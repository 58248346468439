import {Card, Divider, message, Modal, Popconfirm, Spin, Table, Tag} from 'antd'
import React, {useEffect, useState} from 'react'
import {TEMPLATE_DOCUMENT} from '../../../../constants/api'
import {getAPI, postAPI} from '../../../../utils/apiRequest'
import {amountNumberFormatting, replaceVariable} from '../../../../utils/projectUtils'
import InfiniteFeedLoaderButton from "../../../common/InfiniteFeedLoaderButton"
import {EditOutlined, DeleteOutlined, EyeOutlined} from '@ant-design/icons'
import "react-quill/dist/quill.core.css"
import {GOOGLE_DOC} from "../../../../constants/hardData";

const ViewTemplate = (props) => {
    const [loading, setLoading] = useState(false)
    const [templateList, setTemplateList] = useState([])
    const [nextPage, setNextPage] = useState(1)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedDetail, setSelectedDetail] = useState('');

    useEffect(() => {
        getTemplate();
    }, [])

    const showModal = (record) => {
        setSelectedDetail(record?.document_id)
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const getTemplate = (page = 1) => {
        setLoading(true)

        let apiParams = {
            page,
        }

        let successFn = function (result) {
            setLoading(false)
            setTemplateList([...(result.current === 1 ? [] : templateList), ...result.results])
            setNextPage(result.next)
        }

        let errorFn = function (error) {
            setLoading(false)
            console.log(error)
        }
        getAPI(TEMPLATE_DOCUMENT, successFn, errorFn, apiParams)
    }

    const deleteObject = (record) => {
        let reqData = {
            ...record,
            is_active: false,
        };
        let successFn = function (result) {
            getTemplate();
        };
        let errorFn = function (error) {
        };

        postAPI(TEMPLATE_DOCUMENT, reqData, successFn, errorFn)
    };

    const editObject = (record) => {
        props.onSelectTab("add-doc", record);
    };


    const columns = [
        {
            title: 'S. No.',
            key: 's_no',
            render: (item, record, index) =>
                templateList.indexOf(record) + 1,
        },
        {
            title: 'Name',
            key: 'name',
            render: (item, record) => (
                <span>
                  {record.name
                      ? `${record.name}`
                      : '--'}
                </span>
            ),
        },
        {
            title: 'Category',
            key: 'category',
            render: (item, record) => (
                <span>
                  {record.category
                      ? record.category_data.value
                      : '--'}
                </span>
            ),
        },
        {
            title: 'Sub Category',
            key: 'subcategory',
            render: (item, record) => (
                <span>
                  {record.subcategory
                      ? record.subcategory_data.value
                      : '--'}
                </span>
            ),
        },
        {
            title: 'Document ID',
            key: 'document_id',
            render: (item, record) => (
                <span>
                  <a onClick={() => showModal(record)}>View</a>
                </span>
            ),
        },
        {
            title: 'View Doc',
            key: 'view',
            render: (item, record) => <a href={GOOGLE_DOC+`${record?.document_id}`} target={'_blank'}>
                <EyeOutlined style={{color: 'skyBlue'}}/>
            </a>

        },
        {
            title: "Partner Enabled",
            dataIndex: "jnk",
            key: "partner",
            render: (item, record) => (
                <span> {record?.partner_enabled ? <Tag>Yes</Tag> : <Tag>No</Tag>}</span>
            ),
        },
        {
            title: "Customer Enabled",
            dataIndex: "jnk",
            key: "customer",
            render: (item, record) => (
                <span> {record?.customer_enabled ? <Tag>Yes</Tag> : <Tag>No</Tag> }</span>
            ),
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) => (
                <span>
              <a onClick={() => editObject(record)}> < EditOutlined style={{color: 'blue'}}/></a>
              <Divider type="vertical"/>
              <Popconfirm
                  title="Are you sure to delete this?"
                  onConfirm={() => deleteObject(record)}
                  okText="Yes"
                  cancelText="No"
              >
                <a><DeleteOutlined style={{color: 'red'}}/></a>
              </Popconfirm>
            </span>
            ),
        },
    ]

    return (
        <Card>
            <Spin spinning={loading}>
                <Table
                    dataSource={templateList}
                    columns={columns}
                    pagination={false}
                />
            </Spin>
            <InfiniteFeedLoaderButton
                loaderFunction={() => getTemplate(nextPage)}
                loading={loading}
                hidden={!nextPage}
            />
            <Modal width={820} title="Document Details" footer={false} open={isModalOpen} onOk={handleOk}
                   onCancel={handleCancel}>
                {selectedDetail}
            </Modal>
        </Card>
    )
}

export default ViewTemplate
