import {Card, Tabs} from 'antd'
import TabPane from 'antd/lib/tabs/TabPane'
import React, {useState} from 'react'
import AppBase from '../../../base/AppBase'
import AddOrEditHSNsetting from './addOrEditHSNsetting'
import ViewHSNsetting from './viewHSNsetting'

const GSTSettings = (props) => {
    const [selectedTab, setSelectedTab] = useState("add-hsn")
    const [editData, setEditData] = useState(null);
    const onSelectTab = (option, extra) => {
        setSelectedTab(option)
        setEditData(extra);
    };
    return (
        <AppBase
            metaDetails={{
                title: 'HSN Settings',
                subtitle: 'HSN Settings',
                // description: HR_SEETINGS_DROPDOWN_SETTING_DESC,
            }}>
            <Card>
                <Tabs onChange={onSelectTab} activeKey={selectedTab}>
                    <TabPane tab="HSN Settings" key="add-hsn">
                        {selectedTab === "add-hsn" ? (
                                <AddOrEditHSNsetting
                                    editData={editData}
                                    key={'add-hsn'}
                                    onSelectTab={onSelectTab}
                                    {...props}/>
                            ) :
                            null}
                    </TabPane>

                    <TabPane tab='View HSN Settings' key='view-hsn'>
                        {selectedTab === 'view-hsn' ? (
                            <ViewHSNsetting onSelectTab={onSelectTab} {...props} />
                        ) : null}
                    </TabPane>
                </Tabs>
            </Card>
        </AppBase>
    )
}

export default GSTSettings