import React from 'react';
import { Layout, Typography } from 'antd';

const { Text } = Typography;

class AuthBase extends React.PureComponent {

  render() {
    return (
      <Layout className={'authMainLayout'}>
        <div className={'authContainer'}>
          <div className={'authLogoContainer'}>
            <img src={'/law_seva_logo.jpeg'} style={{height: "105px"}} className={'authLogo'} />
          </div>
          {this.props.children}
          <h4 style={{ textAlign: 'center', marginTop: 20 }}>
            Powered By:{' '}
            <a href='https://plutonic.co.in/' target='_blank'>
              Plutonic Services
            </a>
          </h4>
          <p style={{ textAlign: 'center' }}>
            Version:{' '}
            <Text type='secondary'>{process.env.REACT_APP_VERSION}</Text>
          </p>
        </div>
      </Layout>
    );
  }
}

export default AuthBase;
