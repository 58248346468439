import React, { useEffect, useState } from "react";
import { getAPI, putAPI } from "../../../../utils/apiRequest";
import {
  ALL_SERVICES,
  FAQ_TEMPLATE, POLICY_TYPE,
} from "../../../../constants/api";
import {
  Card,
  Divider,
  Modal,
  Popconfirm,
  Spin,
  Table,
  Space,
  Select,
} from "antd";
import InfiniteFeedLoaderButton from "../../../common/InfiniteFeedLoaderButton";
import {CORE_SERVICES} from "../../../../constants/dataKeys";
import {EditOutlined,DeleteOutlined} from '@ant-design/icons'

const ViewFAQ = (props) => {
  const [loading, setLoading] = useState(false);
  const [faqData, setFaqData] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [nextPage, setNextPage] = useState(1);
  const [showAnswer, onShowAnswer] = useState(null);
  const [filterKeys, setFilterKeys] = useState({});

  useEffect(() => {
    loadFAQ().then((r) => r);
    loadServices().then(r => r)
  }, []);


  useEffect(() => {
    loadFAQ().then((r) => r);
  }, [filterKeys]);

  const loadServices = async () => {
    let apiParams = {
      pagination: false
    };
    const successFn = (res) => {
      setServiceList(res);
    };
    const errorFn = (error) => {
      console.log(error);
    };
    await getAPI(ALL_SERVICES, successFn, errorFn, apiParams);
  };

  const loadFAQ = async (page = 1) => {
    let apiParams = {
      page,
      ...filterKeys,
      // is_disabled:true
    };
    setLoading(true);
    const successFn = (data) => {
      setLoading(false);
      setFaqData([...(data.current === 1 ? [] : faqData), ...data.results]);
      setNextPage(data.next);
    };
    const errorFn = (error) => {
      setLoading(false);
    };
    await getAPI(FAQ_TEMPLATE, successFn, errorFn, apiParams);
  };
  const deleteObject = async (record) => {
    let reqData = {
      ...record,
      organization: [record.organization],
      is_active: false,
    };
    let successFn = function (result) {
      loadFAQ();
    };
    let errorFn = function (error) {};

    await putAPI(FAQ_TEMPLATE, reqData, successFn, errorFn);
  };

  const editObject = (record) => {
    props.onSelectTab("add-faq", record);
  };
  const filterData = async (type, value) => {
    await setFilterKeys({ ...filterKeys, [type]: value ? value : undefined });
  };
  const columns = [
    {
      title: "S. No.",
      key: "s_no",
      render: (item, record, index) => faqData.indexOf(record) + 1,
    },
    {
      title: "Question",
      key: "question",
      render: (item, record) => (
        <span>{record.question ? record.question : "---"}</span>
      ),
    },
    ,
    {
      title: "Service",
      key: "service",
      render: (item, record) => (
        <span>{record.service ? record.service_data.value : "--"}</span>
      ),
    },
    {
      title: "Answer",
      key: "answer",
      render: (item, record) => (
        <a onClick={() => onShowAnswer(record)}>Show</a>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <a onClick={() => editObject(record)}> < EditOutlined style={{ color: 'blue' }}/></a>
          <Divider type="vertical" />
          <Popconfirm
            title="Are you sure to delete this?"
            onConfirm={() => deleteObject(record)}
            okText="Yes"
            cancelText="No"
          >
            <a><DeleteOutlined style={{ color: 'red' }}/></a>
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <Spin spinning={loading}>
      <Card
        title={
          <Space>
            Filters:
            <Select
              allowClear
              showSearch
              style={{ width: 200 }}
              placeholder={"Service"}
              optionFilterProp="children"
              onChange={(value, record) => filterData("service", value)}
            >
              {serviceList?.map((option) => (
                <Select.Option
                  label={option?.name}
                  key={option?.id}
                  value={option?.id}
                >
                  {option.value}
                </Select.Option>
              ))}
            </Select>
          </Space>
        }
        bodyStyle={{ padding: 4, border: 0 }}
        style={{ border: "none" }}
      >
        <Table
          size={"small"}
          columns={columns}
          dataSource={faqData}
          pagination={false}
        />
        <InfiniteFeedLoaderButton
          loaderFunction={() => loadFAQ(nextPage)}
          loading={loading}
          hidden={!nextPage}
        />
      </Card>
      <Modal
        title="Answer"
        footer={false}
        visible={!!showAnswer}
        onCancel={() => onShowAnswer(null)}
      >
        <div dangerouslySetInnerHTML={{ __html: showAnswer?.answer }} />
      </Modal>
    </Spin>
  );
};
export default ViewFAQ;
