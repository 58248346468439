import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import React, { useState, useEffect } from "react";
import { HSN_SETTING, SUBSCRIPTION_PLAN } from "../../../../constants/api";
import { SUCCESS_MSG_TYPE } from "../../../../constants/dataKeys";
import { layout, tailLayout } from "../../../../constants/hardData";
import { getAPI, postAPI } from "../../../../utils/apiRequest";
import { displayMessage } from "../../../../utils/common";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { REQUIRED_FIELD_MESSAGE } from "../../../../constants/message";

const AddOrEditSubscription = (props) => {
  const [loading, setLoading] = useState(false);
  const [checkValue, setCheckValue] = useState(false);
  const [HSNList, setHSNList] = useState([]);
  const [descriptionHSN, setDescriptionHSN] = useState("");

  const onChange = (e) => {
    setCheckValue(e.target.checked);
  };

  useEffect(() => {
    getHSN();
  }, []);
  const getHSN = () => {
    setLoading(true);
    let apiParams = {
      pagination: false,
    };

    let successFn = function (result) {
      setLoading(false);
      setHSNList(result);
    };
    let errorFn = function (error) {
      setLoading(false);
      console.log(error);
    };
    getAPI(HSN_SETTING, successFn, errorFn, apiParams);
  };

  const onFinish = (values) => {
    let { editData } = props;
    setLoading(true)

    let reqData = { ...values, is_default: checkValue };
    let featureList = values.features;
    if(featureList?.length >= 1){
      let names = featureList?.map(function (item) {
        return item.name;
      });
      let separator = "#$#";
      let featureListString = names.join(separator);
      reqData.features = featureListString;
    }

    let successFn = function () {
      setLoading(false);
      props.onSelectTab("view-subscription");
      if (editData) {
        displayMessage(SUCCESS_MSG_TYPE, "Subscription Updated Successfully.");
      } else {
        displayMessage(
          SUCCESS_MSG_TYPE,
          "Subscription Plan Added Successfully."
        );
      }
    };
    let errorFn = function (error) {
      console.log(error);
      setLoading(false);
    };
    if (editData) {
      reqData.id = editData.id;
      postAPI(SUBSCRIPTION_PLAN, reqData, successFn, errorFn);
    } else {
      postAPI(SUBSCRIPTION_PLAN, reqData, successFn, errorFn);
    }
  };

  const handleCancelEdit = () => {
    props.onSelectTab("view-subscription");
  };


  return (
    <Spin spinning={loading}>
      <Form
        onFinish={onFinish}
        validateMessages={validateMessages}
        {...layout}
        key={props.editData ? props.editData.id : "1"}
        initialValues={{
          ...props.editData,
          features: props?.editData?.features_data?.map((feature) => ({ name: feature }))
        }}
      >
        <Form.Item label={"Name"} name={"name"} rules={[{ required: true }]}>
          <Input placeholder="Name" />
        </Form.Item>
        {/* <Form.Item
            label={'Start Size (In GB)'}
            name={'start_size'}
            rules={[{ required: true }]}
          >
            <Input  type="number" step="0.1" placeholder={'Start Size'} />
          </Form.Item> */}
        <Form.Item
          label={"End Size (In GB)"}
          name={"end_size"}
          rules={[{ required: true }]}
        >
          <Input type="number" step="0.1" placeholder={"End Size"} />
        </Form.Item>

        <Form.Item
          label={"Valid For (In Days)"}
          name={"expiry_days"}
          rules={[{ required: true }]}
        >
          <Input type="number" placeholder={"Days"} />
        </Form.Item>

        <Form.Item label={"Price"} name={"price"} rules={[{ required: true }]}>
          <Input type="number" placeholder={"Price"} />
        </Form.Item>
        <Form.Item
          label={"HSN"}
          name={"hsn"}
          rules={[{ required: true }]}
          // extra={descriptionHSN}
        >
          <Select
            showSearch
            allowClear
            style={{ width: "100%" }}
            placeholder={"HSN"}
            optionFilterProp="children"
            onChange={(value) =>
              setDescriptionHSN(
                HSNList?.find((x) => x.id === value)?.description
              )
            }
          >
            {HSNList.map((option) => (
              <Select.Option
                label={option.hsn_no}
                key={option.id}
                value={option.id}
              >
                {option.hsn_no}
                {" (" + option.description + ")"}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Row>
          <Col offset={2} span={18}>
            <Typography.Text>Features</Typography.Text>
            <Form.List name="features">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <Row key={field.key}>
                      <Col span={22} offset={1}>
                        <Form.Item
                          {...field}
                          label={`Feature ${index + 1}`}
                          name={[field.name, "name"]}
                          fieldKey={[field.fieldKey, "name"]}
                          rules={[
                            {
                              required: true,
                              message: "Feature is required",
                            },
                          ]}
                        >
                          <Input placeholder="Enter Feature" />
                        </Form.Item>
                      </Col>
                      {index >= 0 && (
                        <MinusCircleOutlined
                          onClick={() => remove(field.name)}
                          style={{ fontSize: "18px", color: "red" }}
                        />
                      )}
                    </Row>
                  ))}
                  <Col span={19} offset={5}>
                    <Form.Item>
                      <Button
                        type="line"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Feature
                      </Button>
                    </Form.Item>
                  </Col>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
        <Form.Item
          label={"Default"}
          name={"is_default"}
          valuePropName="checked"
        >
          <Checkbox onChange={onChange}></Checkbox>
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Space>
            <Button
              htmlType="submit"
              type={"primary"}
              className={"theme-color"}
            >
              Save
            </Button>
            {props.editData && (
              <Button onClick={handleCancelEdit}>Cancel</Button>
            )}
          </Space>
        </Form.Item>
      </Form>
    </Spin>
  );
};

const validateMessages = {
  required: REQUIRED_FIELD_MESSAGE,
};

export default AddOrEditSubscription;
