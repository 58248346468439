import {Card, Tabs} from 'antd'
import TabPane from 'antd/lib/tabs/TabPane'
import React, {useState} from 'react'
import AppBase from '../../../base/AppBase'
import PendingOnboarding from "./PendingOnboarding";
import PreviousOnboarding from "./PreviousOnboarding";

const OnboardingRequest = (props) => {
    const [selectedTab, setSelectedTab] = useState("pending-onboarding")
    const [editData, setEditData] = useState(null);
    const onSelectTab = (option, extra) => {
        setSelectedTab(option)
        setEditData(extra);

    };
    return (
        <AppBase
            metaDetails={{
                title: 'Partner Onboarding',
                // description: HR_SEETINGS_DROPDOWN_SETTING_DESC,
            }}
        >
            <Card>
                <Tabs onChange={onSelectTab} activeKey={selectedTab}>
                    <TabPane tab="Pending Onboarding" key="pending-onboarding">
                        {selectedTab === "pending-onboarding" ? (
                                <PendingOnboarding
                                    editData={editData}
                                    key={'pending-onboarding'}
                                    onSelectTab={onSelectTab}
                                    {...props}/>
                            ) :
                            null}
                    </TabPane>

                    <TabPane tab='Previous Onboarding' key='view-onboarding'>
                        {selectedTab === 'view-onboarding' ? (
                            <PreviousOnboarding onSelectTab={onSelectTab} {...props} />
                        ) : null}
                    </TabPane>
                </Tabs>
            </Card>
        </AppBase>
    )
}

export default OnboardingRequest