import { Card, Tabs } from 'antd'
import TabPane from 'antd/lib/tabs/TabPane'
import React, {useState} from 'react'
import AppBase from '../../../../base/AppBase'
import ViewCurrentPoint from './ViewCurrentPoint'
import ViewTransactionHistory from './ViewTransactionHistory'


const TransactionHistory = (props) => {
    const [selectedTab, setSelectedTab] = useState("view-partner-credit")
    const [ editData, setEditData ] =useState(null);
    const onSelectTab = (option, extra) => {
        setSelectedTab(option)
        setEditData(extra);
      };
  return (
    <AppBase
    metaDetails={{
        title: 'Organization Credits',
      }}
    >
        <Card>
            <Tabs onChange={onSelectTab} activeKey={selectedTab}>
                <TabPane  tab='View Organization Credits' key='view-partner-credit'>
              {selectedTab === 'view-partner-credit' ? (
                <ViewCurrentPoint onSelectTab={onSelectTab} {...props} />
              ) : null}
            </TabPane>
            <TabPane tab="View Transaction History" key="view-transaction-history">
                    {selectedTab ==="view-transaction-history"? (
                        <ViewTransactionHistory
                        editData={editData}
                        key={'view-transaction-history'}
                        onSelectTab={onSelectTab}
                        {...props}/>
                    ):
                    null}
                </TabPane>
            </Tabs>
        </Card>
    </AppBase>
  )
}



export default TransactionHistory