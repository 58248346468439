import { Card, Tabs } from 'antd'
import TabPane from 'antd/lib/tabs/TabPane'
import React, {useState} from 'react'
import AppBase from '../../../../base/AppBase'
import AddOrEditState from './AddOrEditState'
import ViewState from './ViewState'

const State = (props) => {
    const [selectedTab, setSelectedTab] = useState("add-state")
    const [ editData, setEditData ] =useState(null);
    const onSelectTab = (option, extra) => {
        setSelectedTab(option)
        setEditData(extra);
      };
  return (
    <AppBase
    metaDetails={{
      title: 'Add State',
      // subtitle: 'GST Settings',
      // description: HR_SEETINGS_DROPDOWN_SETTING_DESC,
    }}
    >
        <Card>
            <Tabs onChange={onSelectTab} activeKey={selectedTab}>
                <TabPane tab="State" key="add-state">
                    {selectedTab ==="add-state"? (
                        <AddOrEditState
                        editData={editData}
                        key={'add-state'}
                        onSelectTab={onSelectTab}
                        {...props}/>
                    ):
                    null}
                </TabPane>

                <TabPane  tab='View State' key='view-state'>
              {selectedTab === 'view-state' ? (
                <ViewState onSelectTab={onSelectTab} {...props} />
              ) : null}
            </TabPane>
            </Tabs>
        </Card>
    </AppBase>
  )
}

export default State