import {Button, DatePicker, Form, InputNumber, Select, Space, Spin} from 'antd'
import moment from 'moment'
import React, {useState, useEffect} from 'react'
import {ADMIN_COUPON, COUPON_BOARDING, ORGANIZATION, SUBSCRIPTION_PLAN} from '../../../../constants/api'
import {CURRENCY_TYPE, SUCCESS_MSG_TYPE} from '../../../../constants/dataKeys'
import {layout, tailLayout} from '../../../../constants/hardData'
import {REQUIRED_FIELD_MESSAGE} from '../../../../constants/message'
import {getAPI, postAPI} from '../../../../utils/apiRequest'
import {displayMessage} from '../../../../utils/common'

const AddOrEditOnBoardCoupon = (props) => {
    const [loading, setLoading] = useState(false)
    const [planList, setPlanList] = useState([])

    useEffect(() => {
        loadPlan();
    }, [])

    const loadPlan = (page = 1) => {
        let apiParams = {
            pagination: false,
        }
        let successFn = function (result) {
            setPlanList(result)
        }

        let errorFn = function (error) {
            console.log(error)
        }
        getAPI(SUBSCRIPTION_PLAN, successFn, errorFn, apiParams)
    }


    const onFinish = (values) => {
        let {editData} = props;
        setLoading(true)
        let reqData = {
            ...values,
            expiry_date: moment(values.expiry_date).format('YYYY-MM-DD'),
            start_date: moment(values.start_date).format('YYYY-MM-DD'),
        }
        if (values.organization === true) {
            reqData.all_organization = true
            delete reqData.organization
        }
        let successFn = (result) => {
            setLoading(false)
            displayMessage(SUCCESS_MSG_TYPE, "Coupon Added Successfully")
            props.onSelectTab('view-coupon')
        }
        let errorFn = (error) => {
            setLoading(false)
            console.log(error)
        }
        if (editData) {
            reqData.id = editData.id;
            postAPI(COUPON_BOARDING, reqData, successFn, errorFn)
        } else {
            postAPI(COUPON_BOARDING, reqData, successFn, errorFn)
        }
    }

    const handleCancelEdit = () => {
        props.onSelectTab("view-coupon");
    }


    return (
        <Spin spinning={loading}>
            <Form
                onFinish={onFinish}
                validateMessages={validateMessages}
                {...layout}
                key={props.editData ? props.editData.id : '1'}
                initialValues={{
                    ...props.editData,
                    expiry_date: props?.editData?.expiry_date ? moment(props.editData.expiry_date, 'YYYY-MM-DD') : '',
                    start_date: props?.editData?.start_date ? moment(props.editData.start_date, 'YYYY-MM-DD') : '',
                    organization: props?.editData?.all_organization ? true : props?.editData?.organization
                }}
            >
                <Form.Item
                    label={'Plan'}
                    name={'plan'}
                    rules={[{required: true}]}
                >
                    <Select
                        mode={'multiple'}
                        showSearch
                        allowClear
                        style={{width: '100%'}}
                        placeholder={'Plan'}
                    >
                        {planList?.map((option) => (
                            <Select.Option label={option.label} key={option.id} value={option.id}>
                                {option.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label={'Coupon Type'}
                    name={'type'}
                    rules={[{required: true}]}
                >
                    <Select
                        showSearch
                        allowClear
                        style={{width: '100%'}}
                        placeholder={'Coupon Type'}
                    >
                        {CURRENCY_TYPE?.map((option) => (
                            <Select.Option label={option.label} key={option.value} value={option.label}>
                                {option.value}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={'Coupon Value'}
                    name={'value'}
                    rules={[{required: true}]}
                >
                    <InputNumber placeholder='Coupon Value' style={{width: '100%'}}/>
                </Form.Item>
                <Form.Item
                    label={'Minimum Order'}
                    name={'minimum_order'}
                    rules={[{required: true}]}
                >
                    <InputNumber placeholder='Minimum Order' style={{width: '100%'}}/>
                </Form.Item>
                <Form.Item
                    label={'Maximum Discount'}
                    name={'maximum_credit'}
                    rules={[{required: true}]}
                >
                    <InputNumber placeholder='Maximum Discount' style={{width: '100%'}}/>
                </Form.Item>
                <Form.Item
                    label={'Start Date'}
                    name={'start_date'}
                    rules={[{required: true}]}
                >
                    <DatePicker format={"DD/MM/YYYY"} style={{width: "100%"}} placeholder="DD/MM/YYYY"/>
                </Form.Item>

                <Form.Item
                    label={'Expiry Date'}
                    name={'expiry_date'}
                    rules={[{required: true}]}
                >
                    <DatePicker format={"DD/MM/YYYY"} style={{width: "100%"}} placeholder="DD/MM/YYYY"/>
                </Form.Item>
                <Form.Item
                    {...tailLayout}
                >
                    <Space>
                        <Button
                            htmlType='submit'
                            type={'primary'}
                            className={'theme-color'}
                        >
                            Save
                        </Button>
                        {props.editData &&
                            <Button onClick={handleCancelEdit}>Cancel</Button>}
                    </Space>
                </Form.Item>
            </Form>
        </Spin>
    )
}

const validateMessages = {
    required: REQUIRED_FIELD_MESSAGE,
};


export default AddOrEditOnBoardCoupon