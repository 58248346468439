import React, { useEffect, useState } from "react";
import { getAPI, putAPI } from "../../../../utils/apiRequest";
import {
  DESCRIPTION_TEMPLATE,
  SETTINGS_DYNAMIC,
} from "../../../../constants/api";
import {
  Card,
  Divider,
  Modal,
  Popconfirm,
  Spin,
  Table,
  Space,
  Select,
} from "antd";
import InfiniteFeedLoaderButton from "../../../common/InfiniteFeedLoaderButton";
import {EditOutlined,DeleteOutlined} from '@ant-design/icons'

const ViewDescription = (props) => {
  const [loading, setLoading] = useState(false);
  const [descriptionData, setDescriptionData] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [nextPage, setNextPage] = useState(1);
  const [showDescription, onShowDescription] = useState(null);
  const [filterKeys, setFilterKeys] = useState({});

  useEffect(() => {
    loadDescription().then((r) => r);
    // loadOrganization().then((r) => r);
    loadServices()
  }, []);


  useEffect(() => {
    loadDescription().then((r) => r);
  }, [filterKeys]);

  const loadServices = async () => {
    let apiParams = {
        // organization: organizationData.id,
        pagination: false,
        name : "Core Services"
    };
    const successFn = (res) => {
        setServiceList(res);
    };
    const errorFn = (error) => {
        console.log(error);
    };
    await getAPI(SETTINGS_DYNAMIC, successFn, errorFn, apiParams);
};

  const loadDescription = async (page = 1) => {
    let apiParams = {
      page,
      ...filterKeys,
      // is_disabled:true
    };
    setLoading(true);
    const successFn = (data) => {
      setLoading(false);
      setDescriptionData([...(data.current === 1 ? [] : descriptionData), ...data.results]);
      setNextPage(data.next);
    };
    const errorFn = (error) => {
      setLoading(false);
    };
    await getAPI(DESCRIPTION_TEMPLATE, successFn, errorFn, apiParams);
  };
  // const loadOrganization = async () => {
  //   let apiParams = {
  //     pagination: false,
  //   };
  //   let successFn = function (result) {
  //     setServiceList(result);
  //   };
  //   let errorFn = function (error) {};
  //   await getAPI(ORGANIZATION, successFn, errorFn, apiParams);
  // };
  const deleteObject = async (record) => {
    let reqData = {
      ...record,
      organization: [record.organization],
      is_active: false,
    };
    let successFn = function (result) {
      loadDescription();
    };
    let errorFn = function (error) {};

    await putAPI(DESCRIPTION_TEMPLATE, reqData, successFn, errorFn);
  };

  const editObject = (record) => {
    props.onSelectTab("add-description", record);
  };
  const filterData = async (type, value) => {
    await setFilterKeys({ ...filterKeys, [type]: value ? value : undefined });
  };
  const columns = [
    {
      title: "S. No.",
      key: "s_no",
      render: (item, record, index) => descriptionData.indexOf(record) + 1,
    },
    ,
    {
      title: "Service",
      key: "service",
      render: (item, record) => (
        <span>{record.service ? record.service_data.value : "--"}</span>
      ),
    },
    {
      title: "Description",
      key: "description",
      render: (item, record) => (
        <a onClick={() => onShowDescription(record)}>Show</a>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <a onClick={() => editObject(record)}> < EditOutlined style={{ color: 'blue' }}/></a>
          <Divider type="vertical" />
          <Popconfirm
            title="Are you sure to delete this?"
            onConfirm={() => deleteObject(record)}
            okText="Yes"
            cancelText="No"
          >
            <a><DeleteOutlined style={{ color: 'red' }}/></a>
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <Spin spinning={loading}>
      <Card
        title={
          <Space>
            Filters:
            <Select
              allowClear
              showSearch
              style={{ width: 200 }}
              placeholder={"Service"}
              optionFilterProp="children"
              onChange={(value, record) => filterData("service", value)}
            >
              {serviceList?.map((option) => (
                <Select.Option
                  label={option?.name}
                  key={option?.id}
                  value={option?.id}
                >
                  {option.value}
                </Select.Option>
              ))}
            </Select>
          </Space>
        }
        bodyStyle={{ padding: 4, border: 0 }}
        style={{ border: "none" }}
      >
        <Table
          size={"small"}
          columns={columns}
          dataSource={descriptionData}
          pagination={false}
        />
        <InfiniteFeedLoaderButton
          loaderFunction={() => loadDescription(nextPage)}
          loading={loading}
          hidden={!nextPage}
        />
      </Card>
      <Modal
        title="Description"
        footer={false}
        visible={!!showDescription}
        onCancel={() => onShowDescription(null)}
      >
        <div dangerouslySetInnerHTML={{ __html: showDescription?.description }} />
      </Modal>
    </Spin>
  );
};
export default ViewDescription;
