import { combineReducers } from 'redux';
import authReducer from './authReducer';
import globalReducer from './globalReducer';
import reportReducer from './reportReducer';

//import all reducer
export const rootReducer = combineReducers({
  auth: authReducer,
  global: globalReducer,
  report: reportReducer,
});
