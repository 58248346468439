import React, {useEffect, useState} from "react";
import {Button, Form, Select, Space, Spin} from "antd";
import {layout, tailLayout} from "../../../../constants/hardData";
import {REQUIRED_FIELD_MESSAGE} from "../../../../constants/message";
import {getAPI, postAPI, putAPI} from "../../../../utils/apiRequest";
import {DESCRIPTION_TEMPLATE, SETTINGS_DYNAMIC} from "../../../../constants/api";
import {displayMessage} from "../../../../utils/common";
import {SUCCESS_MSG_TYPE} from "../../../../constants/dataKeys";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";


const AddOrEditDescription = (props) => {
    const [loading, setLoading] = useState(false)
    const [editorState, setEditorState] = useState({})
    const [contentError, setContentError] = useState(false)
    const [serviceList, setServiceList] = useState([]);
    const [nonCoreList, setNonCoreList] = useState([]);
    const [otherValue, setOtherValue] = useState(false);


    useEffect(() => {
        loadServices().then(r => r);
        loadNonCoreServices().then(r => r);
    }, [])

    useEffect(()=> {
        if(props.editData){
            if(!props.editData.is_core){
                setOtherValue(true)
            }
        }
    },[props.editData])

    const loadServices = async () => {
        let apiParams = {
            pagination: false,
            name : "Core Services"
        };
        const successFn = (res) => {
            res.push({
                id: 9999999,
                name: "other",
                value: "other",
            });
            setServiceList(res);
        };
        const errorFn = (error) => {
            console.log(error);
        };
        await getAPI(SETTINGS_DYNAMIC, successFn, errorFn, apiParams);
    };

    const loadNonCoreServices = async () => {
        let apiParams = {
            pagination: false,
            name : "Non Core Services"
        };
        const successFn = (res) => {
            setNonCoreList(res);
        };
        const errorFn = (error) => {
            console.log(error);
        };
        await getAPI(SETTINGS_DYNAMIC, successFn, errorFn, apiParams);
    };
   
    const onEditorStateChange = (name, editorState) => {
        setEditorState({...editorState, [name]: editorState})
        setContentError(false)
    };

    const handleServiceChange = (value) => {
        if (value === 9999999) {
            setOtherValue(true);
        } else {
            setOtherValue(false);
        }
    };
    const onFinish = async (values) => {
        let reqData = {
            ...values,
            service: values.service === 9999999 ? values.other_service : values.service,
            description: editorState.description ? editorState.description
             : props.editData ? props.editData.description : '',
        }
        setLoading(true);
        let successFn = function (result) {
            setLoading(false);
            if (props.editData) {
                displayMessage(SUCCESS_MSG_TYPE, "Description Updated Successfully.");
            } else {
                displayMessage(SUCCESS_MSG_TYPE, "Description Added Successfully.");
            }

            props.onSelectTab("view-description");
        }
        let errorFn = function (error) {
            setLoading(false);
        }
        if (props.editData) {
            reqData.id = props.editData.id
            await putAPI(DESCRIPTION_TEMPLATE, reqData, successFn, errorFn)
        } else {
            await postAPI(DESCRIPTION_TEMPLATE, reqData, successFn, errorFn)
        }
    }

    const handleCancelEdit = () => {
        props.onSelectTab("view-description");
      }
  
      

    return (<Spin spinning={loading}>
        <Form
            onFinish={onFinish}
            validateMessages={validateMessages}
            {...layout}
            key={props.editData ? props.editData.id : '1'}
            initialValues={{
                ...props?.editData,
                service : props?.editData && !props?.editData?.is_core ? 9999999 : props?.editData?.service,
                other_service : !props?.editData?.is_core ? props?.editData?.service : null,
                organization: props?.editData ? [props.editData.organization] : []
            }}>
            <Form.Item name={"service"} label={"Service Name"} rules={[{required: true}]}>
                <Select
                    className="default-form-input-format"
                    onChange={handleServiceChange}
                    placeholder={'Service Name'}
                    optionFilterProp="children"
                    showSearch
                >
                    {serviceList?.map((option) => (
                        <Select.Option
                            label={option?.id}
                            key={option?.id}
                            value={option?.id}
                        >
                            {option?.value}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            {otherValue ? (
                <Form.Item label={"Other Service Name"} name={"other_service"} rules={[{required: true}]}>
                    <Select className="default-form-input-format"
                            placeholder={'Other Service Name'}>
                        {nonCoreList?.map((option) => (
                            <Select.Option

                                label={option?.id}
                                key={option?.id}
                                value={option?.id}
                            >
                                {option?.value}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            ) : null}
            <Form.Item label={'Description'} name={'description'}>
                <div style={{border: '1px solid #eee'}}>
                <ReactQuill
              value={
                editorState?.description
                  ? editorState?.description
                  : props.editData?.description
                  ? props.editData.description
                  : ""
              }
              onChange={(content) => onEditorStateChange("description", content)}
              modules={{
                toolbar: [
                  ["bold", "italic", "underline", "strike"],
                  ["blockquote", "code-block"],
                  [{ header: 1 }, { header: 2 }],
                  [{ list: "ordered" }, { list: "bullet" }],
                  [{ script: "sub" }, { script: "super" }],
                  [{ indent: "-1" }, { indent: "+1" }],
                  [{ direction: "rtl" }],
                  [{ size: ["small", false, "large", "huge"] }],
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  [{ color: [] }, { background: [] }],
                  [{ font: [] }],
                  [{ align: [] }],
                  ["clean"],
                ],
              }}
              className="default-rich-text-editor my-editor"
            />
                </div>
            </Form.Item>
            <Form.Item {...tailLayout}>
                <Space>

                    <Button
                        type='primary'
                        className='primary-btn'
                        htmlType='submit  '
                    >
                        Save
                    </Button>
                    {props.editData && 
            <Button onClick={handleCancelEdit} >Cancel</Button> }
                </Space>
            </Form.Item>

        </Form>
    </Spin>)
}
export default AddOrEditDescription
const validateMessages = {
    required: REQUIRED_FIELD_MESSAGE,
};