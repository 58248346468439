import {Card, Tabs} from 'antd'
import TabPane from 'antd/lib/tabs/TabPane'
import React, {useState} from 'react'
import AppBase from '../../../base/AppBase'
import AddOrEditFAQ from "./AddOrEditFAQ";
import ViewFAQ from "./ViewFAQ";

const FAQSettings = (props) => {
    const [selectedTab, setSelectedTab] = useState("add-faq")
    const [editData, setEditData] = useState(null);
    const onSelectTab = (option, extra) => {
        setSelectedTab(option)
        setEditData(extra);
    };
    return (
        <AppBase
            metaDetails={{
                title: 'FAQ Settings',
                subtitle: 'FAQ Settings',
            }}>
            <Card>
                <Tabs onChange={onSelectTab} activeKey={selectedTab}>
                    <TabPane tab="FAQ Settings" key="add-faq">
                        {selectedTab === "add-faq" ? (
                                <AddOrEditFAQ
                                    editData={editData}
                                    key={'add-faq'}
                                    onSelectTab={onSelectTab}
                                    {...props}/>
                            ) :
                            null}
                    </TabPane>

                    <TabPane tab='View FAQ Settings' key='view-faq'>
                        {selectedTab === 'view-faq' ? (
                            <ViewFAQ onSelectTab={onSelectTab} {...props} />
                        ) : null}
                    </TabPane>
                </Tabs>
            </Card>
        </AppBase>
    )
}

export default FAQSettings