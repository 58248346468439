import React, {useEffect, useRef, useState} from "react";
import {Button, Form, Input, Select, Space, Spin} from "antd";
import {layout, tailLayout} from "../../../../constants/hardData";
import {REQUIRED_FIELD_MESSAGE} from "../../../../constants/message";
import {getAPI, postAPI, putAPI} from "../../../../utils/apiRequest";
import {FAQ_TEMPLATE, POLICY_TYPE, SETTINGS_DYNAMIC,} from "../../../../constants/api";
import {displayMessage} from "../../../../utils/common";
import {CORE_SERVICES, SUCCESS_MSG_TYPE,} from "../../../../constants/dataKeys";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AddOrEditFAQ = (props) => {
    const [loading, setLoading] = useState(false);
    const [editorState, setEditorState] = useState({});
    const [contentError, setContentError] = useState(false);
    const [serviceList, setServiceList] = useState([]);
    const [otherValue, setOtherValue] = useState(false);
    const [nonCoreList, setNonCoreList] = useState([]);
    const [serviceVal, setServiceVal] = useState();
    const formRef = useRef();
    let isCore = false;

    useEffect(() => {
        loadServices();
        loadNonCoreServices();
    }, []);


    useEffect(() => {
        if (props?.editData?.service && serviceList?.length) {
            if (!userExists(props?.editData?.service)) {
                setOtherValue(true);
                isCore = true;
                setServiceVal(9999999);
                formRef.current.resetFields();
            }
        }
    }, [props?.editData?.service, serviceList]);

    function userExists(username) {
        return serviceList?.some(function (el) {
            return el.id === username;
        });
    }

    const handleServiceChange = (value) => {
        setServiceVal(value);
        if (value === 9999999) {
            setOtherValue(true);
        } else {
            setOtherValue(false);
        }
    };

    const loadServices = async () => {
        let apiParams = {
            pagination: false,
            name: CORE_SERVICES,
        };
        const successFn = (res) => {
            res.push({
                id: 9999999,
                name: "other",
                value: "other",
            });
            setServiceList(res);
        };
        const errorFn = (error) => {
            console.log(error);
        };
        await getAPI(POLICY_TYPE, successFn, errorFn, apiParams);
    };

    const loadNonCoreServices = async () => {
        let apiParams = {
            pagination: false,
            name: "Non Core Services"
        };
        const successFn = (res) => {
            setNonCoreList(res);
        };
        const errorFn = (error) => {
            console.log(error);
        };
        await getAPI(SETTINGS_DYNAMIC, successFn, errorFn, apiParams);
    };

    const onFinish = async (values) => {
        let reqData = {
            ...values,
            answer: editorState.answer
                ? editorState?.answer
                : props.editData
                    ? props.editData.answer
                    : "",
            service: otherValue ? values.other_service : values.service
        };
        setLoading(true);
        let successFn = function (result) {
            setLoading(false);
            if (props.editData) {
                displayMessage(SUCCESS_MSG_TYPE, "Faq  Updated Successfully.");
            } else {
                displayMessage(SUCCESS_MSG_TYPE, "Faq  Added Successfully.");
            }
            if(props?.editData?.id){
                props.onSelectTab("view-faq");
            }
            else {
                formRef.current.setFieldsValue({
                    ...values,
                    service: otherValue ? values.other_service : values.service,
                    answer: "",
                    question: ""
                });
                setEditorState("");
            }
        };
        let errorFn = function (error) {
            setLoading(false);
        };
        if (props.editData) {
            reqData.id = props.editData.id;
            await putAPI(FAQ_TEMPLATE, reqData, successFn, errorFn);
        } else {
            await postAPI(FAQ_TEMPLATE, reqData, successFn, errorFn);
        }
    };

    const handleCancelEdit = () => {
        props.onSelectTab("view-faq");
    };

    const onEditorStateChange = (name, editorState) => {
        setEditorState({...editorState, [name]: editorState});
        setContentError(false);
    };
    return (
        <Spin spinning={loading}>
            <Form
                onFinish={onFinish}
                validateMessages={validateMessages}
                ref={formRef}
                {...layout}
                key={props.editData ? props.editData.id : "1"}
                initialValues={{
                    question: props?.editData?.question,
                    answer: props?.editData?.answer,
                    other_service: props?.editData?.service,
                    service: otherValue ? 9999999 : props?.editData?.service
                }}
            >
                <Form.Item
                    name={"service"}
                    label={"Service Name"}
                    rules={[{required: true}]}
                >
                    <Select
                        className="default-form-input-format"
                        placeholder={"Service Name"}
                        onChange={handleServiceChange}
                        optionFilterProp="children"
                        showSearch

                    >
                        {serviceList?.map((option) => (
                            <Select.Option
                                label={option?.id}
                                key={option?.id}
                                value={option?.id}

                            >
                                {option?.value}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                {otherValue ? (
                    <Form.Item label={"Other Service Name"} name={"other_service"} rules={[{required: true}]}>
                        <Select className="default-form-input-format"
                                placeholder={'Other Service Name'}>
                            {nonCoreList?.map((option) => (
                                <Select.Option

                                    label={option?.id}
                                    key={option?.id}
                                    value={option?.id}
                                >
                                    {option?.value}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                ) : null}
                <Form.Item
                    label={"Question"}
                    name={"question"}
                    rules={[{required: true}]}
                >
                    <Input.TextArea plasceholder={"Question"}/>
                </Form.Item>
                <Form.Item label={"Answer"} name={"answer"}>
                    <div style={{border: "1px solid #eee"}}>
                        <ReactQuill
                            value={
                                editorState?.answer
                                    ? editorState?.answer
                                    : props.editData?.answer
                                        ? props.editData.answer
                                        : ""
                            }
                            onChange={(content) => onEditorStateChange("answer", content)}
                            modules={{
                                toolbar: [
                                    ["bold", "italic", "underline", "strike"],
                                    ["blockquote", "code-block"],
                                    [{header: 1}, {header: 2}],
                                    [{list: "ordered"}, {list: "bullet"}],
                                    [{script: "sub"}, {script: "super"}],
                                    [{indent: "-1"}, {indent: "+1"}],
                                    [{direction: "rtl"}],
                                    [{size: ["small", false, "large", "huge"]}],
                                    [{header: [1, 2, 3, 4, 5, 6, false]}],
                                    [{color: []}, {background: []}],
                                    [{font: []}],
                                    [{align: []}],
                                    ["clean"],
                                ],
                            }}
                            className="default-rich-text-editor my-editor"
                        />
                    </div>
                </Form.Item>
                <Form.Item {...tailLayout}>
                    <Space>
                        <Button type="primary" className="primary-btn" htmlType="submit  ">
                            Save
                        </Button>
                        {props.editData && (
                            <Button onClick={handleCancelEdit}>Cancel</Button>
                        )}
                    </Space>
                </Form.Item>
            </Form>
        </Spin>
    );
};
export default AddOrEditFAQ;
const validateMessages = {
    required: REQUIRED_FIELD_MESSAGE,
};
