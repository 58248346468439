import { Button, Card, Checkbox, Divider, message, Popconfirm, Spin, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { SMS_SETTING } from '../../../../constants/api'
import { getAPI, postAPI } from '../../../../utils/apiRequest'
import { replaceVariable } from '../../../../utils/projectUtils'
import InfiniteFeedLoaderButton from "../../../common/InfiniteFeedLoaderButton"
import {EditOutlined,DeleteOutlined} from '@ant-design/icons'

const ViewSms = (props) => {
    const [loading, setLoading] = useState(false)
    const [smsList, setSmsList] = useState([])
    const [nextPage, setNextPage] = useState(1)

    useEffect(() => {
        getSMS();
    }, [])

    const getSMS = (page=1) => {
        setLoading(true)

        let apiParams = {
          page,
        }

        let successFn = function(result){
            setLoading(false)
            setSmsList([...(result.current === 1? [] : smsList), ...result.results])
            setNextPage(result.next)
        }

        let errorFn = function(error){
            setLoading(false)
            console.log(error)
        }
       getAPI(SMS_SETTING, successFn, errorFn, apiParams)
    }

    const deleteObject = (record) => {
      let reqData = {
        ...record,
        is_active: false,
      };
      let successFn = function (result) {
        getSMS();
      };
      let errorFn = function (error) {};
  
     postAPI(SMS_SETTING, reqData, successFn, errorFn)
    };

    const editObject = (record) => {
      props.onSelectTab("add-sms", record);
    };

    const enableTemplate = (record) => {
      console.log(record)
      let reqData = {
        ...record,
       is_enabled : !record.is_enabled,
      };
      let successFn = function (result) {
        getSMS();
      };
      let errorFn = function (error) {};
  
     postAPI(SMS_SETTING, reqData, successFn, errorFn)
    }
    

    const columns = [
        {
            title: 'S. No.',
            key: 's_no',
            render: (item, record, index) =>
            smsList.indexOf(record) + 1,
        },
        {
            title: 'Name',
            key: 'name',
            render: (item, record) => (
                <span>
                  {record.name
                    ? `${record.name}`
                    : '--'}
                </span>
              ),
        },
        {
            title: 'Template',
            key: 'template',
            render: (item, record) => (
                <span>
                  {record.template
                    ? `${record.template}`
                    : '--'}
                </span>
              ),
        },
        {
            title: 'DLT Template',
            key: 'template',
            render: (item, record) => (
                <span>
                  {record.template
                    ? replaceVariable(record.template)
                    
                    : '--'}
                       <Button
        type="link"
        onClick={() => {
          navigator.clipboard.writeText(replaceVariable(record.template))
            .then(() => {
              message.success('Copied to clipboard');
            })
            .catch(() => {
              message.error('Failed to copy to clipboard');
            });
        }}
      >
        Copy
      </Button>
                </span>
              ),
        },
        {
          title: 'DLT Approved',
          key: 'is_enable',
          render: (item, record) => (
              <span>
                <Popconfirm
                title={`Are you sure you want to ${record.is_enabled ? "disable" : "enable"} this template? `}
                onConfirm={()=>enableTemplate(record)}
                okText="Yes"
                cancelText="No">
                <Checkbox checked={record.is_enabled ? true : false } />
                </Popconfirm>
               
              </span>
            ),
      },
        {
          title: "Action",
          key: "action",
          render: (text, record) => (
            <span>
              <a onClick={() => editObject(record)}> < EditOutlined style={{ color: 'blue' }}/></a>
              <Divider type="vertical" />
              <Popconfirm
                title="Are you sure to delete this?"
                onConfirm={() => deleteObject(record)}
                okText="Yes"
                cancelText="No"
              >
                <a><DeleteOutlined style={{ color: 'red' }}/></a>
              </Popconfirm>
            </span>
          ),
        },
    ]
    
  return (
    <Card>
    <Spin spinning={loading}>
          <Table
            dataSource={smsList}
            columns={columns}
            pagination={false}
          />
        </Spin>
        <InfiniteFeedLoaderButton
          loaderFunction={() => getSMS(nextPage)}
          loading={loading}
          hidden={!nextPage}
        />
      </Card>
  )
}

export default ViewSms