import { Card, Select, Space, Spin, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { CURRENT_POINT, ORGANIZATION, PARTNER } from '../../../../../constants/api'
import { getAPI, postAPI } from '../../../../../utils/apiRequest'
import InfiniteFeedLoaderButton from '../../../../common/InfiniteFeedLoaderButton'
import CreditModal from './CreditModal'

const ViewCurrentPoint = (props) => {
    const [loading, setLoading] = useState(false)
    const [partnerList, setPartnerList] = useState([])
    const [nextPage, setNextPage] = useState(1)
    const [filterKeys, setFilterKeys] = useState({})
    const [organizationList, setOrganizationList] = useState([])

    useEffect(() => {
        getPartner();
    }, [filterKeys])

    useEffect(() => {
      getOrganization();
    }, [])
    


    const getOrganization = () => {
      setLoading(true)

      let apiParams = {
        pagination: false,
      }
      console.log(apiParams)

      let successFn = function(result){
          setLoading(false)
          setOrganizationList(result)
      }

      let errorFn = function(error){
          setLoading(false)
          console.log(error)
      }
     getAPI(ORGANIZATION, successFn, errorFn, apiParams)
  }

    const getPartner = (page=1) => {
        setLoading(true)
        let apiParams = {
          ...filterKeys,
          page,
        }
        let successFn = function(result){
            setLoading(false)
            setPartnerList([...(result.current === 1? [] : partnerList), ...result.results])
            setNextPage(result.next)
        }

        let errorFn = function(error){
            setLoading(false)
            console.log(error)
        }
       getAPI(CURRENT_POINT, successFn, errorFn, apiParams)
    }

    const  filterData = (type, value) => {
      setFilterKeys(filterKeys=>{ return{...filterKeys,  [type] : value? value : undefined}})
};

    const deleteObject = (record) => {
      let reqData = {
        ...record,
        is_active: false,
      };
      let successFn = function (result) {
        getPartner();
      };
      let errorFn = function (error) {};
  
     postAPI(PARTNER, reqData, successFn, errorFn)
    };

    const editObject = (record) => {
      props.onSelectTab("add-partner-credits", record);
    };

    const columns = [
        {
            title: 'S. No.',
            key: 's_no',
            render: (item, record, index) =>
            partnerList.indexOf(record) + 1,
        },
        {
            title: 'Organization',
            key: 'organization_data',
            render: (item, record) => (
                <span>
                  {record.organization
                    ? `${record.organization_data.name}`
                    : '--'}
                </span>
              ),
        },
        {
          title: 'Credits',
          key: 'credits',
          render: (item, record) => (
              <span>
                {record.credits
                  ? `${record.credits}`
                  : '--'}
              </span>
            ),
      },
    ]
    
  return (
    <Card
    title={
      <Space>
        Filters :
        <Select
          placeholder={'Organization'}
          allowClear
          style={{width: "150px"}}
          onChange={(e) => filterData('organization', e)}
          showSearch
          optionFilterProp="children"
        >
           {organizationList.map((option) => (
                <Select.Option label={option.name} value={option.id}>
                  {option.name}
                </Select.Option>
              ))}
        </Select>
        <CreditModal loadData={getPartner} />
        </Space>
         }
         bodyStyle={{ padding: 4, border: 0 }}>
    <Spin spinning={loading}>
          <Table
            dataSource={partnerList}
            columns={columns}
            pagination={false}
          />
        </Spin>
        <InfiniteFeedLoaderButton
          loaderFunction={() => getPartner(nextPage)}
          loading={loading}
          hidden={!nextPage}
        />
      </Card>
  )
}



export default ViewCurrentPoint