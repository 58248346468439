import { Card, Divider, Popconfirm, Select, Space, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import { COUNTRY } from "../../../../../constants/api";
import { getAPI, postAPI } from "../../../../../utils/apiRequest";
import InfiniteFeedLoaderButton from "../../../../common/InfiniteFeedLoaderButton";
import {EditOutlined,DeleteOutlined} from '@ant-design/icons'

const ViewCountry = (props) => {
  const [loading, setLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [countryFilterList, setCountryFilterList] = useState([]);
  const [filterKeys, setFilterKeys] = useState({})
  const [nextPage, setNextPage] = useState(1);

  useEffect(() => {
    getCountry();
  }, [filterKeys]);

  useEffect(() => {
    getCountryFilter();
  }, []);

  const getCountry = (page = 1) => {
    setLoading(true);
    let apiParams = {
      page,
      ...filterKeys
    };

    let successFn = function (result) {
      setLoading(false);
      setCountryList([
        ...(result.current === 1 ? [] : countryList),
        ...result.results,
      ]);
      setNextPage(result.next);
    };

    let errorFn = function (error) {
      setLoading(false);
      console.log(error);
    };
    getAPI(COUNTRY, successFn, errorFn, apiParams);
  };
  const getCountryFilter = (page = 1) => {
    setLoading(true);
    let apiParams = {
      pagination : false,
    };

    let successFn = function (result) {
      setLoading(false);
      setCountryFilterList(result)
    };

    let errorFn = function (error) {
      setLoading(false);
      console.log(error);
    };
    getAPI(COUNTRY, successFn, errorFn, apiParams);
  };

  const deleteObject = (record) => {
    let reqData = {
      ...record,
      is_active: false,
    };
    let successFn = function (result) {
      getCountry();
    };
    let errorFn = function (error) {};

    postAPI(COUNTRY, reqData, successFn, errorFn);
  };

  const editObject = (record) => {
    props.onSelectTab("add-country", record);
  };

  const columns = [
    {
      title: "S. No.",
      key: "s_no",
      render: (item, record, index) => countryList.indexOf(record) + 1,
    },
    {
      title: "Country Name",
      key: "name",
      render: (item, record) => (
        <span>{record.name ? `${record.name}` : "--"}</span>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <a onClick={() => editObject(record)}> < EditOutlined style={{ color: 'blue' }}/></a>
          <Divider type="vertical" /> 
          <Popconfirm
            title="Are you sure to delete this?"
            onConfirm={() => deleteObject(record)}
            okText="Yes"
            cancelText="No"
          >
            <a><DeleteOutlined style={{ color: 'red' }}/></a>
          </Popconfirm>
        </span>
      ),
    },
  ];

  const filterAddress=(type, value)=>{
    setFilterKeys(filterKeys => {
        return {...filterKeys, [type]: value ? value : undefined}
    })
  }

  return (
    <Card
      title={
        <Space>
          Filters :
          <Select
            placeholder={"Country"}
            allowClear
            style={{ width: "150px" }}
            onChange={(e) => filterAddress("id", e)}
            showSearch
            optionFilterProp="children"
          >
            {countryFilterList.map((option) => (
              <Select.Option label={option.name} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </Space>
      }
    >
      <Spin spinning={loading}>
        <Table dataSource={countryList} columns={columns} pagination={false} />
      </Spin>
      <InfiniteFeedLoaderButton
        loaderFunction={() => getCountry(nextPage)}
        loading={loading}
        hidden={!nextPage}
      />
    </Card>
  );
};

export default ViewCountry;
