import { Card, Tabs } from 'antd'
import TabPane from 'antd/lib/tabs/TabPane'
import React, {useState} from 'react'
import AppBase from '../../../base/AppBase'
import AddOrEditPolicies from './AddOrEditPolicies'
import ViewPolicies from './ViewPolicies'

const Policies = (props) => {
    const [selectedTab, setSelectedTab] = useState("add-policies")
    const [ editData, setEditData ] =useState(null);
    const onSelectTab = (option, extra) => {
        setSelectedTab(option)
        setEditData(extra);
        
      };
  return (
    <AppBase
    metaDetails={{
        title: 'Policies',
        // description: HR_SEETINGS_DROPDOWN_SETTING_DESC,
      }}
    >
        <Card>
            <Tabs onChange={onSelectTab} activeKey={selectedTab}>
                <TabPane tab="Policies" key="add-policies">
                    {selectedTab ==="add-policies"? (
                        <AddOrEditPolicies
                        editData={editData}
                        key={'add-policies'}
                        onSelectTab={onSelectTab}
                        {...props}/>
                    ):
                    null}
                </TabPane>

                <TabPane  tab='View Policies' key='view-policies'>
              {selectedTab === 'view-policies' ? (
                <ViewPolicies onSelectTab={onSelectTab} {...props}  editData={editData} key={'policy_detail'}/>
              ) : null}
            </TabPane>
            </Tabs>
        </Card>
    </AppBase>
  )
}

export default Policies