import { Card, Tabs } from 'antd'
import TabPane from 'antd/lib/tabs/TabPane'
import React, {useState} from 'react'
import AppBase from '../../../../base/AppBase'
import AddCountry from './AddOrEditCountry'
import ViewCountry from './ViewCountry'

const Country = (props) => {
    const [selectedTab, setSelectedTab] = useState("add-country")
    const [ editData, setEditData ] =useState(null);
    const onSelectTab = (option, extra) => {
        setSelectedTab(option)
        setEditData(extra);
      };
  return (
    <AppBase
    metaDetails={{
      title: 'Add Country',
      // subtitle: 'GST Settings',
      // description: HR_SEETINGS_DROPDOWN_SETTING_DESC,
    }}
    >
        <Card>
            <Tabs onChange={onSelectTab} activeKey={selectedTab}>
                <TabPane tab="Country" key="add-country">
                    {selectedTab ==="add-country"? (
                        <AddCountry
                        editData={editData}
                        key={'add-country'}
                        onSelectTab={onSelectTab}
                        {...props}/>
                    ):
                    null}
                </TabPane>

                <TabPane  tab='View Country' key='view-country'>
              {selectedTab === 'view-country' ? (
                <ViewCountry onSelectTab={onSelectTab} {...props} />
              ) : null}
            </TabPane>
            </Tabs>
        </Card>
    </AppBase>
  )
}

export default Country