import { Card, Tabs } from 'antd'
import TabPane from 'antd/lib/tabs/TabPane'
import React, {useState} from 'react'
import AppBase from '../../../base/AppBase'
import AddOrEditTemplate from './AddOrEditTemplate'
import ViewTemplate from './ViewTemplate'

const DocumentTemplate = (props) => {
    const [selectedTab, setSelectedTab] = useState("add-doc")
    const [ editData, setEditData ] =useState(null);
    const onSelectTab = (option, extra) => {
        setSelectedTab(option)
        setEditData(extra);
      };
  return (
    <AppBase
    metaDetails={{
        title: 'Document Template',
        subtitle: 'Document Settings',
        // description: HR_SEETINGS_DROPDOWN_SETTING_DESC,
      }}
    >
        <Card>
            <Tabs onChange={onSelectTab} activeKey={selectedTab}>
                <TabPane tab="Document Template" key="add-doc">
                    {selectedTab ==="add-doc"? (
                        <AddOrEditTemplate
                        editData={editData}
                        key={'add-doc'}
                        onSelectTab={onSelectTab}
                        {...props}/>
                    ):
                    null}
                </TabPane>

                <TabPane  tab='View Template' key='view-doc'>
              {selectedTab === 'view-doc' ? (
                <ViewTemplate onSelectTab={onSelectTab} {...props} />
              ) : null}
            </TabPane>
            </Tabs>
        </Card>
    </AppBase>
  )
}

export default DocumentTemplate