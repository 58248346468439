import { _get } from './lodashUtils';
import moment from 'moment';

export const projectDropDownListViewConverter = function(
  projectData,
  key = null,
) {
  if (projectData) {
    let returningList = projectData.map((projectItem) => {
      let project = projectItem;
      if (key) project = projectItem[key];
      return {
        label: `(${_get(project, 'code_name')}) ${_get(project, 'short_name')}`,
        ...project,
      };
    });
    return returningList;
  }
  return [];
};

export const getProjectShortNameWithCode = function(projectData) {
  return `(${_get(projectData, 'code_name')}) ${_get(
    projectData,
    'short_name',
  )}`;
};

export const getCharacterAfterWords = (string) => {
  if (string) {
    return string
      .split(' ')
      .map(function(s) {
        return s.charAt(0);
      })
      .join('');
  }
};

export const randomDataRendaring = (type, value) => {
  switch (type) {
    case 'SELECT DATE':
      return moment(value).format('lll');
    case 'SELECT TIME':
      return moment(value).format('HH:mm:ss');
    case 'DATE TIME':
      return moment(value).format('lll');
    default:
      return value;
  }

};

export const amountNumberFormatting = function(number) {
  if (number)
    return new Intl.NumberFormat('en-IN').format(+number);
  return number;
};

export const appraisalCycleFormat = function(value) {
  return `${moment(value.duration_start, 'YYYY-MM-DD').format('MMM, YYYY')} - ${moment(value.duration_end, 'YYYY-MM-DD').format('MMM, YYYY')}`;

};

export const replaceVariable = function(inputString) {
  const pattern = /\{\{(\w+)\}\}/g;
  return inputString.replace(pattern, "{#var#}");
}