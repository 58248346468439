import { Card, Tabs } from 'antd'
import TabPane from 'antd/lib/tabs/TabPane'
import React, {useState} from 'react'
import AppBase from '../../../base/AppBase'
import Viewsuggestion from './viewSuggestion';

const Suggestion = (props) => {
    const [selectedTab, setSelectedTab] = useState("view-suggestion")
    const [ editData, setEditData ] =useState(null);
    const onSelectTab = (option, extra) => {
        setSelectedTab(option)
        setEditData(extra);
      };
  return (
    <AppBase
    metaDetails={{
        title: 'Suggestion',
      }}
    >
        <Card>
            <Tabs onChange={onSelectTab} activeKey={selectedTab}>
                <TabPane  tab='View Suggestions' key='view-suggestion'>
              {selectedTab === 'view-suggestion' ? (
                <Viewsuggestion onSelectTab={onSelectTab} {...props} />
              ) : null}
            </TabPane>
            </Tabs>
        </Card>
    </AppBase>
  )
}


export default Suggestion