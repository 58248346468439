import {Button, DatePicker, Form, Input, InputNumber, Select, Space, Spin} from 'antd'
import React, {useState, useEffect, useRef} from 'react'
import {
    CITY,
    COUNTRY,
    MANAGER,
    ORGANIZATION,
    POLICIES,
    PROFESSION,
    STATE,
    SUBSCRIPTION_PLAN
} from '../../../../constants/api'
import {SUCCESS_MSG_TYPE} from '../../../../constants/dataKeys'
import {layout, tailLayout} from '../../../../constants/hardData'
import {getAPI, postAPI} from '../../../../utils/apiRequest'
import {displayMessage, validateEmail, validateMobileNumber} from '../../../../utils/common'
import moment from "moment/moment";
import Upload from 'antd/lib/upload/Upload'
import UploadFile from '../../../common/uploadFile'

const AddOrEditOrganization = (props) => {

    let formRef = useRef();

    const [loading, setLoading] = useState(false)
    const [cityList, setCityList] = useState([])
    const [professionList, setProfessionList] = useState([])
    const [managerList, setManagerList] = useState([])
    const [countryList, setCountryList] = useState([])
    const [stateList, setStateList] = useState([])
    const [error, setError] = useState(null);
    const [errorMobile, setErrorMobile] = useState(null);
    const [validEmail, setValidEmail] = useState(false);
    const [validMobile, setValidMobile] = useState(false);
    const [uploadFileState, setUploadFileState] = useState('');

    useEffect(() => {
        getProfession();
        getManager();
        getCountry()
        if (props.editData) {
            getState(props.editData?.country)
            getCity(props.editData?.state)
        }
    }, [])

    useEffect(() => {
        if (props.editData) {
            if (props?.editData?.mobile) {
                setValidMobile(validateMobileNumber(props?.editData?.mobile))
            }
            if (props?.editData?.email) {
                setValidEmail(validateEmail(props?.editData?.email))
            }
        }
    }, [props.editData])


    const onFinish = (values) => {
        let {editData} = props;
        let reqData = {
            ...values,
            logo:uploadFileState ? uploadFileState?.logo : props?.editData?.logo ? props?.editData?.logo : null
        }


        setLoading(true)
        let successFn = function () {
            props.onSelectTab('view-organization')
            if (editData) {
                displayMessage(SUCCESS_MSG_TYPE, 'Organization Updated Successfully.');
            } else {
                displayMessage(SUCCESS_MSG_TYPE, 'Organization Added Successfully.');
            }
            setLoading(false)
        }
        let errorFn = function (error) {
            console.log(error)
            setLoading(false)
        }
        if (editData) {
            reqData.id = editData.id;
            postAPI(ORGANIZATION, reqData, successFn, errorFn);
        } else {
            postAPI(ORGANIZATION, reqData, successFn, errorFn)
        }
    }


    const getCity = (id) => {

        setLoading(true);
        let apiParams = {
            pagination: false,
            state: id
        }
        let successFn = function (result) {
            setLoading(false);
            setCityList(result)
        }
        let errorFn = function (error) {
            console.log(error)
        }
        getAPI(CITY, successFn, errorFn, apiParams)
    }

    const getCountry = () => {
        setLoading(true)
        let apiParams = {
            pagination: false,
        }

        let successFn = function (result) {
            setLoading(false)
            setCountryList(result)
        }

        let errorFn = function (error) {
            setLoading(false)
            console.log(error)
        }
        getAPI(COUNTRY, successFn, errorFn, apiParams)
    }

    const getProfession = () => {
        setLoading(true);
        let successFn = function (result) {
            setLoading(false);
            setProfessionList(result.results)
        }
        let errorFn = function (error) {
            console.log(error)
        }
        getAPI(PROFESSION, successFn, errorFn)
    }

    const getManager = () => {
        setLoading(true);
        let successFn = function (result) {
            setLoading(false);
            setManagerList(result.results)
        }
        let errorFn = function (error) {
            console.log(error)
        }
        getAPI(MANAGER, successFn, errorFn)
    }

    const getState = (id) => {
        setLoading(true)

        let apiParams = {
            pagination: false,
            country: id
        }
        let successFn = function (result) {
            setLoading(false)
            setStateList(result)
        }

        let errorFn = function (error) {
            setLoading(false)
            console.log(error)
        }
        getAPI(STATE, successFn, errorFn, apiParams)
    }

    const handleCountryChange = (id) => {
        formRef.current.setFieldsValue({
            state: "",
            city: ""
        })
        getState(id)
    }

    const handleStateChange = (id) => {
        getCity(id)
    }


    const handleEmailChange = (e) => {
        const {value} = e.target;
        setValidEmail(validateEmail(value))
        if (validateEmail(value)) {
            setError(null);
            // props.onChange(e);
        } else {
            setError('Invalid email address');
        }
    };

    const handleMobileChange = (e) => {
        setValidMobile(validateMobileNumber(e))
        if (validateMobileNumber(e)) {
            setErrorMobile(null);
            // props.onChange(e);
        } else {
            setErrorMobile('Invalid mobile number');
        }
    };
    const handleCancelEdit = () => {
        props.onSelectTab("view-organization");
    }


    return (

        <Spin spinning={loading}>
            <Form
                onFinish={onFinish}
                {...layout}
                key={props.editData ? props.editData.id : '1'}
                initialValues={{
                    ...props.editData,
                }}
                ref={formRef}
            >
                <Form.Item
                    label={'Name'}
                    name={'name'}
                    rules={[{required: true}]}
                >
                    <Input placeholder='Name'/>

                </Form.Item>

                <Form.Item
                    label={'Tally Name'}
                    name={'tally_name'}
                >
                    <Input placeholder='Tally Name'/>

                </Form.Item>
                <Form.Item
                    label={'Address'}
                    name={'address'}
                    rules={[{required: true}]}
                >
                    <Input placeholder={'Address'}/>
                </Form.Item>
                <Form.Item
                    label={'Email'}
                    name={'email'}
                    rules={[{required: true}]}
                    validateStatus={error ? 'error' : ''}
                    help={error}
                >
                    <Input placeholder={'Email'} onChange={handleEmailChange}/>
                </Form.Item>
                <Form.Item
                    label={'Mobile'}
                    name={'mobile'}
                    validateStatus={errorMobile ? 'error' : ''} help={errorMobile}
                    rules={[{required: true}]}
                >
                    <InputNumber style={{width: "100%"}} onChange={handleMobileChange} placeholder={'Mobile'}/>
                </Form.Item>
                <Form.Item
                    label={'Country'}
                    name={'country'}
                    rules={[{required: true}]}
                >
                    <Select
                        showSearch
                        allowClear
                        style={{width: '100%'}}
                        placeholder={'Country'}
                        onChange={handleCountryChange}
                        optionFilterProp="children"
                    >

                        {countryList.map((option) => (
                            <Select.Option label={option.label} key={option.id} value={option.id}>
                                {option.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={'State'}
                    name={'state'}
                    rules={[{required: true}]}
                >
                    <Select
                        showSearch
                        allowClear
                        style={{width: '100%'}}
                        placeholder={'State'}
                        onChange={handleStateChange}
                        optionFilterProp="children"
                    >
                        {stateList.map((option) => (
                            <Select.Option label={option.label} key={option.id} value={option.id}>
                                {option.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={'City'}
                    name={'city'}
                    rules={[{required: true}]}
                >
                    <Select
                        showSearch
                        allowClear
                        style={{width: '100%'}}
                        placeholder={'City'}
                        optionFilterProp="children"
                    >
                        {cityList.map((option) => (
                            <Select.Option label={option.label} key={option.id} value={option.id}>
                                {option.name} ({option.state_data.name})
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={'Profession'}
                    name={'profession'}
                    rules={[{required: true}]}
                >
                    <Select
                        showSearch
                        allowClear
                        style={{width: '100%'}}
                        placeholder={'Profession'}
                        mode="multiple"
                        optionFilterProp="children"
                    >
                        {professionList?.map((option) => (
                            <Select.Option label={option.label} key={option.id} value={option.id}>
                                {option.value}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                
                <Form.Item
                    label={'Manager'}
                    name={'manager'}
                    rules={[{required: true}]}
                >
                    <Select
                        showSearch
                        allowClear
                        style={{width: '100%'}}
                        placeholder={'Manager'}
                        optionFilterProp="children"
                    >
                        {managerList?.map((option) => (
                            <Select.Option label={option.label} key={option.id} value={option.id}>
                                {option.first_name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label={"Upload Logo"} name={'logo'}>
                    <UploadFile initialValue={props?.editData?.logo || null}
                                key={'Upload Logo'}
                                name={"logo"}
                                label={' '}
                                colon={false}
                                setState={setUploadFileState}
                                />
                </Form.Item>
                <Form.Item
                    {...tailLayout}
                >
                    <Space>
                        <Button
                            htmlType='submit'
                            type={'primary'}
                            className={'theme-color'}
                            disabled={!validEmail || !validMobile}
                        >
                            Save
                        </Button>
                        {props.editData &&
                            <Button onClick={handleCancelEdit}>Cancel</Button>}
                    </Space>
                </Form.Item>
            </Form>
        </Spin>
    )
}


export default AddOrEditOrganization