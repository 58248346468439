export const BREADCRUMB_PATHS = {
    '/': 'Admin',
    // '/admin/setting': 'Settings',
    "/admin/setting/drop-down": "Drop Down",
    "/admin/setting/address-setting/country": "Country",
    "/admin/setting/address-setting/state": "State",
    "/admin/setting/address-setting/city": "City",
    "/admin/document-template": "Template Document",
    "/admin/sms-settings": "SMS Settings",
    "/admin/setting/gst-settings": "GST Settings",
    "/admin/setting/hsn-settings": "HSN Settings",
    "/admin/setting/subscription-plan": "Subscription Plan",
    "/admin/setting/policies": "Policies",
    "/admin/setting/organization": "Organization",
    "/admin/setting/partner": "Employee",
    "/admin/setting/partner/partner-credits": "Organization Credits",
    "/admin/partner/disabled": "Disabled Employee",
    "/admin/partner/faq": "FAQ",
    "/admin/partner/description": "Description",
    "/admin/setting/our-policies": "Policies",
    "/admin/setting/partner-policies": "Policies",
    "/admin/setting/partner/partner-coupon": "Organization Coupon",
    "/admin/feedback":"FeedBack",
    "/admin/become-partner" : "Become Partner",
    "/admin/on-boarding" : "Onboarding Partner",
    "/admin/on-boarding-coupon" : "Onboarding Coupon"
};
