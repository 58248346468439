import React, {Component} from 'react';
import {Avatar, Layout, List, Menu} from 'antd';
import {connect} from 'react-redux';
import {_get} from '../../../utils/lodashUtils';
import {globalSiderCollapsed, globalSiderMenu} from '../../../redux/actions/globalReducActions';
import {Link, withRouter} from 'react-router-dom';
import {
    DollarOutlined,
    FileDoneOutlined,
    FileExclamationOutlined,
    FormOutlined,
    GroupOutlined,
    MoneyCollectOutlined,
    SettingOutlined,
    TeamOutlined,
    UserOutlined,
    QuestionCircleOutlined,
    ExclamationCircleOutlined,ReconciliationOutlined,LikeOutlined,UsergroupAddOutlined
} from '@ant-design/icons';
import {SUCCESS_COLOR,} from '../../../constants/hardData';
import {makeFileURL} from '../../../utils/common';

const {Sider} = Layout;

// const {SubMenu} = Menu;

class AppSider extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    onSwitchPanel = (item) => {
        let that = this;
        that.props.globalSiderMenu([item]);
    };

    render() {
        let that = this;
        const {
            siderCollapsed,
            route,
            location,
            userDetail,
            userImage,
        } = that.props;
        return (
            <Sider
                trigger={null}
                collapsible
                collapsed={siderCollapsed}
                className={'theme-background'}
                width={225}
            >
                <div className='logo' style={{padding: 20, paddingBottom: 0}}>
                    {siderCollapsed ? (
                        <Avatar
                            src={makeFileURL(userImage)}
                            alt={_get(userDetail, 'first_name')}
                            size={50}
                            icon={<UserOutlined/>}
                            style={{
                                margin: 'auto',
                                display: 'block',
                                background: SUCCESS_COLOR,
                            }}
                        />
                    ) : (
                        <List.Item.Meta
                            avatar={
                                <Avatar
                                    src={makeFileURL(userImage)}
                                    size={50}
                                    icon={<UserOutlined/>}
                                    style={{background: SUCCESS_COLOR}}
                                />
                            }
                            title={_get(userDetail, 'first_name')}
                            description={_get(userDetail, 'employee_code_data')}
                        />
                    )}
                </div>
                <Menu
                    defaultSelectedKeys={[`${location.pathname}`]}
                    key={`${_get(route, "cat")}-${_get(route, "subCat")}`}
                    defaultOpenKeys={[`${_get(route, "cat")}-${_get(route, "subCat")}`]}
                    style={{
                        height: 'calc(100vh - 140px)',
                        overflowX: 'hidden',
                        overflowY: 'scroll',
                    }}
                    mode='inline'
                >
                    <Menu.SubMenu
                        key="admin-settings"
                        title="Settings"
                        icon={<SettingOutlined/>}>
                        <Menu.Item key={'/admin/setting/drop-down'}>
                            <Link to={'/admin/setting/drop-down'}>Dropdown Settings</Link>
                        </Menu.Item>
                        <Menu.Item key={'/admin/sms-settings'}>
                            <Link to={'/admin/sms-settings'}>SMS Template</Link>
                        </Menu.Item>
                        <Menu.Item key={'/admin/setting/gst-settings'}>
                            <Link to={'/admin/setting/gst-settings'}>GST Settings</Link>
                        </Menu.Item>
                        <Menu.Item key={'/admin/setting/hsn-settings'}>
                            <Link to={'/admin/setting/hsn-settings'}>HSN Settings</Link>
                        </Menu.Item>
                        
                    </Menu.SubMenu>
                     
                    <Menu.SubMenu
                        title="Address Settings"
                        icon={<FormOutlined/>}>
                        <Menu.Item key={'/admin/setting/address-setting/country'}>
                            <Link to={'/admin/setting/address-setting/country'}>Country</Link>
                        </Menu.Item>
                        <Menu.Item key={'/admin/setting/address-setting/state'}>
                            <Link to={'/admin/setting/address-setting/state'}>State</Link>
                        </Menu.Item>
                        <Menu.Item key={'/admin/setting/address-setting/city'}>
                            <Link to={'/admin/setting/address-setting/city'}>City</Link>
                        </Menu.Item>
                    </Menu.SubMenu>
                    <Menu.SubMenu
                        title="Subscription Plan"
                        icon={<FileDoneOutlined/>}>
                        <Menu.Item key={'/admin/setting/subscription-plan'}>
                            <Link to={'/admin/setting/subscription-plan'}>Subscription Plan</Link>
                        </Menu.Item>
                    </Menu.SubMenu>
                    <Menu.SubMenu
                        title="Policies"
                        icon={<FileExclamationOutlined/>}>
                        <Menu.Item key={'/admin/setting/our-policies'}>
                            <Link to={'/admin/setting/our-policies'}>Our Policies</Link>
                        </Menu.Item>
                        <Menu.Item key={'/admin/setting/partner-policies'}>
                            <Link to={'/admin/setting/partner-policies'}>Partner Policies</Link>
                        </Menu.Item>
                    </Menu.SubMenu>
                    <Menu.SubMenu
                        title="Organization"
                        icon={<GroupOutlined/>}>
                        <Menu.Item key={'/admin/setting/organization'}>
                            <Link to={'/admin/setting/organization'}>Organizations</Link>
                        </Menu.Item>
                        <Menu.Item key={'/admin/setting/partner/partner-credits'}>
                            <Link to={'/admin/setting/partner/partner-credits'}>Organization Credits</Link>
                        </Menu.Item>
                        <Menu.Item key={'/admin/setting/partner/partner-coupon'}>
                            <Link to={'/admin/setting/partner/partner-coupon'}>Organization Coupons</Link>
                        </Menu.Item>
                    </Menu.SubMenu>
                    <Menu.SubMenu
                        title="Employee"
                        icon={<TeamOutlined/>}>
                        <Menu.Item key={'/admin/setting/partner'}>
                            <Link to={'/admin/setting/partner'}>Employee</Link>
                        </Menu.Item>
                        <Menu.Item key={'/admin/setting/partner/partner-kyc'}>
                            <Link to={'/admin/setting/partner/partner-kyc'}>Employee KYC</Link>
                        </Menu.Item>
                    </Menu.SubMenu>
                    <Menu.Item icon={<ExclamationCircleOutlined />} key={'/admin/setting/partner/suggestions'}>
                        <Link to={'/admin/setting/partner/suggestions'}>Suggestions</Link>
                    </Menu.Item>
                    <Menu.SubMenu title="Manage Services" icon={<QuestionCircleOutlined/>} >
                    <Menu.Item key={'/admin/partner/faq'}>
                        <Link to={'/admin/partner/faq'}> FAQ </Link>
                    </Menu.Item>
                    <Menu.Item key={'/admin/partner/description'}>
                        <Link to={'/admin/partner/description'}> Description </Link>
                    </Menu.Item>
                    </Menu.SubMenu>
                    <Menu.Item key={'/admin/document-template'} icon={<ReconciliationOutlined/>}>
                        <Link to={'/admin/document-template'}>Document Template</Link>
                    </Menu.Item>
                    <Menu.Item key={'/admin/become-partner'} icon={<UserOutlined/>}>
                        <Link to={'/admin/become-partner'}>Become Partner</Link>
                    </Menu.Item>

                    <Menu.SubMenu
                        title="Onboarding"
                        icon={<UsergroupAddOutlined/>}>
                        <Menu.Item key={'/admin/on-boarding'} >
                            <Link to={'/admin/on-boarding'}>On-Boarding Partner</Link>
                        </Menu.Item>
                        <Menu.Item key={'/admin/on-boarding-coupon'}>
                            <Link to={'/admin/on-boarding-coupon'}>Coupons</Link>
                        </Menu.Item>
                    </Menu.SubMenu>


                    <Menu.Item key={'/admin/feedback'} icon={<LikeOutlined/>}>
                        <Link to={'/admin/feedback'}>Feedback</Link>
                    </Menu.Item>
                </Menu>
            </Sider>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        route: _get(state, 'global.route'),
        currentSiderMenu: _get(state, 'global.currentSiderMenu'),
        userRoles: _get(state, 'auth.userRoles'),
        userDetail: _get(state, 'auth.user'),
        userImage: _get(state, 'auth.userImage'),
        siderCollapsed: _get(state, 'global.siderCollapsed'),
    };
};
const mapDispatchToProps = {
    globalSiderCollapsed,
    globalSiderMenu,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(AppSider));


