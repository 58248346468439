/***
 * Messages Status Constants
 * */

export const SUCCESS_MSG_TYPE = 'success';
export const WARNING_MSG_TYPE = 'warning';
export const ERROR_MSG_TYPE = 'error';
export const INFO_MSG_TYPE = 'info';
export const SUCCESS_MESSAGE = 'success';

export const AUTH_TOKEN = 'access';
export const REFRESH_TOKEN = 'refresh';

/*Dropdown setting*/
export const POLICY='Policies'
export const CATEGORY='Personal Document Category'
export const SUB_CATEGORY='Sub Category'
export const PARTNER_POLICY='Partner Policies'
export const DEPARTMENT = 'Department';
export const HOLIDAY_TYPE = 'Holiday Type';
export const TYPE_OF_EMPLOYMENT = 'Type of Employment';
export const CATEGORY_OF_EMPLOYEE = 'Category of Employee';
export const CADRE = 'Cadre';
export const LADDER = 'Ladder';
export const POSITION = 'Position';
export const DESIGNATION = 'Designation';
export const DOCUMENT_TYPE = 'Document type';
export const OD_CATEGORY = 'OD Category';
export const OD_SUB_CATEGORY = 'OD Sub Category';
export const TITLE = 'Title';
export const PG_DEGREE = 'Post Graduate Degree';
export const DIPLOMA_DEGREE = 'Diploma Degree';
export const OTHER_DEGREE = 'Other Degree';
export const UG_DEGREE = 'Under Graduate Degree';
export const UNIVERSITY = 'University';
export const BOARD_OF_EDUCATION = 'Board of Education';
export const GRIEVANCE_TYPE = 'Grievance Type';
export const ROLES = 'Roles';
export const RELIGION = 'Religion';
export const GENDER = 'Gender';
export const MARITAL_STATUS = 'Marital Status';
export const CAST = 'Caste';
export const NATIONALITY = 'Nationality';
export const BLOOD_GROUP = 'Blood Group';
export const STATE = 'State';
export const DISTRICT = 'District';
export const LONG_LEAVE_TYPE = 'Long Leave Type';
export const NO_DUES_CATEGORY = 'No Dues Category';
export const LOCATION = 'Location';
export const TEAM = 'Team';
export const BUSINESS_UNIT = 'Business Unit';
export const IDENTITY_CARD = 'Identity Card';
export const VISITING_CARD = 'Visiting Card';
export const DIVISION = 'Division';
export const LANGUAGE = 'Language';
export const LEAVE_TYPE = [
  { name: 'Lapse', value: 'Lapse' },
  { name: 'Accumulate', value: 'Accumulate' },
];
export const LEAVE_CREDIT_TYPE = [
  { name: 'Monthly', value: 'Monthly' },
  { name: 'Quarterly', value: 'Quarterly' },
  { name: 'Half yearly', value: 'Half yearly' },
  { name: 'Yearly', value: 'Yearly' },
];

export const LAPSE = 'Lapse';
export const ACCUMULATE = 'Accumulate';
export const NORMAL_LEAVE = 'normal_leave';
export const COMPENSATORY_LEAVE = 'compensatory';
export const OD_LEAVE = 'od_leave';

export const EMPLOYEE_LEAVE_TYPE = [
  { name: 'Normal Leave', value: NORMAL_LEAVE },
  { name: 'Compensatory', value: COMPENSATORY_LEAVE },
];
export const FIRST_HALF = 'first_half';
export const FULL_LEAVE = 'full_leave';
export const SECOND_HALF = 'second_half';
export const HALF_LEAVE = [
  { name: 'Full Day', key: 'Full Day', value: 'full_leave' },
  { name: 'First Half', key: 'Ist Half', value: 'first_half' },
  { name: 'Second Half', key: 'IInd Half', value: 'second_half' },
];
export const HALF_LEAVE_COMP_OFF = [
  { name: 'First Half', value: 'first_half' },
  { name: 'Second Half', value: 'second_half' },
];
export const OPTION_HALF_LEAVE = [
  { name: 'Full Day', value: 'full_leave' },
  { name: 'First Half', value: 'first_half' },
];

export const SEPARATION_STATUS = [
  { name: 'RESIGN', value: 'resign' },
  { name: 'LONG LEAVE', value: 'long leave' },
];

/** *
 * Form Data Types
 * */
export const INPUT_FIELD = 'input';
export const RADIO_FIELD = 'radio';
export const SELECT_FIELD = 'select';
export const SEARCH_FIELD = 'search_select';
export const MULTI_SELECT_FIELD = 'multiselect';
export const CHECKBOX_FIELD = 'checkbox';
export const SINGLE_CHECKBOX_FIELD = 'singlecheckbox';
export const NUMBER_FIELD = 'number';
export const DATE_PICKER = 'datepicker';
export const DATE_TIME_PICKER = 'datetimepicker';
export const TEXT_FIELD = 'textfield';
export const TIME_PICKER = 'timepicker';
export const COLOR_PICKER = 'colorpicker';
export const QUILL_TEXT_FIELD = 'quilltextfield';
export const SINGLE_IMAGE_UPLOAD_FIELD = 'singleimageupload';
export const MULTI_IMAGE_UPLOAD_FIELD = 'multiimageupload';
export const COUNTRY_FIELD = 'country';
export const STATE_FIELD = 'state';
export const CITY_FIELD = 'city';
export const PASSWORD_FIELD = 'password';
export const EMAIL_FIELD = 'email';
export const SMS_FIELD = 'sms_field';
export const DIVIDER_FIELD = 'divider_field';
export const MAIL_TEMPLATE_FIELD = 'mail_field';
export const LABEL_FIELD = 'label_field';
export const RATE_FIELD = 'rate_field';
export const FRAME_VIEW = 'frame';

/** *
 * Form Action Type Constants
 * */
export const FORM_POST_ACTION = 'post';
export const FORM_PUT_ACTION = 'put';

/* Reports */
export const ATTENDANCE_SUM = 'summary';
export const ATTENDANCE_DAILY = 'daily';
export const ATTENDANCE_DETAIL_REPORT = 'detail_report';

export const EMPLOYEE_WISE_LEAVE = 'employee';
export const DEPARTMENT_WISE_LEAVE = 'department';
export const HALF_YEARLY = 'HALF_YEARLY';
export const MONTHLY = 'MONTHLY';
export const QUARTERLY = 'QUARTERLY';
export const YEARLY = 'YEARLY';
export const EMPLOYEE_LEAVE_COUNT = 'leave_count';
export const EMPLOYEE_LEAVE_LOG = 'employee_log';
export const LEAVE_ARREAR_MONTHLY = 'leave_arrear';
export const COMP_OFF_REPORT = 'comp_off_report';

export const GRIEVANCE_REP = 'grievance';
export const GRIEVANCE_SUMMARY = 'summary';

export const PROJECT_WISE_TIME_SHEET = 'project';

export const EMPLOYEES_DETAILS = 'employees_detail';
export const SEPARATED_EMPLOYEES_DETAILS = 'separated_employees_detail';
export const EMPLOYEE_REPORTING = 'employee_reporting';

export const APPRAISAL_ASSESSMENT = 'appraisal_assessment';
export const EMPLOYEE_ASSESSMENT_SHEET = 'employee_assessment_sheet';
export const APPRAISAL_STATUS = 'appraisal_status';
export const SELF_ASSESSMENT = 'self_assessment';
export const APPRAISAL_AVERAGE_PARAMETER = 'appraisal_average_parameter';
export const SELF_AND_REPORTER_ASSESSMENT_SHEET = 'self_and_reporter_assessment_sheet';
export const PRESENT_COLOUR = '';
export const HOLIDAY_COLOUR = '#f4a540';
export const ABSENT_COLOUR = '#ed3833';
export const HR_MODIFIED_COLOUR = '#008000';
export const HALF_DAY_COLOUR = '#3174ae';
export const OD_COLOUR = '#3174ae';
export const CO_COLOUR = '#787808';
export const DEFAULT_COLOUR = '#000000';

export const CURRENCY_TYPE = [
  { label: 'Percentage Based', value: '%' },
  { label: 'Value Based', value: 'INR' },
];

export const CORE_SERVICES='Core Services'