import { Card, Tabs } from 'antd'
import TabPane from 'antd/lib/tabs/TabPane'
import React, {useState} from 'react'
import AppBase from '../../../base/AppBase'
import AddOrEditGSTSettings from './AddOrEditGSTSettings'
import ViewGSTSettings from './ViewGSTSettings'

const GSTSettings = (props) => {
    const [selectedTab, setSelectedTab] = useState("add-gst")
    const [ editData, setEditData ] =useState(null);
    const onSelectTab = (option, extra) => {
        setSelectedTab(option)
        setEditData(extra);
      };
  return (
    <AppBase 
    metaDetails={{
        title: 'GST Settings',
        subtitle: 'GST Settings',
        // description: HR_SEETINGS_DROPDOWN_SETTING_DESC,
      }}>
        <Card>
            <Tabs onChange={onSelectTab} activeKey={selectedTab}>
                <TabPane tab="GST Settings" key="add-gst">
                    {selectedTab ==="add-gst"? (
                        <AddOrEditGSTSettings
                        editData={editData}
                        key={'add-gst'}
                        onSelectTab={onSelectTab}
                        {...props}/>
                    ):
                    null}
                </TabPane>

                <TabPane  tab='View GST Settings' key='view-gst'>
              {selectedTab === 'view-gst' ? (
                <ViewGSTSettings onSelectTab={onSelectTab} {...props} />
              ) : null}
            </TabPane>
            </Tabs>
        </Card>
    </AppBase>
  )
}

export default GSTSettings