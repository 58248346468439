import { Card, DatePicker, Space, Spin, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { BECOME_PARTNER, DEMO_PARTNER_LOGIN_DATA } from '../../../../constants/api'
import { getAPI } from '../../../../utils/apiRequest'
import InfiniteFeedLoaderButton from "../../../common/InfiniteFeedLoaderButton"
import { makeFileURL } from '../../../../utils/common'
import AppBase from '../../../base/AppBase'
import moment from "moment";
const {RangePicker} = DatePicker
const ViewBecomePartner = (props) => {
    const [loading, setLoading] = useState(false)
    const [becomePartnersData, setBecomePartnersData] = useState([])
    const [nextPage, setNextPage] = useState(1)
    const [startDate, setStartDate] = useState(null);
   const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        getBecomePartners();
    }, [endDate])

    const getBecomePartners = (page=1) => {
        setLoading(true)

        let apiParams = {
            page,
            start: startDate && moment(startDate).format("YYYY-MM-DD"),
            end: endDate && moment(endDate).format("YYYY-MM-DD"),
        }

        

        let successFn = function(result){
            setLoading(false)
            setBecomePartnersData([...(result.current === 1? [] : becomePartnersData), ...result.results])
            setNextPage(result.next)
        }

        let errorFn = function(error){
            setLoading(false)
            console.log(error)
        }
       getAPI(DEMO_PARTNER_LOGIN_DATA, successFn, errorFn, apiParams)
    }

    const openimg = (link) => {
      window.open(makeFileURL(link));
    }

    const onChangeDateRange = (dates, dateStrings) => {
      const ho = () => {
        setStartDate(dates && dates[0]);
        setEndDate(dates && dates[1]);
      };
      ho();
    };

    const columns = [
        {
            title: 'S. No.',
            key: 's_no',
            render: (item, record, index) =>
            becomePartnersData.indexOf(record) + 1,
        },
        {
            title: 'Name',
            key: 'name',
            render: (item, record) => (
                <span>{record?.name ?  record?.name  : "--"}</span>
              ),
        },
        {
          title: 'Email',
          key: 'email',
          render: (item, record) => (
              <span>{record?.email ? record?.email :'--'}</span>
            ),
      },
      {
        title: 'Mobile',
        key: 'mobile',
        render: (item, record) => (
            <span>{record?.mobile ?  record?.mobile:'--'}</span>
          ),
    },
        {
            title: 'Created At',
            key: 'date',
            render: (item, record) => (
                <span>{record?.created_at ?  moment(record?.created_at).format("DD/MM/YYYY HH:mm A")  : "--"}</span>
            ),
        },
    // {
    //     title: 'Profession',
    //     key: 'profession',
    //     render: (item, record) => (
    //         <span>{record?.profession ?  record?.profession:'--'}</span>
    //       ),
    // },
       
    ]
    
  return (
    <AppBase metaDetails={{
        title: 'Become Partner',
      }}>
 
    <Card title={
      
      <Space>
        Filters: 
      <RangePicker
      allowClear={true}
      format={"DD/MM/YYYY"}
      onChange={onChangeDateRange}
    />
    </Space>
    }>
    <Spin spinning={loading}>
          <Table
            dataSource={becomePartnersData}
            columns={columns}
            pagination={false}
          />
        </Spin>
        <InfiniteFeedLoaderButton
          loaderFunction={() => getBecomePartners(nextPage)}
          // loading={loading}
          hidden={!nextPage}
        />
      </Card>
    </AppBase>
  )
}

export default ViewBecomePartner