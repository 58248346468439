import { Button, Form, Input, Select, Space, Spin } from "antd";
import React, { useState, useEffect } from "react";
import {
  GST_SETTING,
  HSN_SETTING,
} from "../../../../constants/api";
import { SUCCESS_MSG_TYPE } from "../../../../constants/dataKeys";
import { layout, tailLayout } from "../../../../constants/hardData";
import { getAPI, postAPI } from "../../../../utils/apiRequest";
import { displayMessage } from "../../../../utils/common";
import TextArea from "antd/lib/input/TextArea";

const AddOrEditHSNsettings = (props) => {
  const [loading, setLoading] = useState(false);
  const [gstData, setGstData] = useState([]);

  useEffect(() => {
    getGST();
  }, []);

  const getGST = () => {
    setLoading(true);
    let apiParams = {
      pagination: false,
    };
    let successFn = function (result) {
      setGstData(result);
      setLoading(false);
    };

    let errorFn = function (error) {setLoading(false);};

    getAPI(GST_SETTING, successFn, errorFn, apiParams);
  };

  const onFinish = (values) => {
    let { editData } = props;
    setLoading(true);
    let reqData = { ...values };

    let successFn = function () {
      setLoading(false);
      props.onSelectTab("view-hsn");
      if (editData) {
        displayMessage(SUCCESS_MSG_TYPE, "HSN Setting Updated Successfully.");
      } else {
        displayMessage(SUCCESS_MSG_TYPE, "HSN Setting Added Successfully.");
      }
    };
    let errorFn = function (error) {
      console.log(error);
      setLoading(false);
    };
    if (editData) {
      reqData.id = editData.id;
      postAPI(HSN_SETTING, reqData, successFn, errorFn);
    } else {
      postAPI(HSN_SETTING, reqData, successFn, errorFn);
    }
  };
  
  const handleCancelEdit = () => {
    props.onSelectTab("view-hsn");
  }
    
  return (
    <Spin spinning={loading}>
      <Form
        onFinish={onFinish}
        // validateMessages={validateMessages}
        {...layout}
        key={props.editData ? props.editData.id : "1"}
        initialValues={{
          ...props.editData,
        }}
      >
        <Form.Item
          label={"HSN NO."}
          name={"hsn_no"}
          rules={[{ required: true }]}
        >
          <Input placeholder="HSN No." />
        </Form.Item>
        <Form.Item
          label={"Same State Tax"}
          name={"same_state"}
          rules={[{ required: true }]}
        >
          <Select
          mode='multiple'
            showSearch
            allowClear
            style={{ width: "100%" }}
            placeholder={"Same State Tax"}
            optionFilterProp="children"
          >
            {gstData.map((option) => (
            <Select.Option label={option.label} key={option.id} value={option.id}>
              {option.name} 
            </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={"Other State Tax"}
          name={"other_state"}
          rules={[{ required: true }]}
        >
          <Select
           mode='multiple'
            showSearch
            allowClear
            style={{ width: "100%" }}
            placeholder={"Other State Tax"}
            optionFilterProp="children"
          >
            {gstData.map((option) => (
                    <Select.Option label={option.label} key={option.id} value={option.id}>
                      {option.name}
                    </Select.Option>
                  ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={"UT Tax"}
          name={"ut_tax"}
          rules={[{ required: true }]}
        >
          <Select
           mode='multiple'
            showSearch
            allowClear
            style={{ width: "100%" }}
            placeholder={"UT Tax"}
            optionFilterProp="children"
          >
            {gstData.map((option) => (
                    <Select.Option label={option.label} key={option.id} value={option.id}>
                      {option.name}
                    </Select.Option>
                  ))}
          </Select>
        </Form.Item>
        <Form.Item label="Description" name={'description'} >
              <TextArea placeholder="Description" />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Space>
            <Button
              htmlType="submit"
              type={"primary"}
              className={"theme-color"}
            >
              Save
            </Button>
            {props.editData && 
            <Button onClick={handleCancelEdit} >Cancel</Button> }
          </Space>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default AddOrEditHSNsettings;
