import React, { Component } from 'react';
import {Col, Layout, List, Popover, Row, Typography } from 'antd';

const { Footer } = Layout;
const { Text } = Typography;

class AppFooter extends Component {
  render() {
    return (
      <Footer>
        <p style={{ textAlign: 'center' }}>
          Version: <Text type='secondary'>{process.env.REACT_APP_VERSION}</Text>
        </p>

        <Row>
          <Col span={12}>
            <Popover
              trigger={'click'}
              placement='rightBottom'
              content={
                <List style={{ width: 150 }} size={'small'}>

                  <List.Item>
                    {' '}
                    <List.Item.Meta

                    />{' '}
                  </List.Item>{' '}
                </List>
              }
            >


            </Popover>
          </Col>
        </Row>
      </Footer>
    );
  }
}

export default AppFooter;
