import { Card, Tabs } from 'antd'
import TabPane from 'antd/lib/tabs/TabPane'
import React, {useState} from 'react'
import AppBase from '../../../base/AppBase'
import AddOrEditSms from './AddOrEditSms'
import ViewSms from './ViewSms'

const SMSSetting = (props) => {
    const [selectedTab, setSelectedTab] = useState("add-sms")
    const [ editData, setEditData ] =useState(null);
    const onSelectTab = (option, extra) => {
        setSelectedTab(option)
        setEditData(extra);
      };
  return (
    <AppBase
    metaDetails={{
        title: 'SMS Template',
        subtitle: 'SMS Settings',
        // description: HR_SEETINGS_DROPDOWN_SETTING_DESC,
      }}
    >
        <Card>
            <Tabs onChange={onSelectTab} activeKey={selectedTab}>
                <TabPane tab="SMS" key="add-sms">
                    {selectedTab ==="add-sms"? (
                        <AddOrEditSms
                        editData={editData}
                        key={'add-sms'}
                        onSelectTab={onSelectTab}
                        {...props}/>
                    ):
                    null}
                </TabPane>

                <TabPane  tab='View SMS Template' key='view-sms'>
              {selectedTab === 'view-sms' ? (
                <ViewSms onSelectTab={onSelectTab} {...props} />
              ) : null}
            </TabPane>
            </Tabs>
        </Card>
    </AppBase>
  )
}

export default SMSSetting