import { Button, Form, Input, Select, Space, Spin } from 'antd'
import React, { useState, useEffect, useRef } from 'react'
import { CITY, COUNTRY, STATE } from '../../../../../constants/api'
import { SUCCESS_MSG_TYPE } from '../../../../../constants/dataKeys'
import { layout, tailLayout } from '../../../../../constants/hardData'
import { getAPI, postAPI } from '../../../../../utils/apiRequest'
import { displayMessage } from '../../../../../utils/common'

const AddOrEditCity = (props) => {

  const [loading, setLoading] = useState(false)
  const [stateList, setStateList] = useState([])
  const [countryList, setCountryList] = useState([])

  const formRef = useRef()

  useEffect(() => {
    getState().then(r => r);
    getCountry().then(r => r);
  }, [])
  

  const getState = async (value) => {
    // if(!value){
    //   formRef.current.setFieldsValue({
    //     state : []
    //   })
    //   setStateList([])
    //   return
    // }
    setLoading(true);
    let apiParams = {
      country : value,
      pagination:false
    }
    let successFn = function(result){
        setLoading(false);
        setStateList(result)
        console.log(result,"state");
    }
    let errorFn = function(error){
        setLoading(false)
        console.log(error)
    }
    await getAPI(STATE, successFn, errorFn, apiParams)
  }

  const getCountry = async () => {
    setLoading(true);
    let apiParams = {
      pagination:false
    }
    let successFn = function(result){
        setLoading(false);
        setCountryList(result)
    }
    let errorFn = function(error){
        setLoading(false)
        console.log(error)
    }
    await getAPI(COUNTRY, successFn, errorFn, apiParams)
  }

  const onFinish = (values) => {
    let { editData } = props;
    setLoading(true)
    let reqData = {...values}

    let successFn = function(){
      setLoading(false)
      props.onSelectTab('view-city')
      if(editData){
        displayMessage(SUCCESS_MSG_TYPE, 'City Updated Successfully.');

      }else{
      displayMessage(SUCCESS_MSG_TYPE, 'City Added Successfully.');
      }
    }
    let errorFn = function(error){
      setLoading(false)
    }
    if (editData) {
      reqData.id = editData.id;
      postAPI(CITY, reqData, successFn, errorFn);
    }
    else{
    postAPI(CITY, reqData, successFn, errorFn)
    }
  }

  const handleCancelEdit = () => {
    props.onSelectTab("view-city");
  }
   
  
  return (
   
        <Spin spinning={loading}>
        <Form
          onFinish={onFinish}
          // validateMessages={validateMessages}
          {...layout}
          ref={formRef}
          key={props.editData ? props.editData.id : '1'}
          initialValues={{
            ...props.editData,
            state:props?.editData?.state,
            country:props?.editData?.state_data?.country,
          }}
        >
          <Form.Item
            label={'Country'}
            name={'country'}
            rules={[{ required: true }]}
          >
            <Select
                  showSearch
                  allowClear
                  style={{ width: '100%' }}
                  placeholder={'Country'}
                  optionFilterProp="children"
                  onChange={getState}
                >
                  {countryList?.map((option) => (
                    <Select.Option label={option.label} key={option.id} value={option.id}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
          </Form.Item>
          <Form.Item
            label={'State'}
            name={'state'}
            rules={[{ required: true }]}
          >
            <Select
                  showSearch
                  allowClear
                  style={{ width: '100%' }}
                  placeholder={'State'}
                  optionFilterProp="children"
                >
                  {stateList?.map((option) => (
                    <Select.Option label={option.label} key={option.id} value={option.id}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
          </Form.Item>
          <Form.Item
            label={'City'}
            name={'name'}
            rules={[{ required: true }]}
          >
            <Input placeholder={'City'} />
          </Form.Item>
          <Form.Item 
          {...tailLayout}
          >
            <Space>
              <Button
                htmlType='submit'
                type={'primary'}
                className={'theme-color'}
              >
                Save
              </Button>
              {props.editData && 
            <Button onClick={handleCancelEdit} >Cancel</Button> }
            </Space>
          </Form.Item>
        </Form>
      </Spin>
  )
}

export default AddOrEditCity