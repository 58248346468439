import {Card, Divider, Form, Modal, Popconfirm, Select, Space, Spin, Table, Tag, Button, DatePicker} from 'antd'
import React, {useEffect, useRef, useState} from 'react'
import {
    CITY,
    COUNTRY,
    DELETED_ORGANIZATIONS,
    MANAGER,
    ORGANIZATION,
    PROFESSION,
    STATE,
    SUBSCRIPTION_HISTORY,
    SUBSCRIPTION_PLAN,
    UPDATE_SUBSCRIPTION
} from '../../../../constants/api'
import {getAPI, postAPI, putAPI} from '../../../../utils/apiRequest'
import InfiniteFeedLoaderButton from '../../../common/InfiniteFeedLoaderButton'
import {removeEmptyObject} from "../../../../utils/common";
import {ClockCircleOutlined, CheckCircleOutlined ,SyncOutlined} from '@ant-design/icons';
import moment from 'moment'
import {layout, tailLayout} from "../../../../constants/hardData";
import { truncate } from 'lodash'

const ViewDeletedOrganization = (props) => {
    let formRef = useRef()
    const [loading, setLoading] = useState(false)
    const [organizationList, setOrganizationList] = useState([])
    const [filterKeys, setFilterKeys] = useState({})
    const [nextPage, setNextPage] = useState(1)
    const [cityList, setCityList] = useState([]);
    const [professionList, setProfessionList] = useState([]);
    const [subscriptionList, setSubscriptionList] = useState([]);
    const [subscriptionHistoryList, setSubscriptionHistoryList] = useState();
    const [managerList, setManagerList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [changeOrgPlan, handleChangeOrgPLan] = useState(null);


    useEffect(() => {
        getOrganization();
    }, [filterKeys])

    useEffect(() => {
        // getCity()
        getProfession();
        getSubscription();
        getManager();
        // getState();
        getCountry();
    }, [])


    const getCity = (id) => {
        setLoading(true)
        let apiParams = {
            pagination: false,
            state: id
        }
        let successFn = function (result) {
            setLoading(false)
            setCityList(result)
        }

        let errorFn = function (error) {
            setLoading(false)
            console.log(error)
        }
        getAPI(CITY, successFn, errorFn, apiParams)
    }
    const getState = (id) => {
        setLoading(true)
        let apiParams = {
            pagination: false,
            country: id
        }
        let successFn = function (result) {
            setLoading(false)
            setStateList(result)
        }

        let errorFn = function (error) {
            setLoading(false)
            console.log(error)
        }
        getAPI(STATE, successFn, errorFn, apiParams)
    }
    const getCountry = () => {
        setLoading(true)
        let apiParams = {
            pagination: false
        }
        let successFn = function (result) {
            setLoading(false)
            setCountryList(result)
            if (countryList !== []) {

            }
        }

        let errorFn = function (error) {
            setLoading(false)
            console.log(error)
        }
        getAPI(COUNTRY, successFn, errorFn, apiParams)
    }

    const getProfession = () => {
        setLoading(true)
        let apiParams = {
            pagination: false
        }
        let successFn = function (result) {
            setLoading(false)
            setProfessionList(result)
        }

        let errorFn = function (error) {
            setLoading(false)
            console.log(error)
        }
        getAPI(PROFESSION, successFn, errorFn, apiParams)
    }

    const getManager = () => {
        let apiParams = {
            pagination: false,
        }
        setLoading(true);
        let successFn = function (result) {
            setLoading(false);
            setManagerList(result)
        }
        let errorFn = function (error) {
            console.log(error)
        }
        getAPI(MANAGER, successFn, errorFn, apiParams)
    }

    const getSubscription = () => {
        setLoading(true)
        let apiParams = {
            pagination: false,
        }
        let successFn = function (result) {
            setLoading(false)
            setSubscriptionList(result)
        }

        let errorFn = function (error) {
            setLoading(false)
            console.log(error)
        }
        getAPI(SUBSCRIPTION_PLAN, successFn, errorFn, apiParams)
    }

    const revertOrg = (isrevert,record) => {
        let reqData = {
            is_active:isrevert,
            ...formRef.current.getFieldsValue(),
            id:record?.id
        }

        let successFn =() => {
            props.onSelectTab('view-organization','')
        }

        let errorFn = {

        }

        putAPI(DELETED_ORGANIZATIONS,reqData,successFn,errorFn);
    }


    const getOrganization = (page = 1) => {
        setLoading(true)

        let apiParams = {
            page,
            ...filterKeys,
            ...formRef.current.getFieldsValue(),
           
        }
        removeEmptyObject(apiParams)

        let successFn = function (result) {
            setLoading(false)
            setOrganizationList([...(result.current === 1 ? [] : organizationList), ...result.results])
            setNextPage(result.next)
        }

        let errorFn = function (error) {
            setLoading(false)
            console.log(error)
        }
        getAPI(DELETED_ORGANIZATIONS, successFn, errorFn, apiParams)
    }

    const filterAddress=(type, value)=>{
        setFilterKeys(filterKeys => {
            return {...filterKeys, [type]: value ? value : undefined}
        })
        switch (type){
            case 'country':
                if (value){
                    getState(value);
                }else {
                    setStateList([])
                    setCityList([])
                }
                formRef.current.setFieldsValue({
                    state: "",
                    city: ""
                })
                break;
            case 'state':
                if (value){
                    getCity(value);
                }else {
                    setCityList([])
                }
                formRef.current.setFieldsValue({
                    city: ""
                })
                break;
        }
    }
    const filterData = (type, value) => {
        setFilterKeys(filterKeys => {
            return {...filterKeys, [type]: value ? value : undefined}
        })

        switch (type){
            case 'country':
                getState(value);
                break;
        }
        console.log("formRef", formRef)


    };

    const deleteObject = (record) => {
        let reqData = {
            ...record,
            is_active: false,
        };
        let successFn = function (result) {
            getOrganization();
        };
        let errorFn = function (error) {
        };

        postAPI(ORGANIZATION, reqData, successFn, errorFn)
    };
    const deletePlanObject = (record) => {
        let reqData = {
            ...record,
            is_active: false,
        };
        let successFn = function (result) {
            setSubscriptionHistoryList(null)
            getOrganization()
        };
        let errorFn = function (error) {
        };

        putAPI(UPDATE_SUBSCRIPTION, reqData, successFn, errorFn)
    };

    // const editObject = (record) => {
    //     props.onSelectTab("add-organization", record);
    // };

    const handleViewSubscription = (record) => {
        let apiParams = {
            organization : record.id,
            pagination : false,
        }
        const successFn = (result) => {
            console.log(result)
            setSubscriptionHistoryList(result)
        }
        const errorFn = (error) => {
            console.log(error)
        }
        getAPI(UPDATE_SUBSCRIPTION, successFn, errorFn, apiParams)
    }



    const changeSubscription = (values) => {
        let reqData = {
            ...values,
            start_date: values.start_date ? moment(values.start_date).format("YYYY-MM-DD") : undefined,
            organization: changeOrgPlan.id,
        };
        let successFn = function (result) {
            getOrganization();
            handleChangeOrgPLan(null)
        };
        let errorFn = function (error) {
        };

        postAPI(UPDATE_SUBSCRIPTION, reqData, successFn, errorFn)
    }


    const columns = [
        {
            title: 'S. No.',
            key: 's_no',
            render: (item, record, index) =>
                organizationList.indexOf(record) + 1,
        },
        {
            title: 'Name',
            key: 'name',
            render: (item, record) => (
                <span>
                  {record.name
                      ? `${record.name}`
                      : '--'}
                </span>
            ),
        },
        {
            title: 'Tally Name',
            key: 'tally_name',
            render: (item, record) => (
                <span>
                  {record.tally_name
                      ? `${record.tally_name}`
                      : '--'}
                </span>
            ),
        },
        {
            title: 'Email',
            key: 'email',
            render: (item, record) => (
                <span>
                  {record.email
                      ? `${record.email}`
                      : '--'}
                </span>
            ),
        },
        {
            title: 'Mobile',
            key: 'mobile',
            render: (item, record) => (
                <span>
                  {record.mobile
                      ? `${record.mobile}`
                      : '--'}
                </span>
            ),
        },
        {
            title: 'Address',
            key: 'address',
            render: (item, record) => (
                <span>
                  {record.address
                      ? `${record.address}`
                      : '--'}
                </span>
            ),
        },
        {
            title: 'City',
            key: 'city_data',
            render: (item, record) => (
                <span>
                  {record.city_data
                      ? `${record.city_data?.name}`
                      : '--'}
                </span>
            ),
        },
        {
            title: 'State',
            key: 'state_data',
            render: (item, record) => (
                <span>
                  {record.state_data
                      ? `${record.state_data?.name}`
                      : '--'}
                </span>
            ),
        },
        {
            title: 'Country',
            key: 'country_data',
            render: (item, record) => (
                <span>
                  {record.country_data
                      ? `${record.country_data?.name}`
                      : '--'}
                </span>
            ),
        },
        {
            title: 'Subscription Plan',
            key: 'plan_data',
            render: (item, record) => (
                <a onClick={()=>handleViewSubscription(record)} >
                  {record.current_plan_data?.plan_data
                      ? `${record.current_plan_data?.plan_data?.name}`
                      : '--'}
                </a>
            ),
        },
        {
            title: 'Manager',
            key: 'manager_data',
            render: (item, record) => (
                <span>
                  {record.manager_data
                      ? `${record.manager_data?.first_name}`
                      : '--'}
                </span>
            ),
        },
        {
            title: 'Profession',
            key: 'profession',
            render: (item, record) => (
                <span>
                  {record.profession_data
                      ? `${record.profession_data.map((option) => option.value)}`
                      : '--'}
                </span>
            ),
        },
        {
            title: 'KYC Status',
            key: 'kyc_data',
            render: (item, record) => (
                <span>
                  {record.kyc_data
                      ?   <Tag icon={<CheckCircleOutlined />} color="success">
                      KYC Done
                    </Tag>
                      :   <Tag icon={<ClockCircleOutlined />} color="default">
                      KYC Pending
                    </Tag>}
                </span>
            ),
        },
        {
            title: "Revert",
            key: "revert",
            render: (text, record) => (
                <span>
              <a onClick={() => revertOrg(true,record)}> <SyncOutlined  style={{ color: 'blue' }}/> </a>
            </span>
            ),
        },
    ]

    const historyColumns = [
        {
            title: 'S. No.',
            key: 's_no',
            render: (item, record, index) =>
                subscriptionHistoryList.indexOf(record) + 1,
        },
        {
            title : 'Plan Name',
            key : 'plan_name',
            render: (item, record) => (
                <span>
                    {record.plan ?
                    record?.plan_data?.name : "--" }
                </span>
            )
        },
        {
            title : 'Start Date',
            key : 'start_date',
            render: (item, record) => (
                <span>
                    {record.start_date ?
                    moment(record.start_date).format("DD/MM/YYYY") : "--" }
                </span>
            )
        },
        {
            title : 'End Date',
            key : 'end_date',
            render: (item, record) => (
                <span>
                    {record?.end_in && record?.end_in?.end_date ?
                    moment(record.end_in.end_date).format("DD/MM/YYYY") : "--" }
                </span>
            )
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) => (
                <span>
               <Popconfirm
                   title="Are you sure to delete this?"
                   onConfirm={() => deletePlanObject(record)}
                   okText="Yes"
                   cancelText="No"
               >
                <a>Delete</a>
              </Popconfirm>
                </span>)

        }
    ]

    return (
        <Card
            title={
                <Space>
                    Filters :
                    <Form layout={'inline'} ref={formRef}>
                        <Form.Item name={'country'}>
                            <Select
                                placeholder={'Country'}
                                allowClear
                                style={{width: "150px"}}
                                onChange={(e) => filterAddress('country', e)}
                                showSearch
                                optionFilterProp="children"
                            >
                                {countryList.map((option) => (
                                    <Select.Option label={option.name} value={option.id}>
                                        {option.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name={'state'}>
                            <Select
                                placeholder={'State'}
                                allowClear
                                style={{width: "150px"}}
                                onChange={(e) => filterAddress('state', e)}
                                showSearch
                                optionFilterProp="children"
                            >
                                {stateList.map((option) => {
                                    return (
                                        <Select.Option label={option.name} value={option.id}>
                                            {option.name}
                                        </Select.Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item name={'city'}>
                            <Select
                                placeholder={'City'}
                                allowClear
                                style={{width: "150px"}}
                                onChange={(e) => filterAddress('city', e)}
                                showSearch
                                optionFilterProp="children"
                            >
                                {cityList.map((option) => (
                                    <Select.Option label={option.name} value={option.id}>
                                        {option.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Select
                                placeholder={'Profession'}
                                allowClear
                                style={{width: "150px"}}
                                onChange={(e) => filterData('profession', e)}
                                showSearch
                                optionFilterProp="children"
                            >
                                {professionList.map((option) => (
                                    <Select.Option label={option.value} value={option.id}>
                                        {option.value}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item>

                            <Select
                                placeholder={'Subscription'}
                                allowClear
                                style={{width: "150px"}}
                                onChange={(e) => filterData('plan', e)}
                                showSearch
                                optionFilterProp="children"
                            >
                                {subscriptionList.map((option) => (
                                    <Select.Option label={option.name} value={option.id}>
                                        {option.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Select
                                placeholder={'Manager'}
                                allowClear
                                style={{width: "150px"}}
                                onChange={(e) => filterData('manager', e)}
                                showSearch
                                optionFilterProp="children"
                            >
                                {managerList?.map((option) => (
                                    <Select.Option label={option.first_name} value={option.id}>
                                        {option.first_name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Form>
                </Space>
            }
            bodyStyle={{padding: 4, border: 0}}
        >
            <Spin spinning={loading}>
                <Table
                    dataSource={organizationList}
                    columns={columns}
                    pagination={false}
                />
            </Spin>
            <InfiniteFeedLoaderButton
                loaderFunction={() => getOrganization(nextPage)}
                loading={loading}
                hidden={!nextPage}
            />
            <Modal title={"Subscription History"}
                   bodyStyle={{padding:8}}
                   open={!!subscriptionHistoryList}
                   onCancel={()=>setSubscriptionHistoryList(null)}
            footer={null}>

                    <Table
                        dataSource={subscriptionHistoryList}
                        columns={historyColumns}
                        pagination={false}/>
            </Modal>

            <Modal title={'Change Subscription Plan'}
                   open={!!changeOrgPlan}
                   footer={null}
                   onCancel={()=>handleChangeOrgPLan(null)}
            >
                <Form labelCol={{span: 8}}  wrapperCol={{span: 16}}
                      onFinish={changeSubscription}
                >
                    <Form.Item
                        label={'Subscription Plan'}
                        name={'plan'}
                        rules={[{required: true}]}
                    >
                        <Select
                            showSearch
                            allowClear
                            style={{width: '100%'}}
                            placeholder={'Subscription Plan'}
                            optionFilterProp="children"
                        >
                            {subscriptionList.map((option) => (
                                <Select.Option label={option.label} key={option.id} value={option.id}>
                                    {option.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={'Start Date'}
                        name={'start_date'}
                    >
                        <DatePicker placeholder='DD/MM/YYYY' format={"DD/MM/YYYY"} style={{width: '100%'}}/>

                    </Form.Item>

                    <Form.Item
                        wrapperCol={{ offset: 8, span: 16 }}
                    >
                        <Space>
                            <Button
                                htmlType='submit'
                                type={'primary'}
                                className={'theme-color'}
                            >
                                Save
                            </Button>

                        </Space>
                    </Form.Item>

                </Form>


            </Modal>
        </Card>
    )
}


export default ViewDeletedOrganization