import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import RoutesHome from './RoutesHome';
import configureStore from './redux/store';
import { Layout } from 'antd';
import { Route, Switch } from 'react-router';
import ErrorBoundary from './crashHandling/ErrorBoundary';

const persistantStore = configureStore();
const { store, persistor } = persistantStore;

function App() {
  return (
    <Layout>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ErrorBoundary>
            <Router>
              <Switch>
                <Route
                  path={'/:cat/:subCat'}
                  render={(route) => <RoutesHome {...route} />}
                />
                <Route
                  path={'/:cat/'}
                  render={(route) => <RoutesHome {...route} />}
                />
                <Route
                  path={'/'}
                  render={(route) => <RoutesHome {...route} />}
                />
              </Switch>
            </Router>
          </ErrorBoundary>
        </PersistGate>
      </Provider>
    </Layout>
  );
}

export default App;
