export const EMPLOYEE_ATTENDANCE_PAGE_DESCRIPTION = "Check attendance summary and its details.";
export const EMPLOYEE_LEAVE_PAGE_DESCRIPTION = "Apply leaves and check the status of previously applied leaves.";
export const EMPLOYEE_COMP_OFF_PAGE_DESCRIPTION = "Apply compensatory off and check the status of previously applied componesatory offs.";
export const EMPLOYEE_ON_DUTY_PAGE_DESCRIPTION = "Apply On duty and check the status of previously applied on duties.";
export const EMPLOYEE_TIME_SHEET_PAGE_DESCRIPTION = "Add your time hours against the tasks assigned to you in projects.";
export const EMPLOYEE_MY_GRIEVANCES_PAGE_DESCRIPTION = "Raise issues or grievance and check the status of previously raised grievances.";
export const EMPLOYEE_MY_ASSESSMENTS_DESCRIPTION = "Check your appraisal assessment filled by your manager or direct reporting person.";
export const EMPLOYEE_CIRCULARS_PAGE_DESCRIPTION = "Read different announcements and circulars from HR.";
export const EMPLOYEE_SEPARATION_APPLY = "Apply for resignation or separation from the organisation.";



export const HR_SEETINGS_LOCATION_SETTING_DESC = "Manage the locations of different offices and sites employees are currently appointed at.";
export const HR_SEETINGS_DROPDOWN_SETTING_DESC = "Manage different dropdown values used through out the different forms in your organisation.";
export const HR_SEETINGS_GRIEVANCE_FORM_DESC = "Manage fields for grievances filled by employees on the basis of Grievance Category.";
export const HR_SEETINGS_HOLIDAY_SETTINGS_DESC = "Define and manage the holidays in your organization. ";
export const HR_SEETINGS_SHIFT_SETTINGS_DESC = "Define and manage the shifts timings for different employees.";
export const HR_SEETINGS_ADD_LEAVE_CATEGORY_SETTINGS_DESC = "LeaveDefine and manage leaves that are used in your organization.";
export const HR_SEETINGS_LEAVE_QUOTA_SETTINGS_DESC = "Define the leave quota for different leave for employees to be credited automatically.";
export const HR_SEETINGS_LEAVE_CLUB_SETTINGS_DESC = "Define the leave clubbing combinations";
export const HR_SEETINGS_ASSESSMENT_YEAR_DESC = "Define the assessment year for appraisal process.";
export const HR_SEETINGS_APPRAISAL_PARAMETERS_DESC = "Define the parameter for appraisal with marks range and the necessary comments for exceptional marks";
export const HR_SEETINGS_APPRAISAL_COMMENTS_DESC = "Define the comments fields in appraisal process form to approvals.";
export const HR_SEETINGS_APPRAISAL_UNLOCK_DESC = "Appraisal Unlock";
export const HR_EMPLOYEE_PASSWORD_RESET_DESC = "Reset password of employee login.";
export const HR_EMPLOYEE_SWITCH_EMPLOYEE_DESC = "Switch to any logged in account of employee.";
export const HR_EMPLOYEE_ADD_DESC = "Add new employee to organisation.";
export const HR_EMPLOYEE_EDIT_DESC = "Edit or update existing employee data.";
export const HR_MANUAL_LEAVE_CREDIT_DEBIT_DESC = "Credit and debit the leaves count manually to any employee.";
export const HR_MANUAL_COUNT_DESC = "Currently available leave count of leaves to any employee with the detailed log of credits and debits.";
export const HR_MANUAL_REFRESH_ATTENDANCE_DESC = "Refresh Attandance";
export const HR_MANUAL_ATTENDANCE_DESC = "View or edit employee attendance on daily basis.";
export const HR_MANUAL_LOCK_ATTENDANCE_DESC = "Lock Attendance";
export const HR_APPLY_LEAVE_DESC = "Apply leaves for any employee.";
export const HR_COMP_OFF_DESC = "Apply compensatory off leave for employee.";
export const HR_EMPLOYEE_LEAVE_DESC = "Approve or reject the applied leaves in your organisation.";
export const HR_ON_DUTY_DESC = "Apply on duty for employee.";
export const HR_SEPARATION_ASSIGN_NO_DUES_DESC = "Define the no dues head to any employee based on No due category. ";
export const HR_SEPARATION_APPLY_DESC = "Manually separate/exit the employee from organisation.";
export const HR_SEPARATION_APPROVE_DESC = "Accept/Reject the exit applications of employee.";
export const HR_SEPARATION_RETRIVE_DESC = "Rejoin the separated employee in the organization.";
export const HR_EMPLOYEE_LEAVE_ARREAR_DESC = "Apply for leave arrear for employee";
export const HR_EMPLOYEE_SIGN_IN_OUT_ARREAR_DESC = "Apply for Sign In/Out arrear for employee.";
export const HR_PROJECT_DESC = "Define and manage the projects in your organisation.";
export const HR_ASSIGN_PROJECT_DESC = "Assign employee to the defined projects.";
export const HR_APPROVAL_GREIVANCES_DESC = "Accept/Reject the employee grievencaes. ";
export const HR_CIRCULARS_DESC = "Create and circulate the different announcements and circulars. ";
export const HR_REPORT_DESC = "Attendance Report";



export const NO_DUE= "Approve or reject the no dues clearance of the category for the seperating employee assigned to you.";


export const APPROVAL_VIEW_ATTENDANCE_DESC= "View the attendance summary and its details of your direct reportees.";
export const APPROVAL_LEAVES_APPROVAL_DESC= "Approve or reject the leaves applied by your reportees.";
export const APPROVAL_COMP_OFF_APPROVAL_DESC= "Approve or reject the compensatory off leaves applied by your reportees.";
export const ON_DUTY_APPROVAL_DESC= "Approve or reject the on duty applied by your reportees.";
export const APPROVAL_GREIVANCES_DESC = "Approve or reject the grievances raised by your reportees.";
export const APPROVAL_TIME_SHEET_DESC = "Approve or reject the timesheet filled by your reportees on different tasks.";
export const APPROVE_SEPARATION_DESC = "Approve or reject the resignation / separation application of your reportees.";
export const APPROVAL_ASSESSMENTS_DESC = "Fill and view the appraisal assessments of your reportees.";