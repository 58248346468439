import { Card, Tabs } from 'antd'
import TabPane from 'antd/lib/tabs/TabPane'
import React, {useState} from 'react'
import AppBase from '../../../base/AppBase'
import AddOrEditSubscription from './AddOrEditSubscription'
import ViewSubscription from './ViewSubscription'

const SubscriptionPlan = (props) => {
    const [selectedTab, setSelectedTab] = useState("add-subscription")
    const [ editData, setEditData ] =useState(null);
    const onSelectTab = (option, extra) => {
        setSelectedTab(option)
        setEditData(extra);
      };
  return (
    <AppBase
    metaDetails={{
        title: 'Subscription Plan',
        subtitle: 'Subscription Plan',
        // description: HR_SEETINGS_DROPDOWN_SETTING_DESC,
      }}
    >
        <Card>
            <Tabs onChange={onSelectTab} activeKey={selectedTab}>
                <TabPane tab="Subscription" key="add-subscription">
                    {selectedTab ==="add-subscription"? (
                        <AddOrEditSubscription
                        editData={editData}
                        key={'add-subscription'}
                        onSelectTab={onSelectTab}
                        {...props}/>
                    ):
                    null}
                </TabPane>

                <TabPane  tab='View Subscription' key='view-subscription'>
              {selectedTab === 'view-subscription' ? (
                <ViewSubscription onSelectTab={onSelectTab} {...props} />
              ) : null}
            </TabPane>
            </Tabs>
        </Card>
    </AppBase>
  )
}

export default SubscriptionPlan