import lockr from 'lockr';
import {
  AUTH_LOGIN_TYPE,
  AUTH_LOGOUT_TYPE,
  AUTH_TOKEN,
  AUTH_USER_DATA,
  EMPLOYEE_REPORTER_DATA,
  REDUX_PERSIST,
  REFRESH_TOKEN,
} from '../reduxConstant';
import { axiosInstance } from '../store';
import { _get } from '../../utils/lodashUtils';
import { clearStorage } from '../../utils/apiRequest';

export default (state = {}, action) => {
  switch (action.type) {
    case AUTH_LOGIN_TYPE: {
      lockr.set(AUTH_TOKEN, _get(action, 'payload.access'));
      lockr.set(REFRESH_TOKEN, _get(action, 'payload.refresh'));
      return {
        ...state,
        ...action.payload,
        isSuperUser: _get(action, 'payload.user.is_superuser'),
        userRoles: _get(action, 'payload.user.roles_code_data'),
        userImage: _get(action, 'payload.user.employee_image'),
        loggedIn: true,
      };
    }
    case AUTH_LOGOUT_TYPE: {
      clearStorage();
      window.location.reload();
      return {
        ...state,
        user: null,
        access: null,
        refresh: null,
        loggedIn: false,
        isSuperUser: null,
      };
    }

    case AUTH_USER_DATA:
      return {
        ...state,
        user: _get(action, 'payload.user'),
        isSuperUser: _get(action, 'payload.user.is_superuser'),
        userRoles: _get(action, 'payload.user.roles_code_data'),
        userImage: _get(action, 'payload.user.employee_image'),
      };

    case EMPLOYEE_REPORTER_DATA:
      return {
        ...state,
        reportees: _get(action, 'payload'),
      };
    case REDUX_PERSIST:
      if (_get(action, 'payload.access')) {
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${_get(
          action,
          'payload.user.access',
        )}`;
      }
      return state;

    default:
      return state;
  }
};
