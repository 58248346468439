import { REPORT_DATE_RANGE } from '../reduxConstant';
import moment from 'moment';

export default (state = {}, action) => {
  switch (action.type) {
    case REPORT_DATE_RANGE:
      return {
        ...state,
        selectedDateRange: action.payload,
      };
    default:
      return {
        ...state,
        selectedDateRange: {
          startDate: moment().format('DD/MM/YYYY'),
          endDate: moment().add(7, 'days').format('DD/MM/YYYY'),
        },
      };
  }
};
