import {Card, Tabs} from 'antd'
import TabPane from 'antd/lib/tabs/TabPane'
import React, {useState} from 'react'
import AppBase from '../../../base/AppBase'
import AddOrEditDescription from "./AddOrEditDescription";
import ViewDescription from "./ViewDescription";

const DescriptionSettings = (props) => {
    const [selectedTab, setSelectedTab] = useState("add-description")
    const [editData, setEditData] = useState(null);
    const onSelectTab = (option, extra) => {
        setSelectedTab(option)
        setEditData(extra);
    };
    return (
        <AppBase
            metaDetails={{
                title: 'Description Settings',
                subtitle: 'Description Settings',
            }}>
            <Card>
                <Tabs onChange={onSelectTab} activeKey={selectedTab}>
                    <TabPane tab="Description Settings" key="add-description">
                        {selectedTab === "add-description" ? (
                                <AddOrEditDescription
                                    editData={editData}
                                    key={'add-description'}
                                    onSelectTab={onSelectTab}
                                    {...props}/>
                            ) :
                            null}
                    </TabPane>

                    <TabPane tab='View Description Settings' key='view-description'>
                        {selectedTab === 'view-description' ? (
                            <ViewDescription onSelectTab={onSelectTab} {...props} />
                        ) : null}
                    </TabPane>
                </Tabs>
            </Card>
        </AppBase>
    )
}

export default DescriptionSettings