export const REQUIRED_FIELD_MESSAGE = "'${label}' is required!";
export const REQUIRED_FIELD_WITH_NAME_MESSAGE = "'${name}' is required!";
export const ERROR_INTERNET_CONNECTIVITY = 'Connection Failed';
export const ERROR_MESSAGE_500 = 'Internal Server Error';
export const ERROR_MESSAGE_404 = 'Not Found';
export const ERROR_MESSAGE_400 = 'Bad Request';
export const ERROR_MESSAGE_401 = 'Unauthorized Request';

export const HOURLY_COUNT_LEAVE_APPLIED_FULL_DAY_NOT_COMPLETE =
  'Full Day is not completed on applying this leave';
export const HOURLY_COUNT_LEAVE_APPLIED_HALF_DAY_NOT_COMPLETE =
  'Half Day is not completed on applying this leave';
