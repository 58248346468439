import { Card, Tabs } from 'antd'
import TabPane from 'antd/lib/tabs/TabPane'
import React, {useState} from 'react'
import AppBase from '../../../base/AppBase'
import AddOrEditPartner from './AddOrEditPartner'
import ViewPartner from './ViewPartner'
import DeletePartner from './DeletedPartner'

const Partner = (props) => {
    const [selectedTab, setSelectedTab] = useState("add-partner")
    const [ editData, setEditData ] =useState(null);
    const onSelectTab = (option, extra) => {
        setSelectedTab(option)
        setEditData(extra);
      };
  return (
    <AppBase
    metaDetails={{
        title: 'Employee',
        // description: HR_SEETINGS_DROPDOWN_SETTING_DESC,
      }}
    >
        <Card>
            <Tabs onChange={onSelectTab} activeKey={selectedTab}>
                <TabPane tab="Employee" key="add-partner">
                    {selectedTab ==="add-partner"? (
                        <AddOrEditPartner
                        editData={editData}
                        key={'add-partner'}
                        onSelectTab={onSelectTab}
                        {...props}/>
                    ):
                    null}
                </TabPane>

                <TabPane  tab='View Employee' key='view-partner'>
              {selectedTab === 'view-partner' ? (
                <ViewPartner onSelectTab={onSelectTab} {...props} />
              ) : null}
            </TabPane>
            <TabPane  tab='Deleted Employee' key='deleted-partner'>
              {selectedTab === 'deleted-partner' ? (
                <DeletePartner onSelectTab={onSelectTab} {...props} />
              ) : null}
            </TabPane>
            </Tabs>
        </Card>
    </AppBase>
  )
}

export default Partner