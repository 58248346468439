import { Card, Tabs } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import React, {useState} from 'react'
import AppBase from '../../../base/AppBase'
import AddOrEditPartnerPolicy from './AddOrEditPartnerPolicy';
import ViewPartnerPolicy from './ViewPartnerPolicy';

export default function PartnerPolicy(props) {
    const [selectedTab, setSelectedTab] = useState("add-partner-policies")
    const [ editData, setEditData ] =useState(null);
    const onSelectTab = (option, extra) => {
        setSelectedTab(option)
        setEditData(extra);
        
      };
  return (
    <AppBase
    metaDetails={{
        title: 'Partner Policies',
      }}>
          <Card>
            <Tabs onChange={onSelectTab} activeKey={selectedTab}>
                <TabPane tab="Policies" key="add-partner-policies">
                    {selectedTab ==="add-partner-policies"? (
                        <AddOrEditPartnerPolicy
                        editData={editData}
                        key={'add-partner-policies'}
                        onSelectTab={onSelectTab}
                        {...props}/>
                    ):
                    null}
                </TabPane>

                <TabPane  tab='View Policies' key='view-partner-policies'>
              {selectedTab === 'view-partner-policies' ? (
                <ViewPartnerPolicy onSelectTab={onSelectTab} {...props} />
              ) : null}
            </TabPane>
            </Tabs>
        </Card>
    </AppBase>
  )
}
