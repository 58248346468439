import { Card, Divider, Popconfirm, Select, Space, Spin, Table } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { ADMIN_COUPON, ORGANIZATION, SEND_EMAIL } from '../../../../constants/api'
import { SUCCESS_MSG_TYPE } from '../../../../constants/dataKeys'
import { getAPI, postAPI, putAPI } from '../../../../utils/apiRequest'
import { displayMessage } from '../../../../utils/common'
import InfiniteFeedLoaderButton from '../../../common/InfiniteFeedLoaderButton'
import {EditOutlined,DeleteOutlined,SendOutlined} from '@ant-design/icons'

const ViewCoupon = (props) => {
    const [loading, setLoading] = useState(false)
    const [couponList, setCouponList] = useState([])
    const [nextPage, setNextPage] = useState(1)
    const [filterKeys, setFilterKeys] = useState({})
    const [organizationList, setOrganizationList] = useState([])

    useEffect(() => {
        loadCoupon();
    }, [filterKeys])

    useEffect(() => {
      getOrganization();
    }, [])
    


    const getOrganization = () => {
      setLoading(true)

      let apiParams = {
        pagination: false,
      }

      let successFn = function(result){
          setLoading(false)
          setOrganizationList(result)

      }

      let errorFn = function(error){
          setLoading(false)
          console.log(error)
      }
     getAPI(ORGANIZATION, successFn, errorFn, apiParams)
  }


    const loadCoupon = (page=1) => {
        setLoading(true)
        let apiParams = {
          ...filterKeys,
          page,
        }
        let successFn = function(result){
            setLoading(false)
            setCouponList([...(result.current === 1? [] : couponList), ...result.results])
            setNextPage(result.next)
        }

        let errorFn = function(error){
            setLoading(false)
            console.log(error)
        }
       getAPI(ADMIN_COUPON, successFn, errorFn, apiParams)
    }

    const  filterData = (type, value) => {
      setFilterKeys(filterKeys=>{ return{...filterKeys,  [type] : value? value : undefined}})
};

    const deleteObject = (record) => {
      let reqData = {
        ...record,
        is_active: false,
      };
      let successFn = function (result) {
        loadCoupon();
      };
      let errorFn = function (error) {};
  
     putAPI(ADMIN_COUPON, reqData, successFn, errorFn)
    };

    const sendEmail = (record) => {


      let reqData = {
      
        id:record.id
     
      };

      setLoading(true);
  
      let successFn = function (res) {
        setLoading(false);
        displayMessage(SUCCESS_MSG_TYPE, "Coupon has been sent Successfully")
      };
  
      let errorFn = function () {
        setLoading(false);
      };
      
      postAPI(SEND_EMAIL,reqData, successFn, errorFn);
    };


    const editObject = (record) => {
      props.onSelectTab("add-coupon", record);
    };

    const columns = [
        {
            title: 'S. No.',
            key: 's_no',
            render: (item, record, index) =>
            couponList.indexOf(record) + 1,
        },
        {
          title: 'Coupon Code',
          key: 'code',
          render: (item, record) => (
            <span>
              {record  ? `${record.code}` : "--"}
            </span>
          ),
      },
        {
          title: 'Coupon Value',
          key: 'value',
          render: (item, record) => (
            <span>
              {record  ? `${record.value}` : "--"}
            </span>
          ),
      },
      {
          title: 'Coupon Type',
          key: 'type',
          render: (item, record) => (
            <span>
              {record  ? `${record.type}` : "--"}
            </span>
          ),
      },
      {
          title: 'Minimum Order',
          key: 'minimum_order',
          render: (item, record) => (
            <span>
              {record  ? `${record.minimum_order}` : "--"}
            </span>
          ),
      },
      {
          title: 'Maximum Discount',
          key: 'maximum_credit',
          render: (item, record) => (
            <span>
              {record  ? `${record.maximum_credit}` : "--"}
            </span>
          ),
      },
      
      {
          title: 'Expiry Date',
          key: 'expiry_date',
          render: (item, record) => (
              <span>
                {record.expiry_date
                  ? `${moment(record.expiry_date).format("DD/MM/YYYY")}`
                  : '--'}
              </span>
            ),
      },
        {
            title: 'Organization',
            key: 'organization_data',
            render: (item, record) => (
                <span>
                  {record?.all_organization
                    ? `All`
                    : record?.organization_data?.name }
                </span>
              ),
        },
       
        {
          title: "Action",
          key: "action",
          render: (text, record) => (
            <span>
              <a onClick={() => editObject(record)}> < EditOutlined style={{ color: 'blue' }}/></a>
              <Divider type="vertical" />
              
              <Popconfirm
              title="Are you sure to send this?"
              onConfirm={() => sendEmail(record)}
              okText="Yes"
              cancelText="No"
              >
              <a><SendOutlined style={{color:'skyBlue'}}/></a>
              </Popconfirm>

              <Divider type="vertical" />
              <Popconfirm
                title="Are you sure to delete this?"
                onConfirm={() => deleteObject(record)}
                okText="Yes"
                cancelText="No"
              > 
             
              
                <a><DeleteOutlined style={{ color: 'red' }}/></a>
              </Popconfirm>

              

            </span>
          ),
        },
    ]
    
  return (
    <Card
    title={
      <Space>
        Filters :
        <Select
          placeholder={'Organization'}
          allowClear
          style={{width: "150px"}}
          onChange={(e) => filterData('organization', e)}
        >
           {organizationList.map((option) => (
                <Select.Option label={option.name} value={option.id}>
                  {option.name}
                </Select.Option>
              ))}
        </Select>
        </Space>
         }
         bodyStyle={{ padding: 4, border: 0 }}>
    <Spin spinning={loading}>
          <Table
            dataSource={couponList}
            columns={columns}
            pagination={false}
          />
        </Spin>
        <InfiniteFeedLoaderButton
          loaderFunction={() => loadCoupon(nextPage)}
          loading={loading}
          hidden={!nextPage}
        />
      </Card>
  )
}




export default ViewCoupon