import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { _get } from './utils/lodashUtils';
import RouteContext from './RouteContext';
import Error404 from './components/common/errors/Error404';
import { globalUpdateRoute } from './redux/actions/globalReducActions';
import LogIn from './components/modules/auth/login';
import { cloneUser } from './redux/actions/authReduxActions';

import ChangePassword from './components/modules/auth/changePassword';
import ResetPassword from './components/modules/auth/resetPassword';
import DropdownSettings from './components/modules/settings/dynamic/DropdownSettings';
import Country from './components/modules/settings/address-settings/Country/index';
import State from './components/modules/settings/address-settings/State';
import City from './components/modules/settings/address-settings/City';
import SMSSetting from './components/modules/settings/sms-settings';
import GSTSettings from './components/modules/settings/gst-settings';
import HSNSettings from './components/modules/settings/HSNSettings'
import SubscriptionPlan from './components/modules/settings/subscription-plan';
import Policies from './components/modules/settings/policies';
import Organization from './components/modules/settings/organization';
import Partner from './components/modules/settings/partner';
import PartnerKYC from './components/modules/settings/partner/partner-kyc';
import TransactionHistory from './components/modules/settings/partner/transaction-history';
import PartnerCoupon from './components/modules/settings/partner-coupon';
import PartnerPolicy from './components/modules/settings/partner-policy';
import FAQSettings from "./components/modules/settings/FAQ";
import DescriptionSettings from './components/modules/settings/description';
import Suggestions from './components/modules/settings/suggestions';
import DocumentTemplate from './components/modules/settings/document-template';
import FeedBack from './components/modules/settings/feedback';
import ViewBecomePartner from './components/modules/settings/become-partner';
import OnboardingRequest from "./components/modules/settings/onboarding";
import OnboardingCoupon from "./components/modules/settings/onboarding-coupon";


class RoutesHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingPermissions: false,
    };
  }

  componentDidMount() {
    let { loggedIn } = this.props;
    this.onRouteChanged();
    if (loggedIn) {
      cloneUser(this);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
    if (this.props.currentSiderMenu !== prevProps.currentSiderMenu) {
      this.props.history.push('/');
    }
  }

  onRouteChanged() {
    this.props.globalUpdateRoute({
      ...this.props.match.params,
      location: this.props.location,
    });
  }

  protectComponent = (Component, render) => {
    if (this.props.loggedIn) {
      return Component;
    }
    if (render)
      return (
        <Redirect
          to={`/login/?next=${
            window.location.pathname + window.location.search
          }`}
        />
      );
    return (
      <Redirect
        to={`/login/?next=${window.location.pathname + window.location.search}`}
      />
    );
  };

  render() {
    let that = this;
    const { userRoles, loggedIn } = that.props;
    return (
      <RouteContext.Provider value={this.props}>
        <Switch>
          
          <Route
            exact
            path={['/', '/admin/setting/drop-down']}
            render={(route) =>
                that.protectComponent(<DropdownSettings {...route} />)
            }
          />
          <Route
            exact
            path={['/admin/setting/address-setting/country']}
            render={(route) =>
                that.protectComponent(<Country {...route} />)
            }
          />
          <Route
            exact
            path={['/admin/setting/address-setting/state']}
            render={(route) =>
                that.protectComponent(<State {...route} />)
            }
          />
          <Route
            exact
            path={['/admin/setting/address-setting/city']}
            render={(route) =>
                that.protectComponent(<City {...route} />)
            }
          />
          <Route
            exact
            path={['/admin/sms-settings']}
            render={(route) =>
                that.protectComponent(<SMSSetting {...route} />)
            }
          />
          <Route
            exact
            path={['/admin/setting/gst-settings']}
            render={(route) =>
                that.protectComponent(<GSTSettings {...route} />)
            }
          />
          <Route
            exact
            path={['/admin/setting/hsn-settings']}
            render={(route) =>
                that.protectComponent(<HSNSettings {...route} />)
            }
          />
          <Route
            exact
            path={['/admin/setting/subscription-plan']}
            render={(route) =>
                that.protectComponent(<SubscriptionPlan {...route} />)
            }
          />
          <Route
            exact
            path={['/admin/setting/our-policies']}
            render={(route) =>
                that.protectComponent(<Policies {...route} />)
            }
          />
          <Route
            exact
            path={['/admin/setting/partner-policies']}
            render={(route) =>
                that.protectComponent(<PartnerPolicy {...route} />)
            }
          />
          <Route
            exact
            path={['/admin/setting/organization']}
            render={(route) =>
                that.protectComponent(<Organization {...route} />)
            }
          />
          <Route
            exact
            path={['/admin/setting/partner']}
            render={(route) =>
                that.protectComponent(<Partner {...route} />)
            }
          />
          <Route
            exact
            path={['/admin/setting/partner/partner-kyc']}
            render={(route) =>
                that.protectComponent(<PartnerKYC {...route} />)
            }
          />
          <Route
            exact
            path={['/admin/setting/partner/partner-credits']}
            render={(route) =>
                that.protectComponent(<TransactionHistory {...route} />)
            }
          />
          <Route
            exact
            path={['/admin/setting/partner/suggestions']}
            render={(route) =>
                that.protectComponent(<Suggestions {...route} />)
            }
          />
          <Route
            exact
            path={['/admin/setting/partner/partner-coupon']}
            render={(route) =>
                that.protectComponent(<PartnerCoupon {...route} />)
            }
          />



        
          

          <Route
              exact
              path={'/admin/partner/faq'}
              render={(route) =>
                  that.protectComponent(<FAQSettings {...route} />)
              }
          />
          <Route
              exact
              path={'/admin/partner/description'}
              render={(route) =>
                  that.protectComponent(<DescriptionSettings {...route} />)
              }
          />

          <Route
            exact
            path={['/admin/document-template']}
            render={(route) =>
                that.protectComponent(<DocumentTemplate {...route} />)
            }
          />

          <Route
            exact
            path={['/admin/feedback']}
            render={(route) =>
                that.protectComponent(<FeedBack {...route} />)
            }
          />
         <Route
            exact
            path={['/admin/become-customer']}
            render={(route) =>
                that.protectComponent(<ViewBecomePartner {...route} />)
            }
          />

          <Route
            exact
            path={['/admin/feedback']}
            render={(route) =>
                that.protectComponent(<FeedBack {...route} />)
            }
          />
         <Route
            exact
            path={['/admin/become-partner']}
            render={(route) =>
                that.protectComponent(<ViewBecomePartner {...route} />)
            }
          />
          <Route
            exact
            path={['/admin/on-boarding']}
            render={(route) =>
                that.protectComponent(<OnboardingRequest {...route} />)
            }
          />
          <Route
              exact
              path={['/admin/on-boarding-coupon']}
              render={(route) =>
                  that.protectComponent(<OnboardingCoupon {...route} />)
              }
          />

          {/*Auth Modulus Routes*/}
          <Route exact path='/login' render={(route) => <LogIn {...route} />} />
          <Route
            exact
            path={'/change-password'}
            render={(route) =>
              this.protectComponent(<ChangePassword {...route} />)
            }
          />
          <Route
            exact
            path={'/password-reset/:token'}
            render={(route) => <ResetPassword {...route} />}
          />
          <Route render={() => <Error404 />} />
        </Switch>
      </RouteContext.Provider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: _get(state, 'auth.user'),
    loggedIn: _get(state, 'auth.loggedIn'),
    currentSiderMenu: _get(state, 'global.currentSiderMenu'),
    userRoles: _get(state, 'auth.userRoles', []),
  };
};
const mapDispatchToProps = {
  globalUpdateRoute,
};
export default connect(mapStateToProps, mapDispatchToProps)(RoutesHome);
