import { Button, Card, Checkbox, Divider, message, Popconfirm, Spin, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { ADD_SUGGESTION, ADD_Suggestion } from '../../../../constants/api'
import { getAPI, postAPI } from '../../../../utils/apiRequest'
import { replaceVariable } from '../../../../utils/projectUtils'
import InfiniteFeedLoaderButton from "../../../common/InfiniteFeedLoaderButton"
import { Link } from 'react-router-dom'
import { makeFileURL } from '../../../../utils/common'
import moment from 'moment'

const ViewSuggestion = (props) => {
    const [loading, setLoading] = useState(false)
    const [SuggestionList, setSuggestionList] = useState([])
    const [nextPage, setNextPage] = useState(1)

    useEffect(() => {
        getSuggestion();
    }, [])

    const getSuggestion = (page=1) => {
        setLoading(true)

        let apiParams = {
            page,
        }

        let successFn = function(result){
            setLoading(false)
            setSuggestionList([...(result.current === 1? [] : SuggestionList), ...result.results])
            setNextPage(result.next)
        }

        let errorFn = function(error){
            setLoading(false)
            console.log(error)
        }
       getAPI(ADD_SUGGESTION, successFn, errorFn, apiParams)
    }

    // const deleteObject = (record) => {
    //   let reqData = {
    //     ...record,
    //     is_active: false,
    //   };
    //   let successFn = function (result) {
    //     getSuggestion();
    //   };
    //   let errorFn = function (error) {};
  
    //  postAPI(ADD_SUGGESTION, reqData, successFn, errorFn)
    // };

    const openimg = (link) => {
      window.open(makeFileURL(link));
    }

    const columns = [
        {
            title: 'S. No.',
            key: 's_no',
            render: (item, record, index) =>
            SuggestionList.indexOf(record) + 1,
        },
        {
            title: 'Description',
            key: 'description',
            render: (item, record) => (
                <span>{record?.description ?   <div dangerouslySetInnerHTML={{ __html: record?.description }} />: "--"}</span>
              ),
        },
        {
          title: 'Date',
          key: 'created_at',
          render: (item, record) => (
              <span>{record?.created_at ?  moment(record?.created_at).format('DD/MM/YYYY'):'--'}</span>
            ),
      },
      {
        title: 'Created By',
        key: 'created_by_data',
        render: (item, record) => (
            <span>{record?.partner_data?.first_name ?  record?.partner_data?.first_name :'--'}</span>
          ),
    },
        {
          title: "File",
          dataIndex: "file",
          key: "image",
          render: (item, record,editor) => (
              <span>{record?.image_url ?  <Link to="/admin/setting/partner/suggestions"><div onClick={() => openimg(record?.image_url)}>view</div></Link>: "--"}</span>
          ),
      },
        // {
        //   title: "Action",
        //   key: "action",
        //   render: (text, record) => ( 
        //     <span>
            
        //       <Divider type="vertical" />
        //       <Popconfirm
        //         title="Are you sure to delete this?"
        //         onConfirm={() => deleteObject(record)}
        //         okText="Yes"
        //         cancelText="No"
        //       >
        //         <a>Delete</a>
        //       </Popconfirm>
        //     </span>
        //   ),
        // },
    ]
    
  return (
    <Card>
    <Spin spinning={loading}>
          <Table
            dataSource={SuggestionList}
            columns={columns}
            pagination={false}
          />
        </Spin>
        <InfiniteFeedLoaderButton
          loaderFunction={() => getSuggestion(nextPage)}
          loading={loading}
          hidden={!nextPage}
        />
      </Card>
  )
}

export default ViewSuggestion