import {
    Button, Form, Input, Select, Space, Spin, DatePicker, InputNumber} from "antd";
import React, {useState, useEffect} from "react";
import {
    ORGANIZATION, PARTNER, PARTNER_KYC, PARTNER_KYC_DETAIL, PROFESSION,
} from "../../../../../constants/api";
import {SUCCESS_MSG_TYPE} from "../../../../../constants/dataKeys";
import {
    layout,
    PROFESSION_ACCOUNTANT_ID,
    PROFESSION_ADVOCATE_ID,
    PROFESSION_CA_ID,
    PROFESSION_CMA_ID,
    PROFESSION_CS_ID,
    tailLayout
} from "../../../../../constants/hardData";
import {getAPI, postAPI, putAPI} from "../../../../../utils/apiRequest";
import {
    displayMessage,
    EMAIL_REGEX,
    MOBILE_REGEX,
    validateEmail,
    validateMobileNumber
} from "../../../../../utils/common";
import moment from "moment/moment";
import {getFilePathFromFileUploadFormValue} from "../../../../../utils/fileUploadUtils";
import {useRef} from "react";
import UploadFile from "../../../../common/uploadFile";


const AddOrEditPartnerKYC = (props) => {
    const [loading, setLoading] = useState(false);
    const [organizationList, setOrganizationList] = useState([]);
    const [partnerList, setPartnerList] = useState([]);
    const [professionList, setProfessionList] = useState([]);
    const [selectedProfession, setSelectedProfession] = useState(null);
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const [uploadFileState, setUploadFileState] = useState({});

    let formRef = useRef()
    let {editData} = props;


    useEffect(() => {
        getOrganization();
    }, []);

    useEffect(() => {
        if (props.editData) {
            setProfessionList(props.editData?.organization_data?.profession_data)
            // getProfession(props.editData?.profession)
            getPartners(props.editData?.organization)
        }
        if (props.editData) {
            setSelectedProfession(() => props.editData.profession_data.id)
            changeProfessionType(props.editData.profession_data.id)
        }
    }, [props.editData])


    const onFinish = async (values) => {
        setLoading(true);
        let reqData = {
            ...values,
            id: values.id,
            dob: moment(values.dob).format("YYYY-MM-DD"),
            enrollment_date: moment(values.enrollment_date).format("YYYY-MM-DD"),
            eenrollment_number: values.enrollment_number,
            ...uploadFileState
        };
        let successFn = function () {
            setLoading(false);
            props.onSelectTab(JSON.stringify(values.profession));
            if (editData) {
                displayMessage(SUCCESS_MSG_TYPE, "Partner KYC Updated Successfully.");
            } else {
                displayMessage(SUCCESS_MSG_TYPE, "Partner KYC Added Successfully.");
            }
        };
        let errorFn = function (error) {
            setLoading(false)
            console.log(error);
            setLoading(false);
        };
        if (editData) {
            reqData.id = editData.id;
            putAPI(PARTNER_KYC_DETAIL, reqData, successFn, errorFn);
        } else {
            postAPI(PARTNER_KYC, reqData, successFn, errorFn);
        }
    };


    const getOrganization = () => {
        setLoading(true);
        let apiParams = {
            pagination: false
        }

        let successFn = function (result) {
            setLoading(false);
            setOrganizationList(result);
        };

        let errorFn = function (error) {
            setLoading(false);
            console.log(error);
        };
        getAPI(ORGANIZATION, successFn, errorFn, apiParams);
    };
    const getProfession = (value) => {
        let apiParam={
            pagination:false,
            organization : value,
        }
        let successFn = function (result) {
            setProfessionList(result)
        }
        let errorFn = function (error) {
            console.log(error)
        }
        getAPI(PROFESSION, successFn, errorFn,apiParam)
    }

    const getPartners = (value) => {
        setLoading(true);
        let apiParams = {
            organization : value,
            is_kyc_completed :false,
            pagination: false
        }

        let successFn = function (result) {
            setLoading(false);
            setPartnerList(result);
        };

        let errorFn = function (error) {
            setLoading(false);
            console.log(error);
        };
        getAPI(PARTNER, successFn, errorFn, apiParams);
    };

    const handleProfessionChange = (value) => {
        changeProfessionType(value)
        setUploadFileState({})
        if (props.editData) {
            setSelectedProfession(() => props.editData.profession_data.id)
            changeProfessionType(props.editData.profession_data.id)
        }
        //  else {
            setSelectedProfession(() => value);
        // }

    };

    const onHandleChangeOrganization = (value, option) => {
        formRef.current.setFieldsValue({
            profession : ''
        })
        getPartners(value)
        // getProfession(value)
        setProfessionList(option?.label?.profession_data)
        setSelectedOrganization(option?.label?.profession)
        if (value === undefined) {
            formRef.current.resetFields()
            setProfessionList([])
            setPartnerList([])
            setSelectedProfession(null)
            changeProfessionType()
        }
    }

    const handleCancelEdit = () => {
        props.onSelectTab("view-partner-kyc");
    }

    const changeProfessionType = (type) => {
        switch (type) {
            case PROFESSION_CA_ID:
                return (<>
                        <Form.Item label="MRN Number" name={"mrn_number"}
                                   rules={[{required: true}]}>
                            <Input name="mrn_number" placeholder="MRN Number"/>
                        </Form.Item>
                        <Form.Item label="Enrollment Date" name={"enrollment_date"}
                                   rules={[{required: true}]}>
                            <DatePicker format={"DD/MM/YYYY"} style={{width: "100%"}} placeholder="DD/MM/YYYY"/>
                        </Form.Item>
                        <Form.Item label="Email ID" name={"email"}
                                   rules={[{ required: true },
                                       { pattern: EMAIL_REGEX, message: 'Please enter a valid email address' }]}>
                            <Input placeholder="Email ID"/>
                        </Form.Item>
                        <Form.Item label="Mobile Number" name={"mobile"}
                                   rules={[{ required: true },
                                       { pattern: MOBILE_REGEX, message: 'Please enter a valid mobile number' }]}>
                            <InputNumber style={{width: "100%"}} placeholder="Mobile Number"
                                        />
                        </Form.Item>
                        <Form.Item label="Date of Birth" name={"dob"}
                                   rules={[{required: true}]}>
                            <DatePicker format={"DD/MM/YYYY"} style={{width: "100%"}} placeholder="DD/MM/YYYY"/>
                        </Form.Item>
                        <Form.Item label="Firm Name (if any) " name={"firm_name"}>
                            <Input placeholder="Firm Name"/>
                        </Form.Item>
                        <Form.Item
                            label="Upload your ACA/FCA certificate"
                            valuePropName='defaultFileList'
                        >
                            <UploadFile
                                initialValue={props?.editData?.aca_fca_upload || null}
                                key={'Upload your ACA/FCA certificate'}
                                name={"aca_fca_upload"}
                                label={' '}
                                colon={false}
                                setState={setUploadFileState}
                            />
                        </Form.Item>
                        <Form.Item label="Upload your Photograph" name={"photo"}
                                   valuePropName='defaultFileList'>
                            <UploadFile
                                initialValue={props?.editData?.photo || null}
                                key={'Upload your Photograph'}
                                name={"photo"}
                                label={' '}
                                colon={false}
                                setState={setUploadFileState}
                            />
                        </Form.Item>
                    </>);
                break;
            case PROFESSION_ADVOCATE_ID:
                return (<>
                        <Form.Item label="Name" name={"name"}
                                   rules={[{required: true}]}>
                            <Input width={100} placeholder="Name"/>
                        </Form.Item>
                        <Form.Item label="Date of Birth" name={"dob"}
                                   rules={[{required: true}]}>
                            <DatePicker format={"DD/MM/YYYY"} style={{width: "100%"}} placeholder="DD/MM/YYYY"/>
                        </Form.Item>
                        <Form.Item
                            label="Enter your enrollment no."
                            name={"enrollment_number"}
                            rules={[{required: true}]}
                        >
                            <Input placeholder="Enrollment No."/>
                        </Form.Item>
                        <Form.Item
                            label="Enter your registration no. (if any) "
                            name={"registration_number"}
                        >
                            <Input placeholder="Registration No."/>
                        </Form.Item>
                        <Form.Item label="Email ID" name={"email"}
                                   rules={[{ required: true },
                                       { pattern: EMAIL_REGEX, message: 'Please enter a valid email address' }]}>
                            <Input  placeholder="Email ID"/>
                        </Form.Item>
                        <Form.Item label="Mobile Number" name={"mobile"}
                                   rules={[{ required: true },
                                       { pattern: MOBILE_REGEX, message: 'Please enter a valid mobile number' }]}>
                            <InputNumber style={{width: "100%"}} placeholder="Mobile Number"
                                         />
                        </Form.Item>
                        <Form.Item label="Firm Name (if any) " name={"firm_name"}>
                            <Input placeholder="Firm Name"/>
                        </Form.Item>
                        <Form.Item label="Upload LLB Degree"
                                   valuePropName='defaultFileList'>
                            <UploadFile
                                initialValue={props?.editData?.llb_degree_upload || null}
                                key={'Upload LLB Degree'}
                                name={"llb_degree_upload"}
                                label={' '}
                                colon={false}
                                setState={setUploadFileState}
                            />

                        </Form.Item>
                        <Form.Item
                            valuePropName='defaultFileList'
                            label="Upload your Photograph">
                            <UploadFile
                                initialValue={props?.editData?.photo || null}
                                key={'Upload LLB Degree'}
                                name={"photo"}
                                label={' '}
                                colon={false}
                                setState={setUploadFileState}
                            />

                        </Form.Item>
                    </>);

                break;
            case PROFESSION_CS_ID:
                return (<>
                        <Form.Item label="MRN Number" name={"mrn_number"}
                                   rules={[{required: true}]}>
                            <Input name="mrn_number" placeholder="MRN Number"/>
                        </Form.Item>
                        <Form.Item label="Enrollment Date" name={"enrollment_date"}
                                   rules={[{required: true}]}>
                            <DatePicker format={"DD/MM/YYYY"} style={{width: "100%"}} placeholder="DD/MM/YYYY"/>
                        </Form.Item>
                        <Form.Item label="Email ID" name={"email"}
                                   rules={[{ required: true },
                                       { pattern: EMAIL_REGEX, message: 'Please enter a valid email address' }]}>
                            <Input width={"100%"} placeholder="Email ID"/>
                        </Form.Item>
                        <Form.Item label="Mobile Number" name={"mobile"}
                                   rules={[{ required: true },
                                       { pattern: MOBILE_REGEX, message: 'Please enter a valid mobile number' }]}>
                            <InputNumber style={{width: "100%"}} placeholder="Mobile Number"
                                         />
                        </Form.Item>
                        <Form.Item label="Date of Birth" name={"dob"}
                                   rules={[{required: true}]}>
                            <DatePicker format={"DD/MM/YYYY"} style={{width: "100%"}} placeholder="DD/MM/YYYY"/>
                        </Form.Item>
                        <Form.Item label="Firm Name (if any) " name={"firm_name"}>
                            <Input width={"100%"} placeholder="Firm Name"/>
                        </Form.Item>
                        <Form.Item
                            label="Upload your Particing Certificate"
                            valuePropName='defaultFileList'
                        >
                            <UploadFile
                                initialValue={props?.editData?.practicing_upload || null}
                                key={'Upload your Particing Certificate'}
                                name={"practicing_upload"}
                                label={' '}
                                colon={false}
                                setState={setUploadFileState}
                            />
                        </Form.Item>
                        <Form.Item label="Upload your Photograph"
                                   valuePropName='defaultFileList'>
                            <UploadFile
                                initialValue={props?.editData?.photo || null}
                                key={'Upload your Photograph'}
                                name={"photo"}
                                label={' '}
                                colon={false}
                                setState={setUploadFileState}
                            />
                        </Form.Item>
                    </>);

                break;
            case PROFESSION_CMA_ID:
                return (<>
                        <Form.Item label="MRN Number" name={"mrn_number"}
                                   rules={[{required: true}]}>
                            <Input name="mrn_number" placeholder="MRN Number"/>
                        </Form.Item>
                        <Form.Item label="Enrollment Date" name={"enrollment_date"}
                                   rules={[{required: true}]}>
                            <DatePicker format={"DD/MM/YYYY"} style={{width: "100%"}} placeholder="DD/MM/YYYY"/>
                        </Form.Item>
                        <Form.Item label="Email ID" name={"email"}
                                   rules={[{ required: true },
                                       { pattern: EMAIL_REGEX, message: 'Please enter a valid email address' }]}>
                            <Input  placeholder="Email ID"/>
                        </Form.Item>
                        <Form.Item label="Mobile Number" name={"mobile"}
                                   rules={[{ required: true },
                                       { pattern: MOBILE_REGEX, message: 'Please enter a valid mobile number' }]}>
                            <InputNumber style={{width: "100%"}} placeholder="Mobile Number"
                                        />
                        </Form.Item>
                        <Form.Item label="Date of Birth" name={"dob"}
                                   rules={[{required: true}]}>
                            <DatePicker format={"DD/MM/YYYY"} style={{width: "100%"}} placeholder="DD/MM/YYYY"/>
                        </Form.Item>
                        <Form.Item label="Firm Name (if any) " name={"firm_name"}>
                            <Input placeholder="Firm Name"/>
                        </Form.Item>
                        <Form.Item
                            label="Upload your Participating Certificate"
                            // name={"practicing_upload"}
                            valuePropName='defaultFileList'
                        >
                           <UploadFile
                                initialValue={props?.editData?.practicing_upload || null}
                                key={'Upload your Participating Certificate'}
                                name={"practicing_upload"}
                                label={' '}
                                colon={false}
                                setState={setUploadFileState}
                            />
                        </Form.Item>
                        <Form.Item label="Upload your Photograph"
                                   valuePropName='defaultFileList'>
                            <UploadFile
                                initialValue={props?.editData?.photo || null}
                                key={'Upload your Photograph'}
                                name={"photo"}
                                label={' '}
                                colon={false}
                                setState={setUploadFileState}
                            />
                        </Form.Item>
                    </>);

                break;
            case PROFESSION_ACCOUNTANT_ID:
                return (<>
                        <Form.Item label="Name" name={"name"}>
                            <Input placeholder="Name"/>
                        </Form.Item>
                        <Form.Item label="Email ID" name={"email"}
                                   rules={[{ required: true },
                                       { pattern: EMAIL_REGEX, message: 'Please enter a valid email address' }]}>
                            <Input  placeholder="Email ID"/>
                        </Form.Item>
                        <Form.Item label="Mobile Number" name={"mobile"}
                                   rules={[{ required: true },
                                       { pattern: MOBILE_REGEX, message: 'Please enter a valid mobile number' }]}>
                            <InputNumber style={{width: "100%"}} placeholder="Mobile Number"
                                         />
                        </Form.Item>
                        <Form.Item label="Date of Birth" name={"dob"}
                                   rules={[{required: true}]}>
                            <DatePicker format={"DD/MM/YYYY"} style={{width: "100%"}} placeholder="DD/MM/YYYY"/>
                        </Form.Item>
                        <Form.Item label="Firm Name (if any) " name={"firm_name"}>
                            <Input placeholder="Firm Name"/>
                        </Form.Item>
                        <Form.Item
                            valuePropName='defaultFileList' label="Upload your Photograph">
                            <UploadFile
                                initialValue={props?.editData?.photo || null}
                                key={'Upload your Photograph'}
                                name={"photo"}
                                label={' '}
                                colon={false}
                                setState={setUploadFileState}
                            />
                        </Form.Item>
                    </>);

                break;
            default:
                return (<>
                        <Form.Item label="Name" name={"name"}>
                            <Input placeholder="Name"/>
                        </Form.Item>
                        <Form.Item label="Email ID" name={"email"}
                                   rules={[{ required: true },
                                       { pattern: EMAIL_REGEX, message: 'Please enter a valid email address' }]}>
                            <Input  placeholder="Email ID"/>
                        </Form.Item>
                        <Form.Item label="Mobile Number" name={"mobile"}
                                   rules={[{ required: true },
                                       { pattern: MOBILE_REGEX, message: 'Please enter a valid mobile number' }]}>
                            <InputNumber style={{width: "100%"}} placeholder="Mobile Number"
                                        />
                        </Form.Item>
                        <Form.Item label="Date of Birth" name={"dob"}
                                   rules={[{required: true}]}>
                            <DatePicker format={"DD/MM/YYYY"} style={{width: "100%"}} placeholder="DD/MM/YYYY"/>
                        </Form.Item>
                        <Form.Item label="Enter your Professional" name={"professional"}>
                            <Input placeholder="Profession (Eg. Banker, DSA, Architect, Shopkeeper, VLE, etc) "/>
                        </Form.Item>
                        <Form.Item label="Firm Name (if any) " name={"firm_name"}>
                            <Input placeholder="Firm Name"/>
                        </Form.Item>
                        <Form.Item label="Upload your Photograph"
                                   valuePropName='defaultFileList'>
                            <UploadFile
                                initialValue={props?.editData?.photo || null}
                                key={'Upload your Photograph'}
                                name={"photo"}
                                label={' '}
                                colon={false}
                                setState={setUploadFileState}
                            />
                        </Form.Item>
                    </>);

                break;
        }
    };

    return (<Spin spinning={loading}>
            <Form
                onFinish={onFinish}
                // validateMessages={validateMessages}
                {...layout}
                ref={formRef}
                key={props.editData ? props.editData.id : "1"}
                initialValues={{
                    ...props.editData,
                    dob: editData && moment(editData.dob, 'YYYY-MM-DD'),
                    enrollment_date: props.editData && moment(props.editData.enrollment_date, 'YYYY-MM-DD'),
                }}
            >
                <Form.Item label="Organization" name={'organization'}
                           rules={[{required: true}]}>
                    <Select
                        showSearch
                        allowClear
                        style={{width: '100%'}}
                        placeholder={'Organization'}
                        onChange={(value, option) => onHandleChangeOrganization(value, option)}

                    >
                        {organizationList?.map((option) => (
                            <Select.Option label={option} key={option.id} value={option.id}
                            >
                                {option.name}
                            </Select.Option>))}
                    </Select>
                </Form.Item>
                <Form.Item label="Partner" name={'partner'}
                           rules={[{required: true}]}>
                    <Select
                        showSearch
                        allowClear
                        style={{width: '100%'}}
                        placeholder={'Partner'}
                    >
                        {partnerList?.map((option) => (
                            <Select.Option label={option} key={option.id} value={option.id}
                            >
                                {option.first_name}
                            </Select.Option>))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={"Profession"}
                    name={"profession"}
                    rules={[{required: true}]}
                >
                    <Select
                        showSearch
                        allowClear
                        style={{width: "100%"}}
                        placeholder={"Profession"}
                        onChange={(value) => handleProfessionChange(value)}
                    >
                        {professionList?.map((option) => (<Select.Option
                                key={option.id}
                                value={option.id}
                            >
                                {option.value}
                            </Select.Option>))}
                    </Select>
                </Form.Item>

                { changeProfessionType(selectedProfession)}

                <Form.Item {...tailLayout}>
                    <Space>
                        <Button
                            htmlType="submit"
                            type={"primary"}
                            className={"theme-color"}
                        >
                            Save
                        </Button>
                        {props.editData && <Button onClick={handleCancelEdit}>Cancel</Button>}
                    </Space>
                </Form.Item>
            </Form>
        </Spin>);
};

export default AddOrEditPartnerKYC;
