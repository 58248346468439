import { Button, Form, Input, Space, Spin } from 'antd'
import React, { useState } from 'react'
import { GST_SETTING } from '../../../../constants/api'
import { SUCCESS_MSG_TYPE } from '../../../../constants/dataKeys'
import { layout, tailLayout } from '../../../../constants/hardData'
import {  postAPI } from '../../../../utils/apiRequest'
import { displayMessage } from '../../../../utils/common'

const AddOrEditGSTSettings = (props) => {

  const [loading, setLoading] = useState(false)

  const onFinish = (values) => {
    let { editData } = props;
    setLoading(true)
    let reqData = {...values}

    let successFn = function(){
      setLoading(false)
      props.onSelectTab('view-gst')
      if(editData){
        displayMessage(SUCCESS_MSG_TYPE, 'GST Value Updated Successfully.');

      }else{
      displayMessage(SUCCESS_MSG_TYPE, 'GST Value Added Successfully.');
      }
    }
    let errorFn = function(error){
      setLoading(false)
    }
    if (editData) {
      reqData.id = editData.id;
      postAPI(GST_SETTING, reqData, successFn, errorFn);
    }
    else{
    postAPI(GST_SETTING, reqData, successFn, errorFn)
    }
  }

  const handleCancelEdit = () => {
    props.onSelectTab("view-gst");
  }
  
  return (
   
        <Spin spinning={loading}>
        <Form
          onFinish={onFinish}
          // validateMessages={validateMessages}
          {...layout}
          key={props.editData ? props.editData.id : '1'}
          initialValues={{
            ...props.editData,
          }}
        >
          <Form.Item
            label={'Name'}
            name={'name'}
            rules={[{ required: true }]}
          >
           <Input placeholder='Name' />
          </Form.Item>
          <Form.Item
            label={'Invoice Name'}
            name={'invoice_name'}
            rules={[{ required: true }]}
          >
            <Input placeholder='Invoice Name' />
          </Form.Item>
          <Form.Item
            label={'Value'}
            name={'value'}
            rules={[{ required: true }]}
          >
            <Input placeholder={'Value%'} />
          </Form.Item>
          <Form.Item 
          {...tailLayout}
          >
            <Space>
              <Button
                htmlType='submit'
                type={'primary'}
                className={'theme-color'}
              >
                Save
              </Button>
              {props.editData && 
            <Button onClick={handleCancelEdit} >Cancel</Button> }
            </Space>
          </Form.Item>
        </Form>
      </Spin>
  )
}

export default AddOrEditGSTSettings