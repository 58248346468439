/*** Base URLs **/
export const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
export const API_URL = BACKEND_BASE_URL + '/seva-api/v1';
export const IMAGE_BASE_URL = BACKEND_BASE_URL + '/media';
export const IMAGE_BASE_ONBOARD_URL = BACKEND_BASE_URL + '/onboard-media';
export const FILE_UPLOAD = 'uploads/';
export const USER_LOGIN = 'users/login/';
export const USER_LOGOUT = 'logout/';
export const USER_CLONE = 'users/user_clone/';
export const CHANGE_PASSWORD = 'users/password_change/';
export const SETTINGS_DYNAMIC_DROPDOWN_LIST = 'admin_settings/dropdown/';
export const SETTINGS_DYNAMIC = 'admin_settings/';
export const SETTINGS_DYNAMIC_DETAIL = 'admin_settings/%s/';
export const RESET_PASSWORD_MAIL = 'users/user_reset_mail/';
export const RESET_PASSWORD = 'users/reset_password/';
export const DEMO_PARTNER_LOGIN_DATA  = 'admin_settings/lead/'


export const COUNTRY = "admin_settings/country/"
export const STATE = "admin_settings/state/"
export const CITY = "admin_settings/city/"
export const SMS_SETTING = "admin_settings/sms_setting/"
export const GST_SETTING = "admin_settings/gst_setting/"
export const HSN_SETTING = "admin_settings/hsn_settings/"
export const SUBSCRIPTION_PLAN = "admin_settings/subscription_plan/"
export const SUBSCRIPTION_HISTORY = "partner/subscription/"
export const POLICIES = "admin_settings/policies/"
export const ORGANIZATION = "admin_settings/organization/"
export const PARTNER = "admin_settings/partner/"
export const PROFESSION = "admin_settings/?name=Profession"
export const POLICY_TYPE = "admin_settings/"
export const ALL_SERVICES = "admin_settings/all_services/"
export const MANAGER = "users/admin_list/"
export const PARTNER_CREDITS = "admin_settings/credits/"
export const CURRENT_POINT = "admin_settings/current_credits/"
export const PARTNER_KYC = "admin_settings/partner_kyc/"
export const PARTNER_KYC_DETAIL = "admin_settings/partner_kyc/"
export const ADMIN_COUPON = "admin_settings/coupon_code/"
export const PARTNER_POLICIES = "admin_settings/partner_policies/"
export const ADD_SUGGESTION = 'partner/support/'
export const DELETED_ORGANIZATIONS = 'admin_settings/deleted_organization/'
export const FEEDBACK = "customer/feedback/"
export const BECOME_PARTNER = "customer/become_partner/"

export const REFRESH_API = "token/refresh/";
export const EMPLOYEE = "admin_settings/partner";
export const DISABLE_PARTNER = 'partner/disable_partner/';
export const DELETED_PARTNER = 'admin_settings/deleted_partner/';
export const FAQ = 'partner/faq/';
export const FAQ_TEMPLATE = "admin_settings/faq_template/";
export const DESCRIPTION_TEMPLATE = "admin_settings/description_template/";
export const SEND_EMAIL = "admin_settings/send_coupon/";
export const GET_FILE_UPLOAD_SIGNATURE = "uploads/presigned_url/";
export const VIEW_UPLOADED_FILE = 'uploads/download_file/';
export const UPDATE_SUBSCRIPTION = 'admin_settings/subscription/';
export const TEMPLATE_DOCUMENT = 'admin_settings/self_document/';

export const DELETE_PARTNER = 'admin_settings/deleted_partner/';
export const REQUEST_ON_BOARDING = 'admin_settings/on_boarding/';
export const COUPON_BOARDING = 'admin_settings/onboard_coupon_code/';
export const APPROVE_BOARDING = '/admin_settings/on_boarding_approve/';