import { Button, Card, Divider, Modal, Popconfirm, Spin, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { SUBSCRIPTION_PLAN } from '../../../../constants/api'
import { getAPI, postAPI } from '../../../../utils/apiRequest'
import InfiniteFeedLoaderButton from '../../../common/InfiniteFeedLoaderButton'
import {EditOutlined,DeleteOutlined, EyeOutlined} from '@ant-design/icons'

const ViewSubscription = (props) => {
    const [loading, setLoading] = useState(false)
    const [subscriptionList, setSubscriptionList] = useState([])
    const [nextPage, setNextPage] = useState(1)

    useEffect(() => {
        getSubscription();
    }, [])

    const getSubscription = (page=1) => {
        setLoading(true)
        let apiParams = {
          page,
        }
        let successFn = function(result){
            setLoading(false)
            setSubscriptionList([...(result.current === 1? [] : subscriptionList), ...result.results])
            setNextPage(result.next)
        }

        let errorFn = function(error){
            setLoading(false)
            console.log(error)
        }
       getAPI(SUBSCRIPTION_PLAN, successFn, errorFn, apiParams)
    }

    const deleteObject = (record) => {
      let reqData = {
        ...record,
        is_active: false,
      };
      let successFn = function (result) {
        getSubscription();
      };
      let errorFn = function (error) {};
  
     postAPI(SUBSCRIPTION_PLAN, reqData, successFn, errorFn)
    };

    const editObject = (record) => {
      props.onSelectTab("add-subscription", record);
    };


    const columns = [
        {
            title: 'S. No.',
            key: 's_no',
            render: (item, record, index) =>
            subscriptionList.indexOf(record) + 1,
        },
        {
            title: 'Name',
            key: 'name',
            render: (item, record) => (
                <span>
                  {record.name
                    ? `${record.name}`
                    : '--'}
                </span>
              ),
        },
        // {
        //     title: 'Start Size',
        //     key: 'start_size',
        //     render: (item, record) => (
        //         <span>
        //           {record.start_size
        //             ? `${record.start_size}`
        //             : '--'}
        //         </span>
        //       ),
        // },
        {
            title: 'End Size',
            key: 'end_size',
            render: (item, record) => (
                <span>
                  {record.end_size
                    ? `${record.end_size}`
                    : '--'}
                </span>
              ),
        },

        {
          title:'Valid For (In Days)',
          key:'expiry_days',
          render: (item,record) => (
            <span>
              {record.expiry_days ? `${record.expiry_days}` : '--'}
            </span>
          ),

        },
        {
          title:'Features',
          key:'features_data',
          render: (item,record) => (
            <span>
              {record.features_data ? 
               <ViewCheckListModal
               checkList={record.features_data}
               record={record}
               setLoading={setLoading}
               loading={loading}
             />
              : '--'}
            </span>
          ),

        },
        {
          title: 'Price',
          key: 'price',
          render: (item, record) => (
              <span>
                {record.price
                  ? `${record.price}`
                  : '--'}
              </span>
            ),
      },
        {
          title: 'HSN',
          key: 'hsn',
          render: (item, record) => (
              <span>
                {record?.hsn
                  ? `${record?.hsn_data?.hsn_no}`
                  : '--'}
              </span>
            ),
      },

        {
          title: 'Default',
          key: 'is_default',
          render: (item, record) => (
              <span>
                 
                  {record.is_default
                    ? `${record.is_default ? "Yes": "No" }`
                    : 'No'}
          
              </span>
            ),
      },
        {
          title: "Action",
          key: "action",
          render: (text, record) => (
            <span>
              <a onClick={() => editObject(record)}> < EditOutlined style={{ color: 'blue' }}/></a>
              <Divider type="vertical" />
              <Popconfirm
                title="Are you sure to delete this?"
                onConfirm={() => deleteObject(record)}
                okText="Yes"
                cancelText="No"
              >
                <a><DeleteOutlined style={{ color: 'red' }}/></a>
              </Popconfirm>
            </span>
          ),
        },
    ]
    
  return (
    <Card>
    <Spin spinning={loading}>
          <Table
            dataSource={subscriptionList}
            columns={columns}
            pagination={false}
          />
        </Spin>
        <InfiniteFeedLoaderButton
          loaderFunction={() => getSubscription(nextPage)}
          loading={loading}
          hidden={!nextPage}
        />
      </Card>
  )
}

export default ViewSubscription


const ViewCheckListModal = ({
  checkList,
  record,
  loading,
  setLoading,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };

  const hideModal = () => {
    setModalVisible(false);
  };

  return (
    <>
      <Button
        type="link"
        icon={<EyeOutlined style={{ color: "blue" }} />}
        onClick={showModal}
      />
      <Modal
        open={modalVisible}
        title="Feature List"
        onCancel={() => hideModal()}
        footer={null}
      >
        <Spin spinning={loading}>
          <div>
            {checkList?.map((item, index) => (
              <div key={index}>{index+1}. {item}</div>
            ))}
          </div>
        </Spin>
      </Modal>
    </>
  );
};
