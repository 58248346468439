import React, { Component } from 'react';
import AppBase from '../../../base/AppBase';
import { Button, Card, Form, Input } from 'antd';
import { displayMessage } from '../../../../utils/common';
import { ERROR_MSG_TYPE, SUCCESS_MSG_TYPE } from '../../../../constants/dataKeys';
import { postAPI } from '../../../../utils/apiRequest';
import { connect } from 'react-redux';
import { logoutUserAuthAction } from '../../../../redux/actions/authReduxActions';
import { REQUIRED_FIELD_WITH_NAME_MESSAGE } from '../../../../constants/message';
import { _get } from '../../../../utils/lodashUtils';
import { CHANGE_PASSWORD } from '../../../../constants/api';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onFinish = (values) => {
    let that = this;
    let successFn = function(data) {
      displayMessage(SUCCESS_MSG_TYPE, 'Your Password is changed successfully');
      setTimeout(function() {
        that.props.logoutUserAuthAction();
        that.props.history.push('/login');
      }, 1000);
    };
    let errorFn = function(error) {
      Object.values(error).map((item) => displayMessage(ERROR_MSG_TYPE, item));
    };
    postAPI(CHANGE_PASSWORD, values, successFn, errorFn);
  };

  render() {
    const formLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 16 },
    };
    const tailLayout = {
      wrapperCol: { offset: 4, span: 16 },
    };
    return (
      <AppBase>
        <Card>
          <Form
            {...formLayout}
            validateMessages={validateMessages}
            onFinish={this.onFinish}
          >
            <Form.Item
              name={'old_password'}
              label={'Old Password'}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name={'new_password'}
              label={'New Password'}
              rules={[
                {
                  required: true,
                  message: 'include atleast one special character !@#$%^&* , one number , one capital letter',
                  pattern: new RegExp(
                    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
                  ),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name='confirm'
              label='Confirm Password'
              dependencies={['new_password']}
              rules={[
                {
                  required: true,
                  pattern: new RegExp(
                    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
                  ),
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('new_password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      'The two passwords that you entered do not match!',
                    );
                  },
                }),
              ]}
            >
              <Input type={'password'} />
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button onClick={() => this.props.history.goBack()}>
                Cancel
              </Button>
              <Button
                htmlType={'submit'}
                type={'primary'}
                className={'theme-color'}
                style={{ float: 'right' }}
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </AppBase>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedIn: _get(state, 'auth.loggedIn'),
  };
};

const mapDispatchToProps = {
  logoutUserAuthAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);

const validateMessages = {
  required: REQUIRED_FIELD_WITH_NAME_MESSAGE,
};
