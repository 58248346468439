import { Button, Form, Input, Select, Space, Spin, Checkbox, DatePicker } from 'antd'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { ORGANIZATION, PARTNER } from '../../../../constants/api'
import { SUCCESS_MSG_TYPE } from '../../../../constants/dataKeys'
import { layout, tailLayout } from '../../../../constants/hardData'
import { getAPI, postAPI } from '../../../../utils/apiRequest'
import {displayMessage, EMAIL_REGEX, MOBILE_REGEX, validateEmail, validateMobileNumber} from '../../../../utils/common'

const AddOrEditPartner = (props) => {

  const [loading, setLoading] = useState(false)
  const [organizationList, setOrganizationList] = useState([])
  const [checkValue, setCheckValue] = useState(false);
  const [error, setError] = useState(null);
  const [errorMobile, setErrorMobile] = useState(null);
  const [validEmail, setValidEmail] = useState(false);
  const [validMobile, setValidMobile] = useState(false);

  useEffect(() => {
   getOrganization();
  }, [])

  useEffect(()=> {
    let { editData } = props;
    if(editData){
      if(editData?.user_data){
        if(editData?.user_data?.email){
          setValidEmail(validateEmail(editData?.user_data?.email))
      }
      if(editData?.user_data?.mobile){
          setValidMobile(validateMobileNumber(editData?.user_data?.mobile))
      }
      }
    }
  },[props.editData])

  const onChange = (e) => {
    setCheckValue(e.target.checked);
 };

 const handleCancelEdit = () => {
  props.onSelectTab("view-partner");
}
  

 

  const onFinish = (values) => {
    var { editData } = props;
    let joiningDate = values.joining_date ? moment(values.joining_date).format("YYYY-MM-DD") : undefined;
    setLoading(true)
    let reqData = {
      ...values,
      joining_date: joiningDate,
      is_primary: checkValue }

    let successFn = function(){
      
      setLoading(false)
      props.onSelectTab('view-partner')
      if(editData){
        displayMessage(SUCCESS_MSG_TYPE, 'Partner Updated Successfully.');
      }
      else{
        displayMessage(SUCCESS_MSG_TYPE, 'Partner Added Successfully.');
      }
    }
    let errorFn = function(error){
        console.log(error)
      setLoading(false)
    }

    if (editData) {
      reqData.id = editData.id;
      postAPI(PARTNER, reqData, successFn, errorFn)
    }
    else{
      postAPI(PARTNER, reqData, successFn, errorFn)
    }
  
  }
  

  const getOrganization = () => {
    setLoading(true)

    let successFn = function(result){
        setLoading(false)
        setOrganizationList(result.results)
        console.log(result)
    }

    let errorFn = function(error){
        setLoading(false)
        console.log(error)
    }
   getAPI(ORGANIZATION, successFn, errorFn)
}

    const handleEmailChange = (e) => {
      const { value } = e.target;
      setValidEmail(validateEmail(value))
      if (validateEmail(value)) {
          setError(null);
        props.onChange(e);
      }
      else {
          setError('Invalid email address');
      }
    };

    const handleMobileChange = (e) => {
      const { value } = e.target;
      setValidMobile(validateMobileNumber(value))
      if (validateMobileNumber(value)) {
          setErrorMobile(null);
        props.onChange(e);
      } else {
          setErrorMobile('Invalid mobile number');
      }
    };

  
  return (
   
        <Spin spinning={loading}>
        <Form
          onFinish={onFinish}
          // validateMessages={validateMessages}
          {...layout}
          key={props.editData ? props.editData.id : '1'}
          initialValues={{
            ...props.editData?.user_data,
            ...props.editData,
            joining_date : props.editData && props.editData.joining_date ? moment(props.editData.joining_date, "YYYY-MM-DD") : ''
          }}
        >
          <Form.Item
              label={'Organization'}
              name={'organization'}
              rules={[{ required: true }]}
          >
            <Select
                showSearch
                allowClear
                style={{ width: '100%' }}
                placeholder={'Organization'}
                optionFilterProp="children"
            >
              {organizationList?.map((option) => (
                  <Select.Option label={option.label} key={option.id} value={option.id}>
                    {option.name}
                  </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={'Name'}
            name={'first_name'}
            rules={[{ required: true }]}
          >
            <Input placeholder='Name'/>
           
          </Form.Item>
          <Form.Item
            label={'Mobile'}
            name={'mobile'}
            rules={[{ required: true },
              { pattern: MOBILE_REGEX, message: 'Please enter a valid mobile number' }]}
          >
            <Input placeholder={'Mobile'}  />
          </Form.Item>
          <Form.Item
            label={'Email'}
            name={'email'}
            rules={[{ required: true },
              { pattern: EMAIL_REGEX, message: 'Please enter a valid email address' }]}
          >
            <Input type='email' placeholder='Email'/>
           
          </Form.Item>

          <Form.Item
            label={'Joining Date'}
            name={'joining_date'}
          >
            {/* <Input placeholder='Email'/> */}
            <DatePicker placeholder='DD/MM/YYYY' format={"DD/MM/YYYY"}  style={{width:'100%'}}/>
           
          </Form.Item>

          <Form.Item
            label={'Department'}
            name={'department'}
          >
            <Input placeholder='Department'/>
           
          </Form.Item>



          <Form.Item label={"Designation"} name={'designation'} >
            <Input placeholder="Designation"/>
          </Form.Item>

          <Form.Item
            label={'Primary'}
            name={'is_primary'}
            valuePropName='checked'
          >
            <Checkbox onChange={onChange}></Checkbox>
           
          </Form.Item>
        
          <Form.Item 
          {...tailLayout}
          >
            <Space>
              <Button
                htmlType='submit'
                type={'primary'}
                className={'theme-color'}
              >
                Save
              </Button>
              {props.editData && 
            <Button onClick={handleCancelEdit} >Cancel</Button> }
            </Space>
          </Form.Item>
        </Form>
      </Spin>
  )
}

export default AddOrEditPartner