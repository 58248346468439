import AppBase from "../../../base/AppBase";
import {Card, Switch, Spin, Table, Tag, Select, Space} from "antd";
import React, {useEffect, useState} from "react";
import {getAPI, putAPI} from "../../../../utils/apiRequest";
import {DELETE_PARTNER, DELETED_PARTNER, DISABLE_PARTNER, EMPLOYEE, ORGANIZATION, PARTNER} from "../../../../constants/api";
import {displayMessage} from "../../../../utils/common";
import {SUCCESS_MSG_TYPE} from "../../../../constants/dataKeys";
import moment from 'moment';
import {userFullName} from "../../../../utils/employeeUtils";
import {SyncOutlined} from '@ant-design/icons';
import InfiniteFeedLoaderButton from "../../../common/InfiniteFeedLoaderButton";
import { filter } from "lodash";


const DeletePartner = (props) => {
    const [loading, setLoading] = useState(false);
    const [deletedPartner, setDeletedPartner] = useState([]);
    const [nextPage, setNextPage] = useState(1)
    const [organizationList, setOrganizationList] = useState([])
    const [filterKeys, setFilterKeys] = useState({})





    useEffect(() => {
        loadDeletedPartner();
    }, [filterKeys])


    useEffect(() => {
        loadDeletedPartner().then(r => r);
        getOrganization();
    }, [])
    const loadDeletedPartner = async (page=1) => {
        let apiParams = {
            page,
            ...filterKeys
        }
        setLoading(true);
        const successFn = (data) => {
            setLoading(false)
            setDeletedPartner([...(data.current === 1 ? [] : deletedPartner), ...data.results])
            setNextPage(data.next)
        }
        const errorFn = (error) => {
            setLoading(false);
        }
        await getAPI(DELETED_PARTNER, successFn, errorFn, apiParams)
    }

    const  filterData = (type, value) => {
        setFilterKeys(filterKeys=>{ return{...filterKeys,  [type] : value? value : undefined}})
  };

    const getOrganization = () => {
        setLoading(true)
  
        let apiParams = {
          pagination: false,
        }
        console.log(apiParams)
  
        let successFn = function(result){
            setLoading(false)
            setOrganizationList(result)
        }
  
        let errorFn = function(error){
            setLoading(false)
            console.log(error)
        }
       getAPI(ORGANIZATION, successFn, errorFn, apiParams)
    }

    const enabledPartner = async (record) => {
      
        let reqData = {
            id:record?.id,
            is_active: true
        }
        const successFn = function (data) {
            displayMessage(SUCCESS_MSG_TYPE, "Partner retrieve for this Organisation successfully!!");
            loadDeletedPartner()
           
        };
        const errorFn = function () {
     

        };
        await putAPI(DELETE_PARTNER, reqData, successFn, errorFn);
    };
    const columns = [
        {
            title: "S.No.",
            key: "s_no",
            render: (item, record, index) => deletedPartner.indexOf(record) + 1
        },
        {
            title: 'Name',
            dataIndex: 'first_name',
            key: 'name',
            render: (item, record) => <span>{record.user ? userFullName(record) : '--'}</span>
        },
        {
            title: 'Mobile No.',
            key: 'user_data',
            render: (item, record) => (
                <span>
                {record.mobile
                    ? `${record.mobile}`
                    : '--'}
              </span>
            ),
        },
        {
            title: 'Organization',
            key: 'organization_data',
            render: (item, record) => (
                <span>
                  {record?.organization ? record?.organization_data?.name : '--'}
                </span>
            ),
        },

        {
            title: "Retrieve Partner",
            dataIndex: "is_active",
            key: "is_active",
            render: (item, record) => (record.is_active ?
                <Tag color="red">Not Allowed</Tag> :
                // <Switch defaultChecked={!!item} onChange={(e) => enabledPartner(record, e)}/>
                <SyncOutlined  style={{color:'blue'}} onClick={() => enabledPartner(record)}/> 
                )
        },
    ]
    return (
        <Card
        title={
          <Space>
            Filters :
            <Select
              placeholder={'Organization'}
              allowClear
              style={{width: "200px"}}
              onChange={(e) => filterData('organization', e)}
              showSearch
              optionFilterProp="children"
            >
               {organizationList?.map((option) => (
                    <Select.Option label={option.name} value={option.id}>
                      {option.name}
                    </Select.Option>
                  ))}
            </Select>
            
            </Space>
             }
             bodyStyle={{ padding: 4, border: 0 }}>
                <Spin spinning={loading}>
                    <Table size={'small'} columns={columns} dataSource={deletedPartner}
                           pagination={false}/>
                    <InfiniteFeedLoaderButton
                        loaderFunction={() => loadDeletedPartner(nextPage)}
                        loading={loading}
                        hidden={!nextPage}
                    />
                </Spin>
            </Card>
       
    )
}
export default DeletePartner