import React, { Component } from 'react';
import AuthBase from '../AuthBase';
import { Button, Card, Col, Form, Input, Row } from 'antd';
import { postAPI } from '../../../../utils/apiRequest';
import { displayMessage } from '../../../../utils/common';
import { SUCCESS_MSG_TYPE } from '../../../../constants/dataKeys';
import { RESET_PASSWORD } from '../../../../constants/api';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onFinish = (values) => {
    let that = this;
    let reqData = {
      ...values,
      code:
        this.props.match && this.props.match.params.token
          ? this.props.match.params.token
          : null,
    };
    let successFn = function(result) {
      displayMessage(SUCCESS_MSG_TYPE, 'Password Changed Successfully');
      that.props.history.push('/');
    };
    let errorFn = function(error) {
    };
    delete reqData.repeat_password;
    postAPI(RESET_PASSWORD, reqData, successFn, errorFn);
  };

  render() {
    const formLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };
    const tailLayout = {
      wrapperCol: { offset: 8, span: 16 },
    };
    return (
      <AuthBase>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <h2 className={'auth-type-title'}>Reset Password</h2>
            <Card bordered={false}>
              <Form
                {...formLayout}
                id={'components-form-demo-normal-login'}
                name='reset-password'
                size={'large'}
                onFinish={this.onFinish}
              >
                <Form.Item label={'Password'} name={'password'}>
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  name='repeat_password'
                  label='Repeat Password'
                  dependencies={['password']}
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          'The two passwords that you entered do not match!',
                        );
                      },
                    }),
                  ]}
                >
                  <Input type={'password'} />
                </Form.Item>
                <Form.Item {...tailLayout}>
                  <Button
                    type='primary'
                    className={'theme-color'}
                    htmlType='submit'
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </AuthBase>
    );
  }
}

export default ResetPassword;
