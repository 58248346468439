import {Button, Form, message, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import {postAPI, putOuterAPI} from "../../../utils/apiRequest";
import {GET_FILE_UPLOAD_SIGNATURE} from "../../../constants/api";
import {_get} from "../../../utils/lodashUtils";
import ViewUploadedFile from "./ViewUploadedFile";


let s3SignatureUrl, s3FileUploadType, s3SignatureFileName;


const UploadFile = (props) => {
    const [fileList, setFileList] = useState([])
    const handleFileUpload = info => {
        let fileList = [...info.fileList];
        if (info.file.status !== "uploading") {
        }
        if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
        }
        let updatedFileList = fileList.map(file => {
            file.url = _get(file, "xhr.responseURL", "").split("?")[0];
            file.name = file.name.slice(0, 22) + (file.name.length > 22 ? "..." : "");
            return file;
        });
        setFileList(updatedFileList)
    };

    const fileUploadProps = {
        maxCount: 1,
        action: s3SignatureUrl,
        method: "put",
        onChange: handleFileUpload,
        beforeUpload: (file) => {
            let successFn = function (data) {
                s3SignatureUrl = data.url;
                s3SignatureFileName = data.file_name;
                s3FileUploadType = file.type;
            };
            let errorFn = function () {
            };
            postAPI(
                GET_FILE_UPLOAD_SIGNATURE,
                {file: file.name, file_type: file.type},
                successFn,
                errorFn
            );
        },
        headers: {
            "Content-Type": s3FileUploadType
        },

    };

    const customRequest = (customProps) => {
        let successFn = function (data) {
            customProps.onSuccess();
            props.setState((prevState) => ({
                ...prevState,
                [props.name]: s3SignatureFileName
            }));
            // props.setChooseFileState(null)
        };
        let errorFn = function (error) {
        }
        setTimeout(async function () {
            putOuterAPI(
                s3SignatureUrl,
                customProps.file,
                successFn,
                errorFn,
                {
                    "Content-Type": customProps.file.type
                },
            );
        }, 500)

    }


    return (
        <Form.Item {...props}
        >
            <Upload {...fileUploadProps}
                    customRequest={(props) => customRequest(props)}
                    className={"center my-upload"}
                    style={{display: "block", margin: "auto"}}
            >
                <Button>
                    <UploadOutlined/> Click to Upload
                </Button>

            </Upload>
            {props.initialValue ?
                <ViewUploadedFile
                    value={props?.initialValue}
                /> : null}
        </Form.Item>
    )
}
export default UploadFile

function getBinaryFromFile(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.addEventListener("load", () => resolve(reader.result));
        reader.addEventListener("error", err => reject(err));

        reader.readAsBinaryString(file);
    });
}