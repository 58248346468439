import { Card, Tabs } from 'antd'
import TabPane from 'antd/lib/tabs/TabPane'
import React, {useState,useEffect} from 'react'
import { PROFESSION } from '../../../../../constants/api'
import { getAPI } from '../../../../../utils/apiRequest'
import AppBase from '../../../../base/AppBase'
import AddOrEditPartnerKYC from './AddOrEditPartnerKYC'
import ViewPartnerKYC from './ViewPartnerKYC'


const PartnerKYC = (props) => {
    const [selectedTab, setSelectedTab] = useState("add-partner-kyc")
    const [ editData, setEditData ] =useState(null);
    const [professionList, setProfessionList] = useState([])
    const onSelectTab = (option, extra) => {
        setSelectedTab(option)
        setEditData(extra);
      };

      useEffect(() => {
        loadprofession();
      }, [])
      

      const loadprofession = (page=1) => {
        // setLoading(true)
        let apiParams = {
          page
        }
        let successFn = (result) => {
          // setLoading(false)
          setProfessionList([...(result.current === 1? [] : professionList), ...result.results])
          // setNextPage(result.next)
        }
        let errorFn = (error) => {
          // setLoading(false)
          console.log(error)
        }
        getAPI(PROFESSION, successFn, errorFn, apiParams)
      }


  return (
    <AppBase
    metaDetails={{
        title: 'Employee KYC',
      }}
    >
        <Card>
            <Tabs onChange={onSelectTab} activeKey={selectedTab}>
                <TabPane tab="Employee KYC" key="add-partner-kyc">
                    {selectedTab ==="add-partner-kyc"? (
                        <AddOrEditPartnerKYC
                        editData={editData}
                        key={'add-partner-credits'}
                        onSelectTab={onSelectTab}
                        {...props}/>
                    ):
                    null}
                </TabPane>

                {professionList.map((item) => <TabPane  tab= {`VIEW ${item.value} KYC`} key= {item.id}>
                { selectedTab == item.id ?
                <ViewPartnerKYC onSelectTab={onSelectTab} profession={item.id} databundle={item} {...props}/>
           :null} 
            </TabPane>)}
            </Tabs>
        </Card>
    </AppBase>
  )
}



export default PartnerKYC