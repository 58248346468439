import { _get } from './lodashUtils';
import {
  ABSENT_COLOUR,
  CO_COLOUR,
  HALF_DAY_COLOUR,
  HALF_LEAVE,
  HOLIDAY_COLOUR,
  HR_MODIFIED_COLOUR,
  OD_COLOUR,
  PRESENT_COLOUR,
} from '../constants/dataKeys';
import { DATE, DATE_TIME, HEADING_FIELD, LABEL_VALUE_FIELD, TIME } from '../constants/hardData';

export const employeeDropDownListViewConverter = function(employeeData) {
  if (employeeData) {
    let returningList = employeeData.map((employee) => {
      return {
        label: `(${_get(employee, 'employee_code_data')}) ${_get(
          employee,
          'first_name',
        )} ${_get(employee, 'middle_name')} ${_get(employee, 'last_name')}`,
        ...employee,
      };
    });
    return returningList;
  }
  return [];
};

export const employeeNameAndCode = function(employeeData) {
  if (employeeData)
    return `(${_get(employeeData, 'employee_code_data')}) ${_get(
      employeeData,
      'first_name',
    )} ${_get(employeeData, 'middle_name')} ${_get(employeeData, 'last_name')}`;
  return null;
};
export const employeeFullNameAndCode = function(employeeData) {
  if (employeeData)
    return `(${_get(employeeData, 'employee_code_data', '')}) ${_get(
      employeeData,
      'first_name',
    )} ${stringCapitalize(_get(employeeData, 'middle_name'))} ${_get(
      employeeData,
      'last_name',
    )}`;
  return null;
};
export const userFullName = function(employeeData) {
  if (employeeData)
    return `${stringCapitalize(
      _get(employeeData, 'first_name'),
    )} ${stringCapitalize(
      _get(employeeData, 'middle_name'),
    )} ${stringCapitalize(_get(employeeData, 'last_name'))}`;
  return null;
};

export const stringCapitalize = (stringValue) => {
  if (stringValue) {
    return stringValue.charAt(0).toUpperCase() + stringValue.slice(1);
  } else {
    return ' ';
  }
};

export const truncateString = (longString, size) => {
  if (longString && longString.length > size) {
    return longString.substring(0, size) + '...';
  }
  return longString;
};

export const calculateExperience = (year, month) => {
  let yearString = '';
  let monthString = '';
  if (year) {
    yearString = year + `Year`;
  }
  if (month) {
    monthString = month + 'Month';
  }
  return yearString + ' ' + monthString;
};

export const getAttendanceColor = (oldColor = null, value = null) => {
  if (oldColor) {
    if (oldColor === 'green') {
      return HR_MODIFIED_COLOUR;
    }
    switch (value) {
      case 'P':
        return PRESENT_COLOUR;
      case 'H':
        return HOLIDAY_COLOUR;
      case 'A':
        return ABSENT_COLOUR;
      case 'OD':
        return OD_COLOUR;
      case 'CO':
        return CO_COLOUR;
      default:
        return PRESENT_COLOUR;
    }
  }
};

export const getHRAttendanceColor = (oldColor = null) => {
  if (oldColor) {
    switch (oldColor) {
      case 'black':
        return PRESENT_COLOUR;
      case 'orange':
        return HOLIDAY_COLOUR;
      case 'red':
        return ABSENT_COLOUR;
      case 'green':
        return HR_MODIFIED_COLOUR;
      case 'blue':
        return HALF_DAY_COLOUR;
      default:
        return PRESENT_COLOUR;
    }
  }
};

export const getHalfLeaveShortName = (leave_code = null) => {
  if (leave_code) {
    return HALF_LEAVE.map((obj) => {
      if (obj.value === leave_code) {
        return obj.key;
      }
    });
  }
};

export const isDocumentList = (documents = null) => {
  let docString = [];
  if (documents) {
    if (_get(documents, 'aadhar_upload')) {
      docString.push('Aadhaar');
    }
    if (_get(documents, 'cv_upload')) {
      docString.push('CV');
    }
    if (_get(documents, 'bank_detail_upload')) {
      docString.push('Bank');
    }
    if (_get(documents, 'd_l_upload')) {
      docString.push('DL');
    }
    if (_get(documents, 'exp_cert')) {
      docString.push('Exp.');
    }
    if (_get(documents, 'med_cert')) {
      docString.push('Med.');
    }
    if (_get(documents, 'pan_upload')) {
      docString.push('PAN');
    }
  }
  return docString;
};

export const ageRange = (ageObj) => {
  if (ageObj) {
    return `${_get(ageObj, 'max_age')} - ${_get(ageObj, 'min_age')}`;
  }
};

export const addFieldObject = function(type) {
  switch (type) {
    case LABEL_VALUE_FIELD:
      return {
        type: LABEL_VALUE_FIELD,
        label: null,
      };
    case HEADING_FIELD:
      return {
        type: HEADING_FIELD,
      };
    case DATE:
      return {
        type: DATE,
      };
    case DATE_TIME:
      return {
        type: DATE_TIME,
      };
    case TIME:
      return {
        type: TIME,
      };
    default:
      return {};
  }
};

export const removeUnderScore = (str) => {
  return str.replace(/_/g, ' ');
};
