import { Button, Modal,  Form, Input, Select, Space, Spin } from 'antd'
import React, {useState, useEffect} from 'react'
import { useRef } from 'react'
import { ORGANIZATION, PARTNER_CREDITS } from '../../../../../constants/api'
import { SUCCESS_MSG_TYPE } from '../../../../../constants/dataKeys'
import { layout, tailLayout, TRANSACTION_TYPE } from '../../../../../constants/hardData'
import { getAPI, postAPI } from '../../../../../utils/apiRequest'
import { displayMessage } from '../../../../../utils/common'

const CreditModal = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [organizationList, setOrganizationList] = useState([])
    const [checkValue, setCheckValue] = useState(false);
    const [loading, setLoading] = useState(false)

    let formRef = useRef();

    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = () => {
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
      formRef.current.resetFields();
    };

    useEffect(() => {
        getOrganization();
       }, [])
     
     
     
       const onFinish = (values) => {
         setLoading(true)
         let reqData = {...values,
           is_primary: checkValue,
         }
     
         let successFn = function(){
           setLoading(false)
             displayMessage(SUCCESS_MSG_TYPE, 'Partner Credit Added Successfully.');
             setIsModalOpen(false)
             formRef.current.resetFields();
             props.loadData()
         }
         let errorFn = function(error){
             console.log(error)
           setLoading(false)
         }
           postAPI(PARTNER_CREDITS, reqData, successFn, errorFn)
       }
     
       const getOrganization = () => {
         setLoading(true)
     
         let successFn = function(result){
             setLoading(false)
             setOrganizationList(result.results)
         }
     
         let errorFn = function(error){
             setLoading(false)
             console.log(error)
         }
        getAPI(ORGANIZATION, successFn, errorFn)
     }
     
  return (
    <>
    <Button type="primary" onClick={showModal}>
      Manage Credits
    </Button>


    <Modal width={"55%"} footer={false} title="Add Credits" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Spin spinning={loading} >
    <Form
    ref={formRef}
          onFinish={onFinish}
          // validateMessages={validateMessages}
          {...layout}
        >
              <Form.Item
            label={'Organization'}
            name={'organization'}
            rules={[{ required: true }]}
          >
            <Select
                  showSearch
                  allowClear
                  optionFilterProp='children'
                  style={{ width: '100%' }}
                  placeholder={'Organization'}
                >
                  {organizationList?.map((option) => (
                    <Select.Option label={option.label} key={option.id} value={option.id}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
          </Form.Item>
          <Form.Item
            label={'Transaction Type'}
            name={'transaction_type'}
            rules={[{ required: true }]}
          >
           <Select>
            {TRANSACTION_TYPE.map((option)=> (
                <Select.Option value={option.value} key={option.key} placeholder="Transaction"  >
                    {option.name}
                </Select.Option>
            ))}
           </Select>
           
          </Form.Item>
          <Form.Item
            label={'Credits'}
            name={'credits'}
            rules={[{ required: true }]}
          >
            <Input type='number' placeholder='Credits'/>
           
          </Form.Item>
        
        
        
          <Form.Item
            label={'Remarks'}
            name={'remarks'}
            rules={[{ required: true }]}
          >
            <Input placeholder={'Remarks'} />
          </Form.Item>
        
          <Form.Item 
          {...tailLayout}
          >
            <Space>
              <Button
                htmlType='submit'
                type={'primary'}
                className={'theme-color'}
              >
                Save
              </Button>
              <Button onClick={handleCancel} >Cancel</Button>
            </Space>
          </Form.Item>
        </Form>
        </Spin>
    </Modal>
  </>
  )
}

export default CreditModal