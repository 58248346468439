import { Breadcrumb, Button } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import React from 'react';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { BREADCRUMB_PATHS } from '../../constants/breadcrumb';
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import RouteContext from '../../RouteContext';

function BreadCrumb(props) {
  const { location } = props.route;
  if (location) {
    const pathSnippets = location.pathname.split('/').filter((i) => i);
    const extraBreadcrumbItems = pathSnippets.map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
      if (!BREADCRUMB_PATHS[url]) return null;

      return (
        <Breadcrumb.Item key={url}>
          <Link to={url}>{BREADCRUMB_PATHS[url]}</Link>
        </Breadcrumb.Item>
      );
    });
    const breadcrumbItems = [].concat(extraBreadcrumbItems);
    return (
      <div className={'mt-20'}>
        <Breadcrumb>
          <RouteContext.Consumer>
            {(context) => {
              if (context === undefined) {
                return null;
              }
              return (
                <Button
                  type={'primary'}
                  icon={<LeftOutlined />}
                  shape={'circle'}
                  size={'small'}
                  style={{ marginRight: 8 }}
                  onClick={context.history.goBack}
                />
              );
            }}
          </RouteContext.Consumer>
          {breadcrumbItems}
        </Breadcrumb>
      </div>
    );
  }
  return null;
}

const mapStateToProps = (state) => {
  return {
    route: _get(state, 'global.route') || {},
  };
};
const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(BreadCrumb));
