import { Card, Tabs } from 'antd'
import TabPane from 'antd/lib/tabs/TabPane'
import React, {useState} from 'react'
import AppBase from '../../../../base/AppBase'
import AddOrEditCity from './AddOrEditCity'
import ViewCity from './ViewCity'

const City = (props) => {
    const [selectedTab, setSelectedTab] = useState("add-city")
    const [ editData, setEditData ] =useState(null);
    const onSelectTab = (option, extra) => {
        setSelectedTab(option)
        setEditData(extra);
      };
  return (
    <AppBase
    metaDetails={{
      title: 'Add City',
      // subtitle: '',
      // description: HR_SEETINGS_DROPDOWN_SETTING_DESC,
    }}
    >
        <Card>
            <Tabs onChange={onSelectTab} activeKey={selectedTab}>
                <TabPane tab="City" key="add-city">
                    {selectedTab ==="add-city"? (
                        <AddOrEditCity
                        editData={editData}
                        key={'add-city'}
                        onSelectTab={onSelectTab}
                        {...props}/>
                    ):
                    null}
                </TabPane>

                <TabPane  tab='View City' key='view-city'>
              {selectedTab === 'view-city' ? (
                <ViewCity onSelectTab={onSelectTab} {...props} />
              ) : null}
            </TabPane>
            </Tabs>
        </Card>
    </AppBase>
  )
}

export default City