import React, { Component } from 'react';
import { Avatar, Col, Dropdown, Layout, Menu, Row, Space } from 'antd';
import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UnlockOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { _get } from '../../../utils/lodashUtils';
import { globalSiderCollapsed } from '../../../redux/actions/globalReducActions';
import { logoutUserAuthAction } from '../../../redux/actions/authReduxActions';
import { connect } from 'react-redux';
import { USER_LOGOUT } from '../../../constants/api';
import { postAPI } from '../../../utils/apiRequest';
import { Link } from 'react-router-dom';
import { makeFileURL } from '../../../utils/common';
import { userFullName } from '../../../utils/employeeUtils';
import BreadCrumb from '../BreadCrumb';
import { withRouter } from 'react-router';
import { AUTH_TOKEN, REFRESH_TOKEN } from '../../../constants/dataKeys';
import Lockr from 'lockr';

const { Header } = Layout;

class AppHeader extends Component {

  logOutUser = () => {
    let that = this;
    let successFn = function(result) {
      that.props.logoutUserAuthAction();
    };
    let errorFn = function(error) {
    };
    postAPI(USER_LOGOUT, { refresh: Lockr.get(REFRESH_TOKEN), access: Lockr.get(AUTH_TOKEN) }, successFn, errorFn);
  };
  onHandleLink = (event) => {
    let { history } = this.props;
    if (event.key === 'logout') {
      this.logOutUser();
    } else {
      history.push(event.key);
    }
  };

  render() {
    let that = this;
    const { siderCollapsed, loginUser, userImage, noBreadcrumb } =
      that.props;
    let { history } = this.props;
    let userMenu = (
      <Menu onClick={this.onHandleLink}>
        {/* <Menu.Item icon={<UserOutlined />} key={'/profile'}>
          <Link to={'/profile'}>Profile</Link>
        </Menu.Item> */}
        <Menu.Item icon={<UnlockOutlined />} key={'/change-password'}>
          <Link to={`/change-password`}>Change Password</Link>
        </Menu.Item>
        <Menu.Item icon={<LogoutOutlined />} key={'logout'}>
          Log Out
        </Menu.Item>
      </Menu>
    );

    return (
      <Header
        className='site-layout-background'
        style={{
          background: '#fff',
          boxShadow: 'rgba(38, 50, 69, 0.2) 0px 2px 4px 0px',
          padding: '0 10px',
          position: 'fixed',
          zIndex: 1,
          width: '100%',
        }}
      >
        <Row align={'center'}>
          <Col flex={siderCollapsed ? '110px' : '235px'}>
            <div>
                {!siderCollapsed?<span onClick={() => history.push('/')}>
                  <img
                      src={'/law_seva_logo.jpeg'}
                      alt='Law Seva'
                      style={{ maxWidth: '100%', maxHeight: 55, marginRight: 16 }}
                  />

                </span>:
                    <img
                        src={'/law_seva_logo.jpeg'}
                        alt='Law Seva'
                        style={{ maxWidth: '100%', maxHeight: 55, marginRight: 16 }}
                    />

                }




              {!siderCollapsed ? (
                      <MenuFoldOutlined
                          onClick={() => this.props.globalSiderCollapsed(true)}
                          style={{ fontSize: 18 }}
                      />

              ) : (
                <MenuUnfoldOutlined
                  onClick={() => this.props.globalSiderCollapsed(false)}
                  style={{ fontSize: 18 }}
                />
              )}
            </div>
          </Col>
          <Col flex='auto'>
            {noBreadcrumb ? null : (
              <BreadCrumb
                style={{ display: 'block', float: 'left', marginLeft: 100 }}
              />
            )}
          </Col>
          <Col span={8}>
            <div style={{ float: 'right', padding: '0 10px' }}>
              <Space align='baseline'>
                <Dropdown overlay={userMenu}>
                  <Avatar
                    icon={<UserOutlined />}
                    style={{ backgroundColor: '#87d068' }}
                    src={makeFileURL(userImage)}
                  />
                </Dropdown>
                <span>{userFullName(loginUser)}</span>
              </Space>
            </div>
          </Col>
        </Row>
      </Header>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    route: _get(state, 'global.route') || {},
    siderCollapsed: _get(state, 'global.siderCollapsed'),
    loginUser: _get(state, 'auth.user'),
    userImage: _get(state, 'auth.userImage'),
    userRoles: _get(state, 'auth.userRoles', []),
  };
};
const mapDispatchToProps = {
  globalSiderCollapsed,
  logoutUserAuthAction,
};
// export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(AppHeader));
