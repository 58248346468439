import { Card, Divider, Popconfirm, Spin, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { GST_SETTING } from '../../../../constants/api'
import { getAPI, postAPI } from '../../../../utils/apiRequest'
import InfiniteFeedLoaderButton from '../../../common/InfiniteFeedLoaderButton'
import {EditOutlined,DeleteOutlined} from '@ant-design/icons'

const ViewGSTSettings = (props) => {
    const [loading, setLoading] = useState(false)
    const [gstList, setGstList] = useState([])
    const [nextPage, setNextPage] = useState(1)

    useEffect(() => {
        getGST();
    }, [])

    const getGST = (page=1) => {
        setLoading(true)
        let apiParams ={
          page,
        }

        let successFn = function(result){
            setLoading(false)
            setGstList([...(result.current === 1? [] : gstList), ...result.results])
            setNextPage(result.next)
        }

        let errorFn = function(error){
            setLoading(false)
            console.log(error)
        }
       getAPI(GST_SETTING, successFn, errorFn, apiParams)
    }

    const deleteObject = (record) => {
      let reqData = {
        ...record,
        is_active: false,
      };
      let successFn = function (result) {
        getGST();
      };
      let errorFn = function (error) {};
  
     postAPI(GST_SETTING, reqData, successFn, errorFn)
    };

    const editObject = (record) => {
      props.onSelectTab("add-gst", record);
    };


    const columns = [
        {
            title: 'S. No.',
            key: 's_no',
            render: (item, record, index) =>
            gstList.indexOf(record) + 1,
        },
        {
            title: 'Name',
            key: 'name',
            render: (item, record) => (
                <span>
                  {record.name
                    ? `${record.name}`
                    : '--'}
                </span>
              ),
        },
        {
            title: 'Value',
            key: 'value',
            render: (item, record) => (
                <span>
                  {record.value
                    ? `${record.value}%`
                    : '--'}
                </span>
              ),
        },
        {
            title: 'Invoice Name',
            key: 'invoice_name',
            render: (item, record) => (
                <span>
                  {record.invoice_name
                    ? `${record.invoice_name}`
                    : '--'}
                </span>
              ),
        },
        {
          title: "Action",
          key: "action",
          render: (text, record) => (
            <span>
              <a onClick={() => editObject(record)}> < EditOutlined style={{ color: 'blue' }}/></a>
              <Divider type="vertical" />
              <Popconfirm
                title="Are you sure to delete this?"
                onConfirm={() => deleteObject(record)}
                okText="Yes"
                cancelText="No"
              >
                <a><DeleteOutlined style={{ color: 'red' }}/></a>
              </Popconfirm>
            </span>
          ),
        },
    ]
    
  return (
    <Card>
    <Spin spinning={loading}>
          <Table
            dataSource={gstList}
            columns={columns}
            pagination={false}
          />
        </Spin>
        <InfiniteFeedLoaderButton
          loaderFunction={() => getGST(nextPage)}
          loading={loading}
          hidden={!nextPage}
        />
      </Card>
  )
}

export default ViewGSTSettings