import { Button, Card, Checkbox, Col, Divider, message, Popconfirm, Rate, Space, Spin, Table, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { ADD_SUGGESTION, ADD_Suggestion, FEEDBACK } from '../../../../constants/api'
import { getAPI, postAPI } from '../../../../utils/apiRequest'
import { replaceVariable } from '../../../../utils/projectUtils'
import InfiniteFeedLoaderButton from "../../../common/InfiniteFeedLoaderButton"
import { Link } from 'react-router-dom'
import { makeFileURL } from '../../../../utils/common'
import moment from 'moment'
import AppBase from '../../../base/AppBase'
import {StarFilled} from '@ant-design/icons'

const ViewFeedBack = (props) => {
    const [loading, setLoading] = useState(false)
    const [feedBackData, setFeedBackData] = useState([])
    const [nextPage, setNextPage] = useState(1)

    useEffect(() => {
        getFeedBack();
    }, [])

    const getFeedBack = (page=1) => {
        setLoading(true)

        let apiParams = {
            page,
        }

        

        let successFn = function(result){
            setLoading(false)
            setFeedBackData([...(result.current === 1? [] : feedBackData), ...result.results])
            setNextPage(result.next)
        }

        let errorFn = function(error){
            setLoading(false)
            console.log(error)
        }
       getAPI(FEEDBACK, successFn, errorFn, apiParams)
    }

    // const deleteObject = (record) => {
    //   let reqData = {
    //     ...record,
    //     is_active: false,
    //   };
    //   let successFn = function (result) {
    //     getFeedBack();
    //   };
    //   let errorFn = function (error) {};
  
    //  postAPI(ADD_SUGGESTION, reqData, successFn, errorFn)
    // };

    const openimg = (link) => {
      window.open(makeFileURL(link));
    }

    const columns = [
        {
            title: 'S. No.',
            key: 's_no',
            render: (item, record, index) =>
            feedBackData.indexOf(record) + 1,
        },
        {
            title: 'Ratings',
            key: 'rating',
            render: (item, record) => (
              <span> <Space style={{left:'1cm'}}>{record?.rating ? <Rate disabled allowHalf  defaultValue={record?.rating}/> : ''}</Space><Space style={{marginLeft:'20px'}}>{record?.rating + " " ?  record?.rating  : "--"}</Space></span>

              ),
        },
        {
          title: 'Date',
          key: 'created_at',
          render: (item, record) => (
              <span>{record?.created_at ?  moment(record?.created_at).format('DD/MM/YYYY'):'--'}</span>
            ),
      },
      {
        title: 'Remark',
        key: 'remark',
        render: (item, record) => (
            <span>{record?.remark ?  record?.remark:'--'}</span>
          ),
    },
       
    ]
    
  return (
    <AppBase    metaDetails={{
        title: 'FeedBack',
      }}>
   
 
    <Card >
    <Spin spinning={loading}>
          <Table
            dataSource={feedBackData}
            columns={columns}
            pagination={false}
          />
        </Spin>
        <InfiniteFeedLoaderButton
          loaderFunction={() => getFeedBack(nextPage)}
          loading={loading}
          hidden={!nextPage}
        />
      </Card>
    </AppBase>
  )
}

export default ViewFeedBack