import { Card, Divider, Popconfirm, Select, Space, Spin, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  ORGANIZATION,
  PARTNER_KYC,
  VIEW_UPLOADED_FILE,
} from "../../../../../constants/api";
import { getAPI, putAPI } from "../../../../../utils/apiRequest";
import InfiniteFeedLoaderButton from "../../../../common/InfiniteFeedLoaderButton";
import {
  handleErrorResponse,
  makeFileURL,
  startLoadingMessage,
  stopLoadingMessage,
} from "../../../../../utils/common";
import {
  ERROR_MSG_TYPE,
  SUCCESS_MSG_TYPE,
} from "../../../../../constants/dataKeys";
import {EditOutlined,DeleteOutlined, EyeOutlined} from '@ant-design/icons'
// import { ORGANIZATION, PARTNER, POLICIES, PROFESSION } from '../../../../../constants/api'
const ViewPartnerKYC = (props) => {
  const [loading, setLoading] = useState(false);
  const [partnerList, setPartnerList] = useState([]);
  const [nextPage, setNextPage] = useState(1);
  const [filterKeys, setFilterKeys] = useState({});
  const [organizationList, setOrganizationList] = useState([]);
  const [check, setCheck] = useState(true);
  const [checkMRN, setMRN] = useState(true);
  const [checkREG, setREG] = useState(true);
  const [checkNAME, setNAME] = useState(true);
  // const [check, setCheck] = useState(true);
  // const [check, setCheck] = useState(true);

  useEffect(() => {
    loadKYC();
    getOrganization();
  }, [filterKeys]);

  const loadKYC = (page = 1) => {
    setLoading(true);
    let apiParams = {
      page,
      ...filterKeys,
      profession: props.profession,
    };

    let successFn = (result) => {
      setLoading(false);
      setPartnerList([
        ...(result.current === 1 ? [] : partnerList),
        ...result.results,
      ]);
      setNextPage(result.next);
    };
    let errorFn = (error) => {
      setLoading(false);
      console.log(error);
    };
    getAPI(PARTNER_KYC, successFn, errorFn, apiParams);
  };

  const getOrganization = () => {
    setLoading(true);

    let apiParams = {
      pagination: false,
      ...filterKeys,
    };

    let successFn = function (result) {
      setLoading(false);
      setOrganizationList(result);
    };

    let errorFn = function (error) {
      setLoading(false);
      console.log(error);
    };
    getAPI(ORGANIZATION, successFn, errorFn, apiParams);
  };

  const filterData = (type, value) => {
    setFilterKeys((filterKeys) => {
      return { ...filterKeys, [type]: value ? value : undefined };
    });
  };

  const deleteObject = (record) => {
    let reqData = {
      ...record,
      is_active: false,
    };
    let successFn = function (result) {
      loadKYC();
    };
    let errorFn = function (error) {};

    putAPI(PARTNER_KYC, reqData, successFn, errorFn);
  };

  const editObject = (record) => {
    props.onSelectTab("add-partner-kyc", record);
  };

  const handleFileDownload = async (path, fileName) => {
    // const viewUploadedFile = async () => {
    let apiParams = {
      path: path,
    };
    let msg = startLoadingMessage("Generating File ...");
    let successFn = function (data) {
      if (data.url) {

        window.open(makeFileURL(data.url));
      }
      stopLoadingMessage(msg, SUCCESS_MSG_TYPE, "File Generated Successfully!");
    };
    let errorFn = function (error) {
      handleErrorResponse(error);
      stopLoadingMessage(msg, ERROR_MSG_TYPE, "File Generation Failed!");
    };
    await getAPI(VIEW_UPLOADED_FILE, successFn, errorFn, apiParams);
    // }
  };

  const columns = [
    {
      title: "S. No.",
      key: "s_no",
      render: (item, record, index) => partnerList.indexOf(record) + 1,
    },
    {
      title: "Organisation",
      key: "organization_data",
      render: (item, record) => (
        <span>
          {record.organization_data ? `${record.organization_data.name}` : "--"}
        </span>
      ),
    },
    {
      title: "Profession",
      key: "profession_data",
      render: (item, record) => (
        <span>
          {record.profession_data ? `${record.profession_data.value}` : "--"}
        </span>
      ),
    },
    {
      title: "Partner",
      key: "partner",
      render: (item, record) => (
        <span>
          {record?.partner ? `${record?.partner_data?.first_name}` : "--"}
        </span>
      ),
    },
    ...(checkNAME
      ? [
          {
            title: "Name",
            key: "name",
            render: (item, record) => (
              <span>{record.name ? `${record.name}` : setNAME(false)}</span>
            ),
          },
        ]
      : []),
    {
      title: "Email",
      key: "email",
      render: (item, record) => (
        <span>{record.email ? `${record.email}` : "--"}</span>
      ),
    },
    {
      title: "Date  of Birth",
      key: "dob",
      render: (item, record) => (
        <span>
          {record.dob ? `${moment(record.dob).format("DD/MM/YYYY")}` : "--"}
        </span>
      ),
    },
    {
      title: "Mobile No.",
      key: "mobile",
      render: (item, record) => (
        <span>{record.mobile ? `${record.mobile}` : "--"}</span>
      ),
    },
    ...(checkMRN
      ? [
          {
            title: "MRN No.",
            key: "mrn_number",
            render: (item, record) => (
              <span>
                {record.mrn_number ? `${record.mrn_number}` : setMRN(false)}
              </span>
            ),
          },
        ]
      : []),
    ...(check
      ? [
          {
            title: "Enrollment No.",
            key: "enrollment_number",
            render: (item, record) => (
              <span>
                {record?.enrollment_number
                  ? `${record.enrollment_number}`
                  : setCheck(false)}
              </span>
            ),
          },
        ]
      : []),
    ...(checkREG
      ? [
          {
            title: "Registration No.",
            key: "registration_number",
            render: (item, record) => (
              <span>
                {record.registration_number
                  ? `${record.registration_number}`
                  : setREG(false)}
              </span>
            ),
          },
        ]
      : []),
    {
      title: "Firm Name",
      key: "firm_name",
      render: (item, record) => (
        <span>{record.firm_name ? `${record.firm_name}` : "--"}</span>
      ),
    },

    {
      title: "Photograph",
      key: "file",
      render: (item, record) => (
        <span>
          {record.photo ? (
            <a onClick={() => handleFileDownload(record.photo)}><EyeOutlined /></a>
          ) : (
            "--"
          )}
        </span>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <a onClick={() => editObject(record)}> < EditOutlined style={{ color: 'blue' }}/></a>
          <Divider type="vertical" />
          <Popconfirm
            title="Are you sure to delete this?"
            onConfirm={() => deleteObject(record)}
            okText="Yes"
            cancelText="No"
          >
            <a><DeleteOutlined style={{ color: 'red' }}/></a>
          </Popconfirm>
        </span>
      ),
    },
  ];

  const accounantColumn = [
    {
      title: "S. No.",
      key: "s_no",
      render: (item, record, index) => partnerList.indexOf(record) + 1,
    },
    {
      title: "Organisation",
      key: "organization_data",
      render: (item, record) => (
        <span>
          {record.organization_data ? `${record.organization_data.name}` : "--"}
        </span>
      ),
    },
    {
      title: "Profession",
      key: "profession_data",
      render: (item, record) => (
        <span>
          {record.profession_data ? `${record.profession_data.value}` : "--"}
        </span>
      ),
    },
    {
      title: "Partner",
      key: "partner",
      render: (item, record) => (
        <span>
          {record?.partner ? `${record?.partner_data?.first_name}` : "--"}
        </span>
      ),
    },
    {
      title: "Name",
      key: "name",
      render: (item, record) => (
        <span>
          {record.name
            ? `${record.name}`
            : "--"}
        </span>
      ),
    },
    {
      title: "Email",
      key: "email",
      render: (item, record) => (
        <span>{record.email ? `${record.email}` : "--"}</span>
      ),
    },
    {
      title: "Date  of Birth",
      key: "dob",
      render: (item, record) => (
        <span>
          {record.dob ? `${moment(record.dob).format("DD/MM/YYYY")}` : "--"}
        </span>
      ),
    },
    {
      title: "Mobile No.",
      key: "mobile",
      render: (item, record) => (
        <span>{record.mobile ? `${record.mobile}` : "--"}</span>
      ),
    },
    {
      title: "Firm Name",
      key: "firm_name",
      render: (item, record) => (
        <span>{record.firm_name ? `${record.firm_name}` : "--"}</span>
      ),
    },

    {
      title: "Photograph",
      key: "file",
      render: (item, record) => (
        <span>
          {record.photo ? (
            <a onClick={() => handleFileDownload(record.photo)}><EyeOutlined /></a>
          ) : (
            "--"
          )}
        </span>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <a onClick={() => editObject(record)}> < EditOutlined style={{ color: 'blue' }}/></a>
          <Divider type="vertical" />
          <Popconfirm
            title="Are you sure to delete this?"
            onConfirm={() => deleteObject(record)}
            okText="Yes"
            cancelText="No"
          >
            <a><DeleteOutlined style={{ color: 'red' }}/></a>
          </Popconfirm>
        </span>
      ),
    },
  ];
  const advocateColumn = [
    {
      title: "S. No.",
      key: "s_no",
      render: (item, record, index) => partnerList.indexOf(record) + 1,
    },
    {
      title: "Organisation",
      key: "organization_data",
      render: (item, record) => (
        <span>
          {record.organization_data ? `${record.organization_data.name}` : "--"}
        </span>
      ),
    },
    {
      title: "Profession",
      key: "profession_data",
      render: (item, record) => (
        <span>
          {record.profession_data ? `${record.profession_data.value}` : "--"}
        </span>
      ),
    },
    {
      title: "Partner",
      key: "partner",
      render: (item, record) => (
        <span>
          {record?.partner ? `${record?.partner_data?.first_name}` : "--"}
        </span>
      ),
    },
    {
      title: "Email",
      key: "email",
      render: (item, record) => (
        <span>{record.email ? `${record.email}` : "--"}</span>
      ),
    },
    {
      title: "Date  of Birth",
      key: "dob",
      render: (item, record) => (
        <span>
          {record.dob ? `${moment(record.dob).format("DD/MM/YYYY")}` : "--"}
        </span>
      ),
    },
    {
      title: "Mobile No.",
      key: "mobile",
      render: (item, record) => (
        <span>{record.mobile ? `${record.mobile}` : "--"}</span>
      ),
    },
    {
      title: "Firm Name",
      key: "firm_name",
      render: (item, record) => (
        <span>{record.firm_name ? `${record.firm_name}` : "--"}</span>
      ),
    },
    {
      title: "Enrollment Number",
      key: "enrollment_number",
      render: (item, record) => (
        <span>
          {record.enrollment_number ? `${record.enrollment_number}` : "--"}
        </span>
      ),
    },
    {
      title: "Registration Number",
      key: "registration_number",
      render: (item, record) => (
        <span>
          {record.registration_number ? `${record.registration_number}` : "--"}
        </span>
      ),
    },

    {
      title: "Photograph",
      key: "file",
      render: (item, record) => (
        <span>
          {record.photo ? (
            <a onClick={() => handleFileDownload(record.photo)}><EyeOutlined /></a>
          ) : (
            "--"
          )}
        </span>
      ),
    },
    {
      title: "LLB Degree",
      key: "file",
      render: (item, record) => (
        <span>
          {record.llb_degree_upload ? (
            <a onClick={() => handleFileDownload(record.llb_degree_upload)}>
              <EyeOutlined />
            </a>
          ) : (
            "--"
          )}
        </span>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <a onClick={() => editObject(record)}> < EditOutlined style={{ color: 'blue' }}/></a>
          <Divider type="vertical" />
          <Popconfirm
            title="Are you sure to delete this?"
            onConfirm={() => deleteObject(record)}
            okText="Yes"
            cancelText="No"
          >
            <a><DeleteOutlined style={{ color: 'red' }}/></a>
          </Popconfirm>
        </span>
      ),
    },
  ];
  const caColumn = [
    {
      title: "S. No.",
      key: "s_no",
      render: (item, record, index) => partnerList.indexOf(record) + 1,
    },
    {
      title: "Organisation",
      key: "organization_data",
      render: (item, record) => (
        <span>
          {record.organization_data ? `${record.organization_data.name}` : "--"}
        </span>
      ),
    },
    {
      title: "Profession",
      key: "profession_data",
      render: (item, record) => (
        <span>
          {record.profession_data ? `${record.profession_data.value}` : "--"}
        </span>
      ),
    },
    {
      title: "Partner",
      key: "partner",
      render: (item, record) => (
        <span>
          {record?.partner ? `${record?.partner_data?.first_name}` : "--"}
        </span>
      ),
    },
    {
      title: "Email",
      key: "email",
      render: (item, record) => (
        <span>{record.email ? `${record.email}` : "--"}</span>
      ),
    },
    {
      title: "Date  of Birth",
      key: "dob",
      render: (item, record) => (
        <span>
          {record.dob ? `${moment(record.dob).format("DD/MM/YYYY")}` : "--"}
        </span>
      ),
    },
    {
      title: "Mobile No.",
      key: "mobile",
      render: (item, record) => (
        <span>{record.mobile ? `${record.mobile}` : "--"}</span>
      ),
    },
    {
      title: "Firm Name",
      key: "firm_name",
      render: (item, record) => (
        <span>{record.firm_name ? `${record.firm_name}` : "--"}</span>
      ),
    },
    {
      title: "Enrollment Date",
      key: "enrollment_date",
      render: (item, record) => (
        <span>
          {record.enrollment_date
            ? `${moment(record.enrollment_date).format("DD/MM/YYYY")}`
            : "--"}
        </span>
      ),
    },
    {
      title: "MRN Number",
      key: "mrn_number",
      render: (item, record) => (
        <span>{record.mrn_number ? `${record.mrn_number}` : "--"}</span>
      ),
    },

    {
      title: "Photograph",
      key: "file",
      render: (item, record) => (
        <span>
          {record.photo ? (
            <a onClick={() => handleFileDownload(record.photo)}><EyeOutlined /></a>
          ) : (
            "--"
          )}
        </span>
      ),
    },
    {
      title: "ACA/FCA Certificate",
      key: "file",
      render: (item, record) => (
        <span>
          {record.aca_fca_upload ? (
            <a onClick={() => handleFileDownload(record.aca_fca_upload)}>
              <EyeOutlined />
            </a>
          ) : (
            "--"
          )}
        </span>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <a onClick={() => editObject(record)}> < EditOutlined style={{ color: 'blue' }}/></a>
          <Divider type="vertical" />
          <Popconfirm
            title="Are you sure to delete this?"
            onConfirm={() => deleteObject(record)}
            okText="Yes"
            cancelText="No"
          >
            <a><DeleteOutlined style={{ color: 'red' }}/></a>
          </Popconfirm>
        </span>
      ),
    },
  ];

  const cmaColumn = [
    {
      title: "S. No.",
      key: "s_no",
      render: (item, record, index) => partnerList.indexOf(record) + 1,
    },
    {
      title: "Organisation",
      key: "organization_data",
      render: (item, record) => (
        <span>
          {record.organization_data ? `${record.organization_data.name}` : "--"}
        </span>
      ),
    },
    {
      title: "Profession",
      key: "profession_data",
      render: (item, record) => (
        <span>
          {record.profession_data ? `${record.profession_data.value}` : "--"}
        </span>
      ),
    },
    {
      title: "Partner",
      key: "partner",
      render: (item, record) => (
        <span>
          {record?.partner ? `${record?.partner_data?.first_name}` : "--"}
        </span>
      ),
    },
    {
      title: "Email",
      key: "email",
      render: (item, record) => (
        <span>{record.email ? `${record.email}` : "--"}</span>
      ),
    },
    {
      title: "Date  of Birth",
      key: "dob",
      render: (item, record) => (
        <span>
          {record.dob ? `${moment(record.dob).format("DD/MM/YYYY")}` : "--"}
        </span>
      ),
    },
    {
      title: "Mobile No.",
      key: "mobile",
      render: (item, record) => (
        <span>{record.mobile ? `${record.mobile}` : "--"}</span>
      ),
    },
    {
      title: "Firm Name",
      key: "firm_name",
      render: (item, record) => (
        <span>{record.firm_name ? `${record.firm_name}` : "--"}</span>
      ),
    },
    {
      title: "Enrollment Date",
      key: "enrollment_date",
      render: (item, record) => (
        <span>
          {record.enrollment_date
            ? `${moment(record.enrollment_date).format("DD/MM/YYYY")}`
            : "--"}
        </span>
      ),
    },
    {
      title: "MRN Number",
      key: "mrn_number",
      render: (item, record) => (
        <span>{record.mrn_number ? `${record.mrn_number}` : "--"}</span>
      ),
    },

    {
      title: "Photograph",
      key: "file",
      render: (item, record) => (
        <span>
          {record.photo ? (
            <a onClick={() => handleFileDownload(record.photo)}><EyeOutlined /></a>
          ) : (
            "--"
          )}
        </span>
      ),
    },
    {
      title: "Participating Certificate",
      key: "file",
      render: (item, record) => (
        <span>
          {record.practicing_upload ? (
            <a onClick={() => handleFileDownload(record.practicing_upload)}>
              <EyeOutlined />
            </a>
          ) : (
            "--"
          )}
        </span>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <a onClick={() => editObject(record)}> < EditOutlined style={{ color: 'blue' }}/></a>
          <Divider type="vertical" />
          <Popconfirm
            title="Are you sure to delete this?"
            onConfirm={() => deleteObject(record)}
            okText="Yes"
            cancelText="No"
          >
            <a><DeleteOutlined style={{ color: 'red' }}/></a>
          </Popconfirm>
        </span>
      ),
    },
  ];
  const csColumn = [
    {
      title: "S. No.",
      key: "s_no",
      render: (item, record, index) => partnerList.indexOf(record) + 1,
    },
    {
      title: "Organisation",
      key: "organization_data",
      render: (item, record) => (
        <span>
          {record.organization_data ? `${record.organization_data.name}` : "--"}
        </span>
      ),
    },
    {
      title: "Profession",
      key: "profession_data",
      render: (item, record) => (
        <span>
          {record.profession_data ? `${record.profession_data.value}` : "--"}
        </span>
      ),
    },
    {
      title: "Partner",
      key: "partner",
      render: (item, record) => (
        <span>
          {record?.partner ? `${record?.partner_data?.first_name}` : "--"}
        </span>
      ),
    },
    {
      title: "Email",
      key: "email",
      render: (item, record) => (
        <span>{record.email ? `${record.email}` : "--"}</span>
      ),
    },
    {
      title: "Date  of Birth",
      key: "dob",
      render: (item, record) => (
        <span>
          {record.dob ? `${moment(record.dob).format("DD/MM/YYYY")}` : "--"}
        </span>
      ),
    },
    {
      title: "Mobile No.",
      key: "mobile",
      render: (item, record) => (
        <span>{record.mobile ? `${record.mobile}` : "--"}</span>
      ),
    },
    {
      title: "Firm Name",
      key: "firm_name",
      render: (item, record) => (
        <span>{record.firm_name ? `${record.firm_name}` : "--"}</span>
      ),
    },
    {
      title: "Enrollment Date",
      key: "enrollment_date",
      render: (item, record) => (
        <span>
          {record.enrollment_date
            ? `${moment(record.enrollment_date).format("DD/MM/YYYY")}`
            : "--"}
        </span>
      ),
    },
    {
      title: "MRN Number",
      key: "mrn_number",
      render: (item, record) => (
        <span>{record.mrn_number ? `${record.mrn_number}` : "--"}</span>
      ),
    },

    {
      title: "Photograph",
      key: "file",
      render: (item, record) => (
        <span>
          {record.photo ? (
            <a onClick={() => handleFileDownload(record.photo)}><EyeOutlined /></a>
          ) : (
            "--"
          )}
        </span>
      ),
    },
    {
      title: "Participating Certificate",
      key: "file",
      render: (item, record) => (
        <span>
          {record.practicing_upload ? (
            <a onClick={() => handleFileDownload(record.practicing_upload)}>
              <EyeOutlined />
            </a>
          ) : (
            "--"
          )}
        </span>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <a onClick={() => editObject(record)}> < EditOutlined style={{ color: 'blue' }}/></a>
          <Divider type="vertical" />
          <Popconfirm
            title="Are you sure to delete this?"
            onConfirm={() => deleteObject(record)}
            okText="Yes"
            cancelText="No"
          >
            <a><DeleteOutlined style={{ color: 'red' }}/></a>
          </Popconfirm>
        </span>
      ),
    },
  ];
  const otherColumn = [
    {
      title: "S. No.",
      key: "s_no",
      render: (item, record, index) => partnerList.indexOf(record) + 1,
    },
    {
      title: "Organisation",
      key: "organization_data",
      render: (item, record) => (
        <span>
          {record.organization_data ? `${record.organization_data.name}` : "--"}
        </span>
      ),
    },
    {
      title: "Profession",
      key: "profession_data",
      render: (item, record) => (
        <span>
          {record.profession_data ? `${record.profession_data.value}` : "--"}
        </span>
      ),
    },
    {
      title: "Partner",
      key: "partner",
      render: (item, record) => (
        <span>
          {record?.partner ? `${record?.partner_data?.first_name}` : "--"}
        </span>
      ),
    },
    {
      title: "Name",
      key: "name",
      render: (item, record) => (
        <span>
          {record.name
            ? `${record.name}`
            : "--"}
        </span>
      ),
    },
    {
      title: "Email",
      key: "email",
      render: (item, record) => (
        <span>{record.email ? `${record.email}` : "--"}</span>
      ),
    },
    {
      title: "Date  of Birth",
      key: "dob",
      render: (item, record) => (
        <span>
          {record.dob ? `${moment(record.dob).format("DD/MM/YYYY")}` : "--"}
        </span>
      ),
    },
    {
      title: "Mobile No.",
      key: "mobile",
      render: (item, record) => (
        <span>{record.mobile ? `${record.mobile}` : "--"}</span>
      ),
    },
    {
      title: "Firm Name",
      key: "firm_name",
      render: (item, record) => (
        <span>{record.firm_name ? `${record.firm_name}` : "--"}</span>
      ),
    },
   
    {
      title: "Professional",
      key: "professional",
      render: (item, record) => (
        <span>{record.professional ? `${record.professional}` : "--"}</span>
      ),
    },

    {
      title: "Photograph",
      key: "file",
      render: (item, record) => (
        <span>
          {record.photo ? (
            <a onClick={() => handleFileDownload(record.photo)}><EyeOutlined /></a>
          ) : (
            "--"
          )}
        </span>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <a onClick={() => editObject(record)}> < EditOutlined style={{ color: 'blue' }}/></a>
          <Divider type="vertical" />
          <Popconfirm
            title="Are you sure to delete this?"
            onConfirm={() => deleteObject(record)}
            okText="Yes"
            cancelText="No"
          >
            <a><DeleteOutlined style={{ color: 'red' }}/></a>
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <Card
      title={
        <Space>
          Filters :
          <Select
            placeholder={"Organization"}
            allowClear
            style={{ width: "150px" }}
            onChange={(e) => filterData("organization", e)}
            showSearch
            optionFilterProp="children"
          >
            {organizationList.map((option) => (
              <Select.Option label={option.name} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </Space>
      }
      bodyStyle={{ padding: 4, border: 0 }}
    >
      <Spin spinning={loading}>
        <Table
          dataSource={partnerList}
          columns={
            props.profession === 5
              ? accounantColumn
              : props.profession === 2
              ? advocateColumn
              : props.profession === 1
              ? caColumn
              : props.profession === 4
              ? cmaColumn
              : props.profession === 3
              ? csColumn
              : props.profession === 6
              ? otherColumn
              : columns
          }
          pagination={false}
        />
      </Spin>
      <InfiniteFeedLoaderButton
        loaderFunction={() => loadKYC(nextPage)}
        loading={loading}
        hidden={!nextPage}
      />
    </Card>
  );
};

export default ViewPartnerKYC;
