import { Card, Divider, Popconfirm, Spin, Table, Space, Select, Form } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { CITY, COUNTRY, STATE } from '../../../../../constants/api'
import { getAPI, postAPI } from '../../../../../utils/apiRequest'
import InfiniteFeedLoaderButton from '../../../../common/InfiniteFeedLoaderButton'
import {EditOutlined,DeleteOutlined} from '@ant-design/icons'

const ViewCity = (props) => {
    const [loading, setLoading] = useState(false)
    const [cityList, setCityList] = useState([])
    const [nextPage, setNextPage] = useState(1)
    const [filterKeys, setFilterKeys] = useState({})
    const [countryFilterList, setCountryFilterList] = useState([]);
    const [stateFilterList, setStateFilterList] = useState([]);
    const [cityFilterList, setCityFilterList] = useState([]);

    const formRef = useRef()

    useEffect(() => {
        getCity();
    }, [filterKeys])

    useEffect(() => {
      getCountryFilter();
    }, [])

    const getFilterState = (value) => {
      setLoading(true)
      
      let apiParams = {
        country : value,
        pagination : false,
      }
      let successFn = function(result){
         setStateFilterList(result)
      }

      let errorFn = function(error){
          setLoading(false)
          console.log(error)
      }
     getAPI(STATE, successFn, errorFn, apiParams)
  }

  const getCountryFilter = () => {
    setLoading(true);
    let apiParams = {
      pagination : false,
    };

    let successFn = function (result) {
      setCountryFilterList(result)
    };

    let errorFn = function (error) {
      setLoading(false);
      console.log(error);
    };
    getAPI(COUNTRY, successFn, errorFn, apiParams);
  };

  const getCityFilter = (value) => {
    setLoading(true)
    let apiParams = {
      pagination : false,
      state : value,
    }
    let successFn = function(result){
       setCityFilterList(result)
    }

    let errorFn = function(error){
        setLoading(false)
        console.log(error)
    }
   getAPI(CITY, successFn, errorFn, apiParams)
}



    const getCity = (page=1) => {
        setLoading(true)
        let apiParams = {
          page,
          ...filterKeys
        }
        let successFn = function(result){
            setLoading(false)
            setCityList([...(result.current === 1? [] : cityList), ...result.results])
            setNextPage(result.next)
        }

        let errorFn = function(error){
            setLoading(false)
            console.log(error)
        }
       getAPI(CITY, successFn, errorFn, apiParams)
    }

    const deleteObject = (record) => {
      let reqData = {
        ...record,
        is_active: false,
      };
      let successFn = function (result) {
        getCity();
      };
      let errorFn = function (error) {};
  
     postAPI(CITY, reqData, successFn, errorFn)
    };

    const editObject = (record) => {
      props.onSelectTab("add-city", record);
    };

    
    

    const columns = [
        {
            title: 'S. No.',
            key: 's_no',
            render: (item, record, index) =>
            cityList.indexOf(record) + 1,
        },
        {
            title: 'City',
            key: 'name',
            render: (item, record) => (
                <span>
                  {record.name
                    ? `${record.name}`
                    : '--'}
                </span>
              ),
        },
        {
            title: 'State',
            key: 'state',
            render: (item, record) => (
                <span>
                  {record.state_data
                    ? `${record.state_data.name}`
                    : '--'}
                </span>
              ),
        },
        {
          title: "Action",
          key: "action",
          render: (text, record) => (
            <span>
              <a onClick={() => editObject(record)}> < EditOutlined style={{ color: 'blue' }}/></a>
              <Divider type="vertical" />
              <Popconfirm
                title="Are you sure to delete this?"
                onConfirm={() => deleteObject(record)}
                okText="Yes"
                cancelText="No"
              >
                <a><DeleteOutlined style={{ color: 'red' }}/></a>
              </Popconfirm>
            </span>
          ),
        },
    ]

    const filterAddress=(type, value)=>{
      setFilterKeys(filterKeys => {
          return {...filterKeys, [type]: value ? value : undefined}
      })
      switch (type){
          case 'country':
              if (value){
                  getFilterState(value);
              }else {
                  setStateFilterList([])
                  setCityFilterList([])
              }
              formRef.current.setFieldsValue({
                  state: "",
                  city: ""
              })
              break;
          case 'state':
              if (value){
                  getCityFilter(value);
              }else {
                  setCityFilterList([])
              }
              formRef.current.setFieldsValue({
                  city: ""
              })
              break;
      }
  }
    
  return (
    <Card
    title={
      <Space>
          Filters :
          <Form layout={'inline'} ref={formRef}>
              <Form.Item name={'country'}>
                  <Select
                      placeholder={'Country'}
                      allowClear
                      style={{width: "150px"}}
                      onChange={(e) => filterAddress('country', e)}
                      showSearch
                      optionFilterProp="children"
                  >
                      {countryFilterList.map((option) => (
                          <Select.Option label={option.name} value={option.id}>
                              {option.name}
                          </Select.Option>
                      ))}
                  </Select>
              </Form.Item>
              <Form.Item name={'state'}>
                  <Select
                      placeholder={'State'}
                      allowClear
                      style={{width: "150px"}}
                      onChange={(e) => filterAddress('state', e)}
                      showSearch
                      optionFilterProp="children"
                  >
                      {stateFilterList.map((option) => {
                          return (
                              <Select.Option label={option.name} value={option.id}>
                                  {option.name}
                              </Select.Option>
                          )
                      })}
                  </Select>
              </Form.Item>
              <Form.Item name={'city'}>
                            <Select
                                placeholder={'City'}
                                allowClear
                                style={{width: "150px"}}
                                onChange={(e) => filterAddress('id', e)}
                                showSearch
                                optionFilterProp="children"
                            >
                                {cityFilterList.map((option) => (
                                    <Select.Option label={option.name} value={option.id}>
                                        {option.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
              </Form>
              </Space> }>
    <Spin spinning={loading}>
          <Table
            dataSource={cityList}
            columns={columns}
            pagination={false}
          />
        </Spin>
        <InfiniteFeedLoaderButton
          loaderFunction={() => getCity(nextPage)}
          loading={loading}
          hidden={!nextPage}
        />
      </Card>
  )
}

export default ViewCity