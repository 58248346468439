import {
  GLOBAL_DYNAMIC_SETTINGS_FORM_VISIBLE,
  GLOBAL_SIDER_COLLAPSED,
  GLOBAL_SIDER_MENU,
  GLOBAL_UPDATE_ROUTE,
  TODAYS_BIRTHDAY_LIST,
} from '../reduxConstant';
import { _get } from '../../utils/lodashUtils';
import moment from 'moment';

export default (state = {}, action) => {
  switch (action.type) {
    case GLOBAL_UPDATE_ROUTE:
      return {
        ...state,
        route: action.payload,
      };
    case GLOBAL_SIDER_COLLAPSED:
      return {
        ...state,
        siderCollapsed: action.payload,
      };
    case GLOBAL_SIDER_MENU:
      return {
        ...state,
        currentSiderMenu: _get(action, 'payload'),
      };
    case GLOBAL_DYNAMIC_SETTINGS_FORM_VISIBLE:
      return {
        ...state,
        dynamicSettingsFormDrawerVisible: action.payload,
      };
    case TODAYS_BIRTHDAY_LIST:
      return {
        ...state,
        todaysBirthdayList: {
          date: moment().format('YYYY-MM-DD'),
          list: action.payload || [],
        },
      };
    default:
      return state;
  }
};
