import { Button, Form, Input, Select, Space, Spin ,Checkbox} from 'antd'
import React, { useState, useEffect } from 'react'
import { COUNTRY, STATE } from '../../../../../constants/api'
import { SUCCESS_MSG_TYPE } from '../../../../../constants/dataKeys'
import { layout, tailLayout } from '../../../../../constants/hardData'
import { getAPI, postAPI } from '../../../../../utils/apiRequest'
import { displayMessage } from '../../../../../utils/common'

const AddOrEditState = (props) => {

  const [loading, setLoading] = useState(false)
  const [countryList, setCountryList] = useState([])
  const [StateList, setStateList] = useState([])
  const [check, setCheck] = useState(false);

  const onChange = (e) => {
    setCheck(e.target.checked);
 };

  useEffect(() => {
    getCountry();
    getState();
  }, [])
  
  

  const getCountry = () => {
    let apiParams = {pagination:false};
    setLoading(true);
    let successFn = function(result){
        setLoading(false);
       setCountryList(result)
    }
    let errorFn = function(error){
        console.log(error)
    }
    getAPI(COUNTRY, successFn, errorFn,apiParams)
  }

  const getState = () => {
    let apiParams = {pagination:false};
    setLoading(true);
    let successFn = function(result){
        setLoading(false);
       setStateList(result)
    }
    let errorFn = function(error){
        console.log(error)
    }
    getAPI(STATE, successFn, errorFn,apiParams)
  }

  const onFinish = (values) => {
    let {editData} = props;
    setLoading(true)
    let reqData = {...values, is_territorial:check}

    let successFn = function(){
      setLoading(false)
      props.onSelectTab('view-state')
      if(editData){
        displayMessage(SUCCESS_MSG_TYPE, 'State Updated Successfully.');
      }else{
      displayMessage(SUCCESS_MSG_TYPE, 'State Added Successfully.');
      }
    }
    let errorFn = function(error){
      setLoading(false)
    }
    if (editData) {
      reqData.id = editData.id;
      postAPI(STATE, reqData, successFn, errorFn);
    }
    else{
    postAPI(STATE, reqData, successFn, errorFn)
    }
  }
  
  const handleCancelEdit = () => {
    props.onSelectTab("view-state");
  }
  
  
  return (
   
        <Spin spinning={loading}>
        <Form
          onFinish={onFinish}
          // validateMessages={validateMessages}
          {...layout}
          key={props.editData ? props.editData.id : '1'}
          initialValues={{
            ...props.editData,
          }}
        >
          <Form.Item
            label={'Country'}
            name={'country'}
            rules={[{ required: true }]}
          >
            <Select
                  showSearch
                  allowClear
                  style={{ width: '100%' }}
                  placeholder={'Country'}
                  optionFilterProp="children"
                >
                  {countryList.map((option) => (
                    <Select.Option label={option.label} key={option.id} value={option.id}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
          </Form.Item>
          <Form.Item
            label={'State'}
            name={'name'}
            rules={[{ required: true }]}
          >
            
                <Input placeholder='State'/>
          </Form.Item>
          <Form.Item
            label={'GST State Code'}
            name={'state_code'}
            rules={[{ required: true }]}
          >
            
                <Input placeholder='GST State Code'/>
          </Form.Item>
          <Form.Item
           label={'Union Territory'}
           name={'is_territorial'}
           valuePropName="checked"
           >
          <Checkbox onChange={onChange}></Checkbox>
          </Form.Item>
          <Form.Item 
          {...tailLayout}
          >
          <Form.Item 
          {...tailLayout}
          >
            

          </Form.Item>
            <Space>
              <Button
                htmlType='submit'
                type={'primary'}
                className={'theme-color'}
              >
                Save
              </Button>
              {props.editData && 
            <Button onClick={handleCancelEdit} >Cancel</Button> }
            </Space>
          </Form.Item>
        </Form>
      </Spin>
  )
}

export default AddOrEditState