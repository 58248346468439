import { Card,Switch, Divider, Popconfirm, Select, Space, Spin, Table, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import {DELETE_PARTNER, ORGANIZATION, PARTNER} from '../../../../constants/api'
import {getAPI, postAPI, putAPI} from '../../../../utils/apiRequest'
import InfiniteFeedLoaderButton from '../../../common/InfiniteFeedLoaderButton'
import moment from 'moment'
import {EditOutlined,DeleteOutlined} from '@ant-design/icons'
import { displayMessage } from '../../../../utils/common'
import { SUCCESS_MSG_TYPE } from '../../../../constants/dataKeys'

const ViewPartner = (props) => {
    const [loading, setLoading] = useState(false)
    const [partnerList, setPartnerList] = useState([])
    const [nextPage, setNextPage] = useState(1)
    const [filterKeys, setFilterKeys] = useState({})
    const [organizationList, setOrganizationList] = useState([])

    useEffect(() => {
        getPartner();
    }, [filterKeys])

    useEffect(() => {
      getOrganization();
    }, [])
    


    const getOrganization = () => {
      setLoading(true)

      let apiParams = {
        pagination: false,
      }
      console.log(apiParams)

      let successFn = function(result){
          setLoading(false)
          setOrganizationList(result)
      }

      let errorFn = function(error){
          setLoading(false)
          console.log(error)
      }
     getAPI(ORGANIZATION, successFn, errorFn, apiParams)
  }

 




    const getPartner = (page=1) => {
        setLoading(true)
        let apiParams = {
          ...filterKeys,
          page,
        }
        let successFn = function(result){
            setLoading(false)
            setPartnerList([...(result.current === 1? [] : partnerList), ...result.results])
            setNextPage(result.next)
        }

        let errorFn = function(error){
            setLoading(false)
            console.log(error)
        }
       getAPI(PARTNER, successFn, errorFn, apiParams)
    }

    const  filterData = (type, value) => {
      setFilterKeys(filterKeys=>{ return{...filterKeys,  [type] : value? value : undefined}})
};

    const deleteObject = (record) => {
        let reqData = {
            id: record.id,
            is_active: false,
        };
      let successFn = function (result) {
        getPartner();
      };
      let errorFn = function (error) {};
  
     putAPI(DELETE_PARTNER, reqData, successFn, errorFn)
    };

    const editObject = (record) => {
      props.onSelectTab("add-partner", record);
    };

    const enabledPartner = async (id, e) => {

      let reqData = {
          id,
          is_disabled: e
      }
      const successFn = function (data) {
          if (e) {
              displayMessage(SUCCESS_MSG_TYPE, "Partner enabled for this Organisation successfully!!");
          } else {
              displayMessage(SUCCESS_MSG_TYPE, "Partner disabled for this Organisation successfully!!");
          }
          getPartner();
      };
      const errorFn = function () {

      };
      await putAPI(DELETE_PARTNER, reqData, successFn, errorFn);
  };

    const columns = [
        {
            title: 'S. No.',
            key: 's_no',
            render: (item, record, index) =>
            partnerList.indexOf(record) + 1,
        },
        {
            title: 'Name',
            key: 'first_name',
            render: (item, record) => (
                <span>
                  {record.first_name}
                </span>
              ),
        },
        {
          title: 'Email',
          key: 'user_data',
          render: (item, record) => (
              <span>
                {record.user_data
                  ? `${record.user_data.email}`
                  : '--'}
              </span>
            ),
      },
      {
          title: 'Mobile No.',
          key: 'user_data',
          render: (item, record) => (
              <span>
                {record.mobile
                  ? `${record.mobile}`
                  : '--'}
              </span>
            ),
      },
        {
            title: 'Organization',
            key: 'organization_data',
            render: (item, record) => (
                <span>
                  {record.organization
                    ? `${record.organization_data.name}`
                    : '--'}
                </span>
              ),
        },
        {
          title: 'Designation',
          key: 'designation',
          render: (item, record) => (
              <span>
                {record.designation
                  ? `${record.designation}`
                  : '--'}
              </span>
            ),
      },
      {
        title: 'Joining Date',
        key: 'joining_date',
        render: (item, record) => (
            <span>
              {record.joining_date
                  ? `${moment(record.joining_date).format("DD/MM/YYYY")}`
                  : '--'}
            </span>
          ),
    },

    {
      title: 'Department',
      key: 'department',
      render: (item, record) => (
          <span>
            {record?.department
              ? `${record?.department}`
              : '--'}
          </span>
        ),
  },
        {
            title: 'Profession',
            key: 'profession',
            render: (item, record) => (
                <span>
                  {record.profession
                    ? `${ record.organization_data.profession_data.map((option)=>option.value)}`
                    : '--'}
                </span>
              ),
        },
        {
            title: 'Designation',
            key: 'designation',
            render:(item,record)=><span>{record.designation?record.designation:"--"}</span>
        },
        {
            title: 'Primary Owner',
            key: 'is_primary',
            render: (item, record) => (
              
                <span>
                  {record.is_primary
                    ?  'Yes'  
                    : 'No'}
                </span>
              ),
        },
        {
          title: "Enable Partner",
          dataIndex: "is_disabled",
          key: "is_disabled",
          render: (item, record) => (record.is_primary ?
              <Tag color="red">Not Allowed</Tag> :
              <Switch defaultChecked={!!item} onChange={(e) => enabledPartner(record.id, e)}/>)
      },
       
        {
          title: "Action",
          key: "action",
          render: (text, record) => (
            <span>
              <a onClick={() => editObject(record)}> < EditOutlined style={{ color: 'blue' }}/></a>
              <Divider type="vertical" />
              <Popconfirm
                title="Are you sure to delete this?"
                onConfirm={() => deleteObject(record)}
                okText="Yes"
                cancelText="No"
              >
                <a><DeleteOutlined style={{ color: 'red' }}/></a>
              </Popconfirm>
            </span>
          ),
        },
    ]
    
  return (
    <Card
    title={
      <Space>
        Filters :
        <Select
          placeholder={'Organization'}
          allowClear
          style={{width: "200px"}}
          onChange={(e) => filterData('organization', e)}
          showSearch
          optionFilterProp="children"
        >
           {organizationList.map((option) => (
                <Select.Option label={option.name} value={option.id}>
                  {option.name}
                </Select.Option>
              ))}
        </Select>
        
        </Space>
         }
         bodyStyle={{ padding: 4, border: 0 }}>
    <Spin spinning={loading}>
          <Table
            dataSource={partnerList}
            columns={columns}
            pagination={false}
          />
        </Spin>
        <InfiniteFeedLoaderButton
          loaderFunction={() => getPartner(nextPage)}
          loading={loading}
          hidden={!nextPage}
        />
      </Card>
  )
}



export default ViewPartner