import { Button, Form, Select, Space, Spin } from 'antd'
import React, { useState, useEffect } from 'react'
import { POLICIES, POLICY_TYPE } from '../../../../constants/api'
import { SUCCESS_MSG_TYPE,POLICY } from '../../../../constants/dataKeys'
import { layout, tailLayout } from '../../../../constants/hardData'
import { getAPI, postAPI } from '../../../../utils/apiRequest'
import { displayMessage } from '../../../../utils/common'
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";


const AddOrEditPolicies = (props) => {

  const [loading, setLoading] = useState(false)
  const [policyList, setPolicyList] = useState([])
  const [editorState, setEditorState] = useState({})

  useEffect(() => {
   getPolicies();
  }, [])
  
  const onEditorStateChange = (name, editorState) => {
    setEditorState({...editorState, [name]: editorState})
};

  const getPolicies = () => {
    setLoading(true);

    let apiParams= {
      pagination: false,
      name:POLICY
    }
    let successFn = function(result){
        setLoading(false);
        setPolicyList(result)
    }
    let errorFn = function(error){
        console.log(error)
        setLoading(false);
    }
    getAPI(POLICY_TYPE, successFn, errorFn, apiParams)
  }


  const onFinish = (values) => {
    let { editData } = props;

    setLoading(true)
    let reqData = {...values,
      policy_detail: editorState.policy_detail ? 
      editorState.policy_detail
       : editData ? editData.policy_detail : '',}

    let successFn = function(){
      setLoading(false)
      props.onSelectTab('view-policies')
      if(editData){
        displayMessage(SUCCESS_MSG_TYPE, 'Policy Updated Successfully.');

      }
      else{
      displayMessage(SUCCESS_MSG_TYPE, 'Policy Added Successfully.');
      }
    }
    let errorFn = function(error){
        console.log(error)
      setLoading(false)
    }
    if (editData) {
      reqData.id = editData.id;
      postAPI(POLICIES, reqData, successFn, errorFn);
    }
    else{
    postAPI(POLICIES, reqData, successFn, errorFn)
    }
  }

  const handleChange = () => {

  }

  const handleCancelEdit = () => {
    props.onSelectTab("view-policies");
  }
    
  
  return (
   
        <Spin spinning={loading}>
        <Form
          onFinish={onFinish}
          // validateMessages={validateMessages}
          {...layout}
          key={props.editData ? props.editData.id : '1'}
          initialValues={{
            ...props.editData,
          }}
        >
          <Form.Item
            label={'Policy Type'}
            name={'name'}
            rules={[{ required: true }]}
          >
          <Select
                  showSearch
                  allowClear
                  style={{ width: '100%' }}
                  placeholder={'Policy Type'}
                  onChange={()=> handleChange }
                >
                  {policyList?.map((option) => (
                    <Select.Option label={option.label} key={option.id} value={option.id}>
                      {option.value}
                    </Select.Option>
                  ))}
                </Select>
           
          </Form.Item>
          <Form.Item
            label={'Description'}
            name={'policy_detail'}
          >
            {/* <TextArea placeholder={'Policy Detail'}></TextArea> */}
            <div style={{border: '1px solid #eee'}}>

            <ReactQuill
              value={
                editorState?.policy_detail
                  ? editorState?.policy_detail
                  : props.editData?.policy_detail
                  ? props.editData.policy_detail
                  : ""
              }
              onChange={(content) => onEditorStateChange("policy_detail", content)}
              modules={{
                toolbar: [
                  ["bold", "italic", "underline", "strike"],
                  ["blockquote", "code-block"],
                  [{ header: 1 }, { header: 2 }],
                  [{ list: "ordered" }, { list: "bullet" }],
                  [{ script: "sub" }, { script: "super" }],
                  [{ indent: "-1" }, { indent: "+1" }],
                  [{ direction: "rtl" }],
                  [{ size: ["small", false, "large", "huge"] }],
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  [{ color: [] }, { background: [] }],
                  [{ font: [] }],
                  [{ align: [] }],
                  ["clean"],
                ],
              }}
              className="default-rich-text-editor my-editor"
            />
                </div>
          </Form.Item>
         
          <Form.Item 
          {...tailLayout}
          >
            <Space>
              <Button
                htmlType='submit'
                type={'primary'}
                className={'theme-color'}
              >
                Save
              </Button>
              {props.editData && 
            <Button onClick={handleCancelEdit} >Cancel</Button> }
            </Space>
          </Form.Item>
        </Form>
      </Spin>
  )
}

export default AddOrEditPolicies