import { Button, Checkbox, Form, Input, Space, Spin } from 'antd'
import React, { useState } from 'react'
import { SMS_SETTING } from '../../../../constants/api'
import { SUCCESS_MSG_TYPE } from '../../../../constants/dataKeys'
import { layout, tailLayout } from '../../../../constants/hardData'
import { postAPI } from '../../../../utils/apiRequest'
import { displayMessage } from '../../../../utils/common'

const AddOrEditSms = (props) => {

  const [loading, setLoading] = useState(false)
  const [isChecked, setIsChecked] = useState(props?.editData?.is_enabled ? true : false)


  const onFinish = (values) => {
    let { editData } = props;
    setLoading(true)
    let reqData = {...values, 
    is_enabled : isChecked}

    let successFn = function(){
      setLoading(false)
      props.onSelectTab('view-sms')
      if(editData){
        displayMessage(SUCCESS_MSG_TYPE, 'SMS Template Updated Successfully.');

      }else{
      displayMessage(SUCCESS_MSG_TYPE, 'SMS Template Added Successfully.');
      }
    }
    let errorFn = function(error){
        console.log(error)
      setLoading(false)
    }
    if (editData) {
      reqData.id = editData.id;
      postAPI(SMS_SETTING, reqData, successFn, errorFn);
    }
    else{
    postAPI(SMS_SETTING, reqData, successFn, errorFn)
    }
  }

  const handleCheck = (e) => {
    setIsChecked(e.target.checked)
  }

  const handleCancelEdit = () => {
    props.onSelectTab("view-sms");
  }
    
  
  return (
   
        <Spin spinning={loading}>
        <Form
          onFinish={onFinish}
          {...layout}
          key={props.editData ? props.editData.id : '1'}
          initialValues={{
            ...props.editData,
          }}
        >
          <Form.Item
            label={'Name'}
            name={'name'}
            rules={[{ required: true }]}
          >
            <Input placeholder='Name'/>
           
          </Form.Item>
          <Form.Item
            label={'Template'}
            name={'template'}
            rules={[{ required: true }]}
          >
            <Input placeholder={'Template'} />
          </Form.Item>
          <Form.Item name = "is_enabled" label="DLT Approved" >
            <Checkbox checked={isChecked} onChange={handleCheck}  />
          </Form.Item>
          <Form.Item 
          {...tailLayout}
          >
            <Space>
              <Button
                htmlType='submit'
                type={'primary'}
                className={'theme-color'}
              >
                Save
              </Button>
              {props.editData && 
            <Button onClick={handleCancelEdit} >Cancel</Button> }
            </Space>
          </Form.Item>
        </Form>
      </Spin>
  )
}

export default AddOrEditSms