import { Card, Tabs } from 'antd'
import TabPane from 'antd/lib/tabs/TabPane'
import React, {useState} from 'react'
import AppBase from '../../../base/AppBase'
import AddOrEditCoupon from './AddOrEditCoupon'
import ViewCoupon from './ViewCoupon'

const PartnerCoupon = (props) => {
    const [selectedTab, setSelectedTab] = useState("add-coupon")
    const [ editData, setEditData ] =useState(null);
    const onSelectTab = (option, extra) => {
        setSelectedTab(option)
        setEditData(extra);
        
      };
  return (
    <AppBase
    metaDetails={{
        title: 'Coupon',
        // description: HR_SEETINGS_DROPDOWN_SETTING_DESC,
      }}
    >
        <Card>
            <Tabs onChange={onSelectTab} activeKey={selectedTab}>
                <TabPane tab="Coupon" key="add-coupon">
                    {selectedTab ==="add-coupon"? (
                        <AddOrEditCoupon
                        editData={editData}
                        key={'add-coupon'}
                        onSelectTab={onSelectTab}
                        {...props}/>
                    ):
                    null}
                </TabPane>

                <TabPane  tab='View Coupon' key='view-coupon'>
              {selectedTab === 'view-coupon' ? (
                <ViewCoupon onSelectTab={onSelectTab} {...props} />
              ) : null}
            </TabPane>
            </Tabs>
        </Card>
    </AppBase>
  )
}

export default PartnerCoupon