import {Button, Divider, Form, Input, Modal, Spin, Table, Tag} from "antd";
import React, {useEffect, useRef, useState} from "react";
import {getAPI, putAPI} from "../../../../utils/apiRequest";
import {APPROVE_BOARDING, REQUEST_ON_BOARDING, VIEW_UPLOADED_FILE} from "../../../../constants/api";
import InfiniteFeedLoaderButton from "../../../common/InfiniteFeedLoaderButton";
import moment from "moment/moment";
import {EyeOutlined} from "@ant-design/icons";
import {
    displayMessage,
    handleErrorResponse,
    makeOnBoardingFileURL,
    startLoadingMessage,
    stopLoadingMessage
} from "../../../../utils/common";
import {ERROR_MSG_TYPE, SUCCESS_MSG_TYPE} from "../../../../constants/dataKeys";

const {TextArea} = Input;
const PendingOnboarding = () => {
    const [loading, setLoading] = useState(false)
    const [onBoardingRequest, setOnBoardingRequest] = useState([])
    const [nextPage, setNextPage] = useState(1)
    const [selectedProfession, setProfession] = useState(null)
    const [approve, setApprove] = useState(null)
    const [reject, setReject] = useState(null)

    useEffect(() => {
        loadOnBoardingRequest()
    }, [])
    const formRef = useRef();
    const loadOnBoardingRequest = (page = 1) => {
        let apiReq = {
            page,
            action_taken: false
        }
        setLoading(true)
        let successFn = function (result) {
            setOnBoardingRequest([...(result.current === 1 ? [] : onBoardingRequest), ...result.results])
            setNextPage(result.next)
            setLoading(false)
        }
        let errorFn = function () {
            setLoading(false)

        }
        getAPI(REQUEST_ON_BOARDING, successFn, errorFn, apiReq)
    }
    const onViewProfession = (record) => {
        setProfession(record)
    }
    const cancelApproval = () => {
        setApprove(null);
        setReject(null);
    }
    const onSubmitApproval = (approve, reject, values) => {
        let data = {
            ...values,
            id: approve ? approve : reject,
            is_approved: !!approve,
            is_rejected: !!reject
        };
        let successFn = function (data) {
            formRef.current.resetFields();
            setApprove(null);
            setReject(null);
            displayMessage(SUCCESS_MSG_TYPE, `Successfully ${approve ? "Approved" : "Rejected"}`);
            loadOnBoardingRequest();
        };
        let errorFn = function (error) {
            displayMessage(ERROR_MSG_TYPE, "Failed")
        };
        putAPI(APPROVE_BOARDING, data, successFn, errorFn);
    }
    const handleFileDownload = async (path, fileName) => {
        let apiParams = {
            path: path,
            is_onboard : true,
        };
        let msg = startLoadingMessage("Generating File ...");
        let successFn = function (data) {
            if (data.url) {
                window.open(makeOnBoardingFileURL(data.url));
            }
            stopLoadingMessage(msg, SUCCESS_MSG_TYPE, "File Generated Successfully!");
        };
        let errorFn = function (error) {
            handleErrorResponse(error);
            stopLoadingMessage(msg, ERROR_MSG_TYPE, "File Generation Failed!");
        };
        await getAPI(VIEW_UPLOADED_FILE, successFn, errorFn, apiParams);
    };


    const columns = [{
        title: 'S. No.', key: 's_no', render: (item, record, index) => onBoardingRequest.indexOf(record) + 1,
    },
        {
            title: 'Organization Name',
            key: 'org_name',
            render: (item, record) => <span>{record.org_name ? record.org_name : '--'}</span>
        },
        {
            title: 'Name', key: 'name', render: (item, record) => <span>{record.name ? record.name : '--'}</span>
        }, {
            title: 'Email', key: 'email', render: (item, record) => <span>{record.email ? record.email : '--'}</span>
        }, {
            title: 'Mobile',
            key: 'mobile',
            render: (item, record) => <span>{record.mobile ? record.mobile : '--'}</span>
        }, {
            title: 'Address',
            key: 'address',
            render: (item, record) =>
                <span>{`${record.address ? record?.address : "--"}${record.city ? record?.city_data?.name : "--"}, ${record.state ? record.state_data.name : "--"},${record.country ? record.country_data.name : ""}`}</span>
        }, {
            title: 'Profession',
            key: 'profession',
            render: (item, record) => <a onClick={() => onViewProfession(record)}>View</a>
        },  {
            title: 'Amount Paid',
            key: 'transaction_amount',
            render: (item, record) => record?.transaction_amount ? record?.transaction_amount : "--"
        }, {
            title: 'Payment Status',
            key: 'is_complete',
            render: (item, record) =>
                record.is_complete ? <Tag color={"success"}>Successful</Tag> : <Tag color={"error"}>Failed</Tag>

        }, {
            title: "Action",
            key: "action",
            render: (text, record) => (
                <span>
                    <a onClick={() => setApprove(record.id)}>Approve</a>
                  <Divider type="vertical"/>
                    <a onClick={() => setReject(record.id)}>Reject</a>
                </span>
            ),
        }]


    const accounantColumn = [
        {
            title: 'S. No.', key: 's_no', render: (item, record, index) => 1,
        },
        {
            title: "Date  of Birth", key: "dob", render: (item, record) => (<span>
          {record.dob ? `${moment(record.dob).format("DD/MM/YYYY")}` : "--"}
        </span>),
        }, {
            title: "Firm Name",
            key: "firm_name",
            render: (item, record) => (<span>{record.firm_name ? `${record.firm_name}` : "--"}</span>),
        },


    ];
    const advocateColumn = [
        {
            title: 'S. No.', key: 's_no', render: (item, record, index) => 1,
        },
        {
            title: "Date  of Birth", key: "dob", render: (item, record) => (<span>
          {record.dob ? `${moment(record.dob).format("DD/MM/YYYY")}` : "--"}
        </span>),
        }, {
            title: "Firm Name",
            key: "firm_name",
            render: (item, record) => (<span>{record.firm_name ? `${record.firm_name}` : "--"}</span>),
        }, {
            title: "Enrollment Number", key: "enrollment_number", render: (item, record) => (<span>
          {record.enrollment_number ? `${record.enrollment_number}` : "--"}
        </span>),
        }, {
            title: "Registration Number", key: "registration_number", render: (item, record) => (<span>
          {record.registration_number ? `${record.registration_number}` : "--"}
        </span>),
        },

        {
            title: "Photograph", key: "file", render: (item, record) => (<span>
          {record.photo ? (<a onClick={() => handleFileDownload(record.photo)}><EyeOutlined/></a>) : ("--")}
        </span>),
        }, {
            title: "LLB Degree", key: "file", render: (item, record) => (<span>
          {record.llb_degree_upload ? (<a onClick={() => handleFileDownload(record.llb_degree_upload)}>
              <EyeOutlined/>
          </a>) : ("--")}
        </span>),
        },

    ];
    const caColumn = [{
        title: 'S. No.', key: 's_no', render: (item, record, index) => 1,
    }, {
        title: "Date  of Birth", key: "dob", render: (item, record) => (<span>
          {record.dob ? `${moment(record.dob).format("DD/MM/YYYY")}` : "--"}
        </span>),
    }, {
        title: "Firm Name",
        key: "firm_name",
        render: (item, record) => (<span>{record.firm_name ? `${record.firm_name}` : "--"}</span>),
    }, {
        title: "Enrollment Date", key: "enrollment_date", render: (item, record) => (<span>
          {record.enrollment_date ? `${moment(record.enrollment_date).format("DD/MM/YYYY")}` : "--"}
        </span>),
    }, {
        title: "MRN Number",
        key: "mrn_number",
        render: (item, record) => (<span>{record.mrn_number ? `${record.mrn_number}` : "--"}</span>),
    },

        {
            title: "Photograph", key: "file", render: (item, record) => (<span>
          {record.photo ? (<a onClick={() => handleFileDownload(record.photo)}><EyeOutlined/></a>) : ("--")}
        </span>),
        }, {
            title: "ACA/FCA Certificate", key: "file", render: (item, record) => (<span>
          {record.aca_fca_upload ? (<a onClick={() => handleFileDownload(record.aca_fca_upload)}>
              <EyeOutlined/>
          </a>) : ("--")}
        </span>),
        },

    ];

    const cmaColumn = [
        {
            title: 'S. No.', key: 's_no', render: (item, record, index) => 1,
        }, {
            title: "Date  of Birth", key: "dob", render: (item, record) => (<span>
          {record.dob ? `${moment(record.dob).format("DD/MM/YYYY")}` : "--"}
        </span>),
        }, {
            title: "Firm Name",
            key: "firm_name",
            render: (item, record) => (<span>{record.firm_name ? `${record.firm_name}` : "--"}</span>),
        }, {
            title: "Enrollment Date", key: "enrollment_date", render: (item, record) => (<span>
          {record.enrollment_date ? `${moment(record.enrollment_date).format("DD/MM/YYYY")}` : "--"}
        </span>),
        }, {
            title: "MRN Number",
            key: "mrn_number",
            render: (item, record) => (<span>{record.mrn_number ? `${record.mrn_number}` : "--"}</span>),
        },

        {
            title: "Photograph", key: "file", render: (item, record) => (<span>
          {record.photo ? (<a onClick={() => handleFileDownload(record.photo)}><EyeOutlined/></a>) : ("--")}
        </span>),
        }, {
            title: "Participating Certificate", key: "file", render: (item, record) => (<span>
          {record.practicing_upload ? (<a onClick={() => handleFileDownload(record.practicing_upload)}>
              <EyeOutlined/>
          </a>) : ("--")}
        </span>),
        },

    ];
    const csColumn = [
        {
            title: 'S. No.', key: 's_no', render: (item, record, index) => 1,
        },
        {
            title: "Date  of Birth", key: "dob", render: (item, record) => (<span>
          {record.dob ? `${moment(record.dob).format("DD/MM/YYYY")}` : "--"}
        </span>),
        }, {
            title: "Firm Name",
            key: "firm_name",
            render: (item, record) => (<span>{record.firm_name ? `${record.firm_name}` : "--"}</span>),
        }, {
            title: "Enrollment Date", key: "enrollment_date", render: (item, record) => (<span>
          {record.enrollment_date ? `${moment(record.enrollment_date).format("DD/MM/YYYY")}` : "--"}
        </span>),
        }, {
            title: "MRN Number",
            key: "mrn_number",
            render: (item, record) => (<span>{record.mrn_number ? `${record.mrn_number}` : "--"}</span>),
        },

        {
            title: "Photograph", key: "file", render: (item, record) => (<span>
          {record.photo ? (<a onClick={() => handleFileDownload(record.photo)}><EyeOutlined/></a>) : ("--")}
        </span>),
        }, {
            title: "Participating Certificate", key: "file", render: (item, record) => (<span>
          {record.practicing_upload ? (<a onClick={() => handleFileDownload(record.practicing_upload)}>
              <EyeOutlined/>
          </a>) : ("--")}
        </span>),
        },

    ];
    const otherColumn = [
        {
            title: 'S. No.', key: 's_no', render: (item, record, index) => 1,
        }, {
            title: "Date  of Birth", key: "dob", render: (item, record) => (<span>
          {record.dob ? `${moment(record.dob).format("DD/MM/YYYY")}` : "--"}
        </span>),
        }, {
            title: "Mobile No.",
            key: "mobile",
            render: (item, record) => (<span>{record.mobile ? `${record.mobile}` : "--"}</span>),
        }, {
            title: "Firm Name",
            key: "firm_name",
            render: (item, record) => (<span>{record.firm_name ? `${record.firm_name}` : "--"}</span>),
        },


        {
            title: "Photograph", key: "file", render: (item, record) => (<span>
          {record.photo ? (<a onClick={() => handleFileDownload(record.photo)}><EyeOutlined/></a>) : ("--")}
        </span>),
        },

    ];
    return (<>
        <Spin spinning={loading}>
            <Table
                pagination={false}
                columns={columns}
                dataSource={onBoardingRequest}/>
        </Spin>
        <InfiniteFeedLoaderButton
            loaderFunction={() => loadOnBoardingRequest(nextPage)}
            loading={loading}
            hidden={!nextPage}
        />
        <Modal
            footer={null}
            title={selectedProfession?.profession}
            onCancel={() => onViewProfession(null)}
            visible={!!selectedProfession}
            width={800}
        >
            <Table
                dataSource={[selectedProfession]}
                columns={selectedProfession?.profession === 'Accountant' ?
                    accounantColumn : selectedProfession?.profession === 'Advocate' ?
                        advocateColumn : selectedProfession?.profession === 'CA' ?
                            caColumn : selectedProfession?.profession === 'CMA' ?
                                cmaColumn : selectedProfession?.profession === 'CMA' ?
                                    csColumn : selectedProfession?.profession === 'Other' ?
                                        otherColumn : columns}
                pagination={false}
            />
        </Modal>

        <Modal
            footer={null}
            title={approve ? "Approve Request" : "Reject Request"}
            onCancel={() => cancelApproval()}
            visible={approve || reject}
        >
            <Form ref={formRef} onFinish={(values) => onSubmitApproval(approve, reject, values)}>
                <Form.Item name={"remarks"}>
                    <TextArea/>
                </Form.Item>
                <Form.Item>
                    <center><Button type={"primary"} htmlType='submit'
                                    className={'theme-color'}>{approve ? "Approve" : "Reject"}</Button>&nbsp;
                        {approve ? <Button onClick={() => setApprove(null)}>Cancel</Button> :
                            <Button onClick={() => setReject(null)}>Cancel</Button>}</center>
                </Form.Item>
            </Form>
        </Modal>
    </>)
}

export default PendingOnboarding