import { Card, Divider, Popconfirm, Spin, Table, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { POLICIES } from '../../../../constants/api'
import { getAPI, postAPI } from '../../../../utils/apiRequest'
import InfiniteFeedLoaderButton from '../../../common/InfiniteFeedLoaderButton'
import {EditOutlined,DeleteOutlined,EyeOutlined} from '@ant-design/icons'

const ViewPolicies = (props) => {
    const [loading, setLoading] = useState(false)
    const [policyList, setPolicyList] = useState([])
    const [nextPage, setNextPage] = useState(1)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPolicy, setSelectedPolicy] = useState(0);

    useEffect(() => {
        getPolicy();
    }, [])


    const showModal = (record) => {
      setSelectedPolicy(record)
      setIsModalOpen(true);
  };
  const handleOk = () => {
      setIsModalOpen(false);
  };
  const handleCancel = () => {
      setIsModalOpen(false);
  };



    const getPolicy = (page=1) => {
        setLoading(true)
        let apiParams = {
          page,
        }

        let successFn = function(result){
            setLoading(false)
            setPolicyList([...(result.current === 1? [] : policyList), ...result.results])
            setNextPage(result.next)
        }

        let errorFn = function(error){
            setLoading(false)
            console.log(error)
        }
       getAPI(POLICIES, successFn, errorFn, apiParams)
    }

    
    const deleteObject = (record) => {
      let reqData = {
        ...record,
        is_active: false,
      };
      let successFn = function (result) {
        getPolicy();
      };
      let errorFn = function (error) {};
  
     postAPI(POLICIES, reqData, successFn, errorFn)
    };

    const editObject = (record) => {
      props.onSelectTab("add-policies", record);
    };

    

    const columns = [
        {
            title: 'S. No.',
            key: 's_no',
            render: (item, record, index) =>
            policyList.indexOf(record) + 1,
        },
        {
            title: 'Policy Type',
            key: 'name_data',
            render: (item, record) => (
                <span>
                  {record.name
                    ? `${record.name_data.value}`
                    : '--'}
                </span>
              ),
        },
        {
          title: "Action",
          key: "action",
          render: (text, record, index) => (
            <span>
              <a onClick={() => editObject(record)}> < EditOutlined style={{ color: 'blue' }}/></a>
              <Divider type="vertical" />
              <a onClick={() => showModal(record)}><EyeOutlined style={{ color: 'skyBlue' }}/></a>
              <Divider type="vertical" />
              <Popconfirm
                title="Are you sure to delete this?"
                onConfirm={() => deleteObject(record)}
                okText="Yes"
                cancelText="No"
              >
                <a><DeleteOutlined style={{ color: 'red' }}/></a>
              </Popconfirm>
            </span>
          ),
        },
    ]
    
  return (
    <Card>
    <Spin spinning={loading}>
          <Table
            dataSource={policyList}
            columns={columns}
            pagination={false}
          />
        </Spin>
        <InfiniteFeedLoaderButton
          loaderFunction={() => getPolicy(nextPage)}
          loading={loading}
          hidden={!nextPage}
        />
    <Modal title="Policy Details" footer={false} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
    <div dangerouslySetInnerHTML={{__html: selectedPolicy?.policy_detail}}/>
      </Modal>
      </Card>
  )
}


export default ViewPolicies