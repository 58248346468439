import { Button, Card, Col, Form, Row, Select, Space, Spin, Typography } from 'antd';
import React, { useRef, useState, useEffect } from 'react'
import {  PARTNER_POLICIES, SETTINGS_DYNAMIC } from '../../../../constants/api';
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { layout, tailLayout } from '../../../../constants/hardData';
import { getAPI, postAPI, putAPI } from '../../../../utils/apiRequest';
import { displayMessage } from '../../../../utils/common';
import { SUCCESS_MSG_TYPE } from '../../../../constants/dataKeys';
import { REQUIRED_FIELD_MESSAGE } from '../../../../constants/message';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";


const AddOrEditPartnerPolicy = (props) => {
    const formRef = useRef();

    const [policyList, setPolicyList] = useState([])
    const [editorState, setEditorState] = useState({})
    const [editData, setEditData] = useState(props?.editData)
    const [loading, setLoading] = useState(false)
    const [contentError, setContentError] = useState(false)

    
    useEffect(() => {
        loadPolicies();
    }, [])


    
    const loadPolicies = () => {
        setLoading(true)
        let apiParams = {
            name: "Partner Policies",
            pagination: false
        }
        const successFn = (res) => {
            setPolicyList(res)
            setLoading(false)
        }
        const errorFn = () => {
            console.error();
            setLoading(false)
        }
        getAPI(SETTINGS_DYNAMIC, successFn, errorFn, apiParams)
    }

    const handleReset = () => {
        formRef.current.resetFields()
    }

    const onFinish = (values) => {
        setLoading(true)
        let reqData = {
            ...values,
            policy_detail: editorState.policy_detail ? 
                        editorState.policy_detail
                        : editData ? editData.policy_detail : '',
        }
        const successFn = (res) => {
            displayMessage(SUCCESS_MSG_TYPE, "Policy updated successfully");
            setLoading(false);
            handleReset();
            props.onSelectTab('view-partner-policies')
        }
        const errorFn = (error) => {
            console.log(error)
            setLoading(false)
        }
        if (editData) {
            reqData.id = editData.id;
            putAPI(PARTNER_POLICIES, reqData, successFn, errorFn);
        } else {
            postAPI(PARTNER_POLICIES, reqData, successFn, errorFn)
        }
        // postAPI(PARTNER_POLICIES, reqData, successFn, errorFn)
        // }
    }

    const onEditorStateChange = (name, editorState) => {
        setEditorState({...editorState, [name]: editorState})
        setContentError(false)
    };

    const handleCancelEdit = () => {
        props.onSelectTab("view-partner-policies");
      }
        


  return (
    <div>
         <Spin spinning={loading}>
                <Row>
                    <Col span={24}>
                        <Card
                            style={{marginRight: "1rem"}}
                        >
                            <Row justify="space-between">
                                <Col offset={3} span={17}>
                                    <Form {...layout} colon={false}
                                          validateMessages={validateMessages}
                                          onFinish={onFinish}
                                          ref={formRef}
                                          key={editData ? editData.id : 'id'}
                                          initialValues={{
                                              ...editData,
                                              name: editData?.name,
                                              policy_detail: editData?.policy_detail
                                          }}
                                    >
                                        <Form.Item
                                            name="policy"
                                            rules={[{required: true}]}
                                            label={'Policy Name'}
                                        >
                                            <Select style={{width: "100%"}}
                                                placeholder="Policy Name"
                                                    optionFilterProp="children">
                                                {policyList.map((option) => (
                                                    <Select.Option label={option.value} key={option.id}
                                                                   value={option.id}>
                                                        {option.value}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            name={'policy_detail'}
                                            label={' Policy Details'}
                                        >
                                            <div style={{border: '1px solid #eee'}}>
                                            <ReactQuill
              value={
                editorState?.policy_detail
                  ? editorState?.policy_detail
                  : props.editData?.policy_detail
                  ? props.editData.policy_detail
                  : ""
              }
              onChange={(content) => onEditorStateChange("policy_detail", content)}
              modules={{
                toolbar: [
                  ["bold", "italic", "underline", "strike"],
                  ["blockquote", "code-block"],
                  [{ header: 1 }, { header: 2 }],
                  [{ list: "ordered" }, { list: "bullet" }],
                  [{ script: "sub" }, { script: "super" }],
                  [{ indent: "-1" }, { indent: "+1" }],
                  [{ direction: "rtl" }],
                  [{ size: ["small", false, "large", "huge"] }],
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  [{ color: [] }, { background: [] }],
                  [{ font: [] }],
                  [{ align: [] }],
                  ["clean"],
                ],
              }}
              className="default-rich-text-editor my-editor"
            />
                                            </div>
                                        <div>
                                            <Typography.Text style={{color : "red"}} >
                                                Note - You can use the following variables to add the policies : 
                                                <br/> {`{{ORG_NAME}}`}, {`{{ORG_MOBILE}}`}, {`{{ORG_EMAIL}}`}, {`{{ORG_ADDRESS}}`}
                                            </Typography.Text>
                                        </div>
                                        </Form.Item>
                                        <Form.Item {...tailLayout}>
                                            <Space>

                                                <Button
                                                    type='primary'
                                                    className='primary-btn'
                                                    htmlType='submit  '
                                                >
                                                    Save
                                                </Button>
                                                {props.editData && 
                                                    <Button onClick={handleCancelEdit} >Cancel</Button> }
                                            </Space>
                                        </Form.Item>
                                    </Form>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Spin>
    </div>
  )
}

const validateMessages = {
    required: REQUIRED_FIELD_MESSAGE,
  };
  
  

export default AddOrEditPartnerPolicy