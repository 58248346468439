import {Card, DatePicker, Select, Space, Spin, Table, Button, Tag} from 'antd'
import React, {useEffect, useState, useRef} from 'react'
import {ORGANIZATION, PARTNER, PARTNER_CREDITS} from '../../../../../constants/api'
import {getAPI, postAPI} from '../../../../../utils/apiRequest'
import InfiniteFeedLoaderButton from '../../../../common/InfiniteFeedLoaderButton'
import CreditModal from './CreditModal'
import moment from "moment";
import {makeFileURL} from "../../../../../utils/common";

const {RangePicker} = DatePicker

const ViewTransactionHistory = (props) => {
    const [loading, setLoading] = useState(false)
    const [partnerList, setPartnerList] = useState([])
    const [nextPage, setNextPage] = useState(1)
    const [filterKeys, setFilterKeys] = useState({})
    const [organizationList, setOrganizationList] = useState([])
    const [dataSuccess, setDataSuccess] = useState(false)
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const component = useRef();

    useEffect(() => {
        getTransaction();
    }, [filterKeys])

    useEffect(() => {
        getTransaction();
    }, [endDate])

    useEffect(() => {
        getOrganization();
    }, [])


    const getHistory = (values) => {
        setLoading(true)

        let apiParams = {
            export_csv: true,
            pagination: false,
            ...filterKeys
        }
        let successFn = function (result) {

            window.open(makeFileURL(result.report_upload))
            setLoading(false)
        }
        let errorFn = function (error) {
            setLoading(false)
            console.log(error)
        }
        getAPI(PARTNER_CREDITS, successFn, errorFn, apiParams)
    }

    const getOrganization = () => {
        setLoading(true)

        let apiParams = {
            pagination: false,
        }

        let successFn = function (result) {
            setLoading(false)
            setOrganizationList(result)
        }

        let errorFn = function (error) {
            setLoading(false)
            console.log(error)
        }
        getAPI(ORGANIZATION, successFn, errorFn, apiParams)
    }


    const getTransaction = (page = 1) => {
        setLoading(true)
        let apiParams = {
            ...filterKeys,
            page,
            start: startDate && moment(startDate).format("YYYY-MM-DD"),
            end: endDate && moment(endDate).format("YYYY-MM-DD")
        }
        let successFn = function (result) {
            setLoading(false)
            setPartnerList([...(result.current === 1 ? [] : partnerList), ...result.results])
            setNextPage(result.next)
        }

        let errorFn = function (error) {
            setLoading(false)
            console.log(error)
        }
        getAPI(PARTNER_CREDITS, successFn, errorFn, apiParams)
    }

    const filterData = (type, value) => {

        if(type === 'is_admin'){
            setFilterKeys(filterKeys => {
                return {...filterKeys, [type]: value ? value : false}
            })
        }else{
            setFilterKeys(filterKeys => {
            return {...filterKeys, [type]: value ? value : undefined}
        })
        }
        
    };

    const deleteObject = (record) => {
        let reqData = {
            ...record,
            is_active: false,
        };
        let successFn = function (result) {
            getTransaction();
        };
        let errorFn = function (error) {
        };

        postAPI(PARTNER, reqData, successFn, errorFn)
    };

    const editObject = (record) => {
        props.onSelectTab("add-partner-credits", record);
    };

    const columns = [
        {
            title: 'S. No.',
            key: 's_no',
            render: (item, record, index) =>
                partnerList.indexOf(record) + 1,
        },
        {
            title: 'Date ',
            key: 'created_at',
            render: (item => item ? moment(item).format("DD/MM/YYYY") : "--")
        },
        {
            title: 'Organization',
            key: 'organization_data',
            render: (item, record) => (
                <span>
                  {record.organization
                      ? `${record.organization_data.name}`
                      : '--'}
                </span>
            ),
        },
        {
            title: 'Transaction Type',
            key: 'transaction_type',
            render: (item, record) => (
                <span>
                {record.transaction_type
                    ? `${record.transaction_type}`
                    : '--'}
              </span>
            ),
        },
        {
            title: 'Transaction Status',
            key: 'status',
            render: (item, record) => (
                <span>
                  {record.status === 'Success'
                    ?  <Tag color="success">Success</Tag>
                    :<Tag color="error">Failed</Tag>}
                </span>
              ),
        },
        {
            title: 'Credits',
            key: 'credits',
            render: (item, record) => (
                <span>
                {record.credits
                    ? `${record.credits}`
                    : '--'}
              </span>
            ),
        },
        {
            title: 'Admin/partner',
            key: 'is_admin',
            render: (item, record) => (
                <span>
                {record?.is_admin
                    ? 'Admin'
                    : 'Partner'}
              </span>
            ),
        },
        {
            title: 'Remarks',
            key: 'remarks',
            render: (item, record) => (
                <span>
                  {record.remarks
                      ? `${record.remarks}`
                      : '--'}
                </span>
            ),
        },

    ]

    const option = [{name:'Partner', value:false},{id:2,name:'Admin', value:true}];

    const onChangeDateRange = (dates, dateStrings) => {
        const ho = () => {
            setStartDate(dates && dates[0])
            setEndDate(dates && dates[1])
        }
        ho()
    };
    return (
        <Card
            title={
                <Space>
                    Filters :
                    <Select
                        placeholder={'Organization'}
                        allowClear
                        style={{width: "150px"}}
                        onChange={(e) => filterData('organization', e)}
                        showSearch
                        optionFilterProp="children"
                    >
                        {organizationList.map((option) => (
                            <Select.Option label={option.name} value={option.id}>
                                {option.name}
                            </Select.Option>
                        ))}
                    </Select>
                    <Select
                        placeholder={'Admin/Partner'}
                        allowClear
                        style={{width: "150px"}}
                        onChange={(e) => filterData('is_admin', e)}
                    >
                        {option.map((option) => (
                            <Select.Option label={option.name} value={option.value}>
                                {option.name}
                            </Select.Option>
                        ))}
                    </Select>
                    <RangePicker
                        allowClear={true}
                        format={'DD/MM/YYYY'}
                        onChange={onChangeDateRange}
                    />
                    <CreditModal loadData={getTransaction}/>
                    <Button type="primary" onClick={getHistory}>  Download Transaction History </Button>

                </Space>
            }
            bodyStyle={{padding: 4, border: 0}}>
            <Spin spinning={loading}>
                <Table ref={component}
                       dataSource={partnerList}
                       columns={columns}
                       pagination={false}
                />
            </Spin>
            <InfiniteFeedLoaderButton
                loaderFunction={() => getTransaction(nextPage)}
                loading={loading}
                hidden={!nextPage}
            />

        </Card>

    )
}


export default ViewTransactionHistory