/** *
 * App Default Config
 * */

const CONFIG = {
  prodDomain: ['erp.chaitanyaprojects.com'],
  crashHandling: {
    slack: {
      sendOnProduction: true,
      sendOnDevelopment: false,
      webHookUrl:
        'https://hooks.slack.com/services/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',
    },
  },
};

export default CONFIG;
