import {Button, Checkbox, Form, Input, Select, Space, Spin} from 'antd'
import React, { useState, useEffect } from 'react'
import {POLICY_TYPE, SMS_SETTING, TEMPLATE_DOCUMENT} from '../../../../constants/api'
import {CATEGORY, POLICY, SUB_CATEGORY, SUCCESS_MSG_TYPE} from '../../../../constants/dataKeys'
import {layout, tailLayout} from '../../../../constants/hardData'
import {getAPI, postAPI} from '../../../../utils/apiRequest'
import {displayMessage} from '../../../../utils/common'


const AddOrEditTemplate = (props) => {

    const [loading, setLoading] = useState(false)
    const [categories, setCategories] = useState([])
    const [subCategories, setSubCategories] = useState([])

    useEffect(()=> {
        getCategories();
    },[])

    useEffect(()=> {
        getSubCategories(props.editData?.category);
    },[props.editData])

    const getCategories=()=>{
        let apiParams= {
            pagination: false,
            name:CATEGORY,
        }
        let successFn = function(result){
            setCategories(result)
        }
        let errorFn=function (){

        }
        getAPI(POLICY_TYPE,successFn, errorFn, apiParams)
    }
    const getSubCategories = (value) => {
        let apiParams= {
            pagination: false,
            name: SUB_CATEGORY,
            parent: value
        }
        let successFn = function(result){
            setSubCategories(result)
        }
        let errorFn = function(error){
        }
        getAPI(POLICY_TYPE, successFn, errorFn, apiParams)
    }
    const onFinish = (values) => {
        let {editData} = props;
        setLoading(true)
        let reqData = {
            ...values,
        }

        let successFn = function () {
            setLoading(false)
            props.onSelectTab('view-doc')
            if (editData) {
                displayMessage(SUCCESS_MSG_TYPE, 'Document Template Updated Successfully.');

            } else {
                displayMessage(SUCCESS_MSG_TYPE, 'Document Template Added Successfully.');
            }
        }
        let errorFn = function (error) {
            console.log(error)
            setLoading(false)
        }
        if (editData) {
            reqData.id = editData.id;
            postAPI(TEMPLATE_DOCUMENT, reqData, successFn, errorFn);
        } else {
            postAPI(TEMPLATE_DOCUMENT, reqData, successFn, errorFn)
        }
    }

    const handleCancelEdit = () => {
        props.onSelectTab("view-doc");
    }


    return (
        <Spin spinning={loading}>
            <Form
                onFinish={onFinish}
                {...layout}
                key={props.editData ? props.editData.id : '1'}
                initialValues={{
                    ...props.editData,
                }}
            >
                <Form.Item
                    label={'Category'}
                    name={'category'}
                    rules={[{ required: true }]}
                >
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder={'Category'}
                        optionFilterProp="children"
                        onChange={getSubCategories}
                    >
                        {categories?.map((option) => (
                            <Select.Option label={option.value} key={option.id} value={option.id}>
                                {option.value}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={'Sub Category'}
                    name={'subcategory'}
                    rules={[{ required: true }]}
                >
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder={'Sub Category'}
                        optionFilterProp="children"
                    >
                        {subCategories?.map((option) => (
                            <Select.Option label={option.value} key={option.id} value={option.id}>
                                {option.value}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={'Name'}
                    name={'name'}
                    rules={[{required: true}]}
                >
                    <Input placeholder='Name'/>

                </Form.Item>
                <Form.Item
                    label={'Document Id'}
                    name={'document_id'}
                >
                    <Input placeholder={'Document Id'}/>
                </Form.Item>
                <Form.Item
                    label={'Customer Enabled'}
                    name={'customer_enabled'}
                    valuePropName='checked'
                >
                    <Checkbox/>

                </Form.Item>
                <Form.Item
                    label={'Partner Enabled'}
                    name={'partner_enabled'}
                    valuePropName='checked'
                >
                    <Checkbox/>

                </Form.Item>
                <Form.Item
                    {...tailLayout}
                >
                    <Space>
                        <Button
                            htmlType='submit'
                            type={'primary'}
                            className={'theme-color'}
                        >
                            Save
                        </Button>
                        {props.editData &&
                            <Button onClick={handleCancelEdit}>Cancel</Button>}
                    </Space>
                </Form.Item>
            </Form>
        </Spin>
    )
}

export default AddOrEditTemplate