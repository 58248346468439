import { Card, Divider, Modal, Popconfirm, Spin, Table ,Tag, Typography} from "antd";
import React, { useEffect, useState } from "react";
import { HSN_SETTING } from "../../../../constants/api";
import { getAPI, postAPI } from "../../../../utils/apiRequest";
import InfiniteFeedLoaderButton from "../../../common/InfiniteFeedLoaderButton";
import {_get} from '../../../../utils/lodashUtils'
import {EditOutlined,DeleteOutlined} from '@ant-design/icons'

const ViewHSNSettings = (props) => {
  const [loading, setLoading] = useState(false);
  const [hsnList, sethsnList] = useState([]);
  const [nextPage, setNextPage] = useState(1);
  const [descriptionData, setDescriptionData] = useState(null)

  useEffect(() => {
    gethsn();
  }, []);

  const gethsn = (page = 1) => {
    setLoading(true);
    let apiParams = {
      page,
    };

    let successFn = function (result) {
      setLoading(false);
      sethsnList([...(result.current === 1 ? [] : hsnList), ...result.results]);
      setNextPage(result.next);
    };
    let errorFn = function (error) {
      setLoading(false);
      console.log(error);
    };
    getAPI(HSN_SETTING, successFn, errorFn, apiParams);
  };

  const deleteObject = (record) => {
    let reqData = {
      ...record,
      is_active: false,
    };
    let successFn = function (result) {
      gethsn();
    };
    let errorFn = function (error) {};

    postAPI(HSN_SETTING, reqData, successFn, errorFn);
  };

  const editObject = (record) => {
    props.onSelectTab("add-hsn", record);
  };

  const columns = [
    {
      title: "S.No",
      key: "s_no",
      render: (item, record, index) => hsnList.indexOf(record) + 1,
    },
    {
        title: "HSN No.",
        key: "hsn_no",
        dataIndex: 'hsn_no',
        // render :(item) => item
        
      },
    {
      title: "Same State Tax",
      key: "same_state",
      render: (item, record) =>
        _get(record, "same_state_data", []).map((item) => <Tag>{item.name}</Tag>),
    },
    {
      title: "Other State Tax",
      key: "other_state",
      render: (item, record) =>
        _get(record, "other_state_data", []).map((item) => <Tag>{item.name}</Tag>),
    },
    {
      title: "UT TAX",
      key: "ut_tax",
      render: (item, record) =>
        _get(record, "ut_tax_data", []).map((item) => <Tag>{item.name}</Tag>),
    },
    {
      title: "Description",
      key: "description",
      render: (item, record) =>
        _get(record, "description") ? <a onClick={()=>showDescription(record)} >View</a> : "--",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <a onClick={() => editObject(record)}> < EditOutlined style={{ color: 'blue' }}/></a>
          <Divider type="vertical" />
          <Popconfirm
            title="Are you sure to delete this?"
            onConfirm={() => deleteObject(record)}
            okText="Yes"
            cancelText="No"
          >
            <a><DeleteOutlined style={{ color: 'red' }}/></a>
          </Popconfirm>
        </span>
      ),
    },
  ];


  const showDescription = (value) => {
    setDescriptionData(value)
  } 

  return (
    <Card>
      <Spin spinning={loading}>
        <Table dataSource={hsnList} columns={columns} pagination={false} />
      </Spin>
      <InfiniteFeedLoaderButton
        loaderFunction={() => gethsn(nextPage)}
        loading={loading}
        hidden={!nextPage}
      />
      <Modal title={"Description"}
      open={!!descriptionData}
      onCancel={()=>setDescriptionData(null)}
      footer={null}
      >
        <Typography.Text>
          {descriptionData?.description}
        </Typography.Text>
      </Modal>
    </Card>
  );
};

export default ViewHSNSettings;
